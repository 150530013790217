import React, {useContext, useEffect, useState} from 'react';
import zigZagImage from '../../../../public/images/zig-zag.png'
import defaultPose from '../../../../public/images/defaultPose.png'
import './program.css';
import {ACID, AUTH_STATUS, AUTH_TOKEN, USERNAME} from "../SharingConstants";
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {Modal} from "antd";
import Loader from "react-js-loader";
import {toast} from "react-toastify";
import {CameratoggleContext} from "../../context/cameratoggle-context";
import api from '../api';

export default function Program() {
    let currentEventDate = {};
    let countlist = 0;
    const [eventList, setEventList] = useState([]);
    const navigate = useNavigate();
    const [loaderEnable, setloaderEnable] = useState(false);
    const {t} = useTranslation();
    const {userLevel, setUserLevel} = useContext(CameratoggleContext);

    const warning = (id) => {
      Modal.warning({
        title: t('Please wait'),
        content: t('You can only post after the program starts. ') + t('Start reporting on : ') + currentEventDate[id],
      });
    };
    var eveDetails = {};

  useEffect(() => {
    let authStatus= JSON.parse(localStorage.getItem(AUTH_STATUS));
    if(!authStatus) {
      navigate('/', { replace: false })
    }
  });

  useEffect(() =>{
      {
          if(!(localStorage.getItem(USERNAME) && localStorage.getItem(ACID) && localStorage.getItem('satateId'))){
              navigate('/login')
          }
      }
  })

  useEffect(() => {
    let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
    {
      !eventList.length && authStatus && getEvents()
        if (userLevel === "") {
            getUserLevel();
        }
    }
    ;
  }, [])

  function navigateCreateEvent(url, replcale) {
    navigate(url, replcale);
  }

    const getEvents = async () => {
        setloaderEnable(true);
        try {
            const events = await api.get('api/event_tracker/get_events', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await events.data;
            setEventList(res.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setloaderEnable(false);
    };


    const getUserLevel = async () => {
        setloaderEnable(true);
        try {
            const level = await api.get('api/report/user_level', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await level.data;
            if (res.status === false) {
                toast(res.message);
                return;
            }
            setUserLevel(res.data.level_name);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setloaderEnable(false);
    };


    function matchEventDates(event) {
        let eventDate;
        let today;
        let evdate;
        let eveDate = event.aired_detail.date.split(" ");
        eveDate[0] = eveDate[0].substring(0, eveDate[0].length - 2);
        today = new Date();
        let eveTime = event.aired_detail.time.substring(0, event.aired_detail.time.length - 2);
        let apm = event.aired_detail.time.substring(5);
        let [hours, minutes] = eveTime.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (apm === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        if (hours > 18 || (hours == 18 && minutes >= 30 )) {
            eveDate[0] = eveDate[0] - 1;
        }
        eveTime = `${hours}:${minutes}`;
        eventDate = new Date(eveDate[1] + ' ' + eveDate[0] + ', ' + eveDate[2] + ' ' + eveTime);
        if (eventDate > today) {
            //convert to date to string
            let d = eventDate.toString();
            //split the string
            let [day,month,date,year,time]= d.split(' ');
            //split time to hours and minutes
            let [hour,minute] = time.split(':');
            //covert again to 12 hour format
            if (hour == 0) {
                hour = 12;
                apm = 'AM';
                evdate = `${day} ${month} ${date} ${year} ${hour}:${minute}${apm}`;

            } else if (hour == 12) {
                hour = 12;
                apm = 'PM';
                evdate = `${day} ${month} ${date} ${year} ${hour}:${minute}${apm}`;
            } else if (hour > 12) {
                hour = hour-12;
                apm = 'PM' ;
                evdate = `${day} ${month} ${date} ${year} ${hour}:${minute}${apm}`;
            } else {
                apm = 'AM';
                evdate = `${day} ${month} ${date} ${year} ${hour}:${minute}${apm}`;
            }
            eveDetails[event.id] = 'Upcoming Event'
        }
        currentEventDate[event.id] = evdate;
        return (eventDate > today)
    }

    function countlistinc() {
        countlist+= 1;
    }

    const getEventAndRedirect = async (event) => {
        setloaderEnable(true);
        try {
            const events = await api.get('api/event_tracker/get_details?event_id=' + event.id, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await events.data;
            setloaderEnable(false);
            navigateCreateEvent('/review', {
                replace: false,
                state: {
                    is_reported_event_detail: true,
                    event_detail: res.data,
                    can_edit: event.can_edit,
                    event_id: event.id,
                    event_count: event.count
                }
            });
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setloaderEnable(false);
    };


    function handleDate(aired_detail) {
        let eventDate;
        let eveDate = aired_detail.date.split(" ");
        eveDate[0] = eveDate[0].substring(0, eveDate[0].length - 2);
        let eveTime = aired_detail.time.substring(0, aired_detail.time.length - 2);
        let apm = aired_detail.time.substring(5);
        let [hours, minutes] = eveTime.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (apm === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        if (hours > 18 || (hours == 18 && minutes >= 30 )) {
            eveDate[0] = eveDate[0] - 1;
        }
        eventDate = eveDate[0].toString() + 'th ' + eveDate[1].toString() + ' ' + eveDate[2].toString();
        return (eventDate) ;
    }

    const handleReportAgainClick = () => {
        navigate('/limit-exceeded');
        toast("You have reached the maximum limit of reporting");
    };


    return (
        <div>
            <div className="container-fluid">
                <h3 className="program-header mt-3">{t('Program')}</h3>
                {loaderEnable ? (<> <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={t("Loading..")} color={'#FFFFFF'}
                                    size={100}/> </>) : (<> </>)}
                <div className="content-area">
                    {
                        eventList?.map((event) => (
                            <div key={event.id}>
                                {!(event.count != 0) && !event.can_create && !matchEventDates(event) ? (<> </>) : (<>
                                   <p> {countlistinc()}</p>
                            <div className="program-detail" >
                                <div className="row">
                                    <div className="col-8 pr-0">
                                        <div className="ml-2 event-info">
                                            <div className="action-btn">
                                                {(event.count != 0) ?
                                                    (<>
                                                        <a className="know-more">
                                                            <h6 className="event-name">{event.name}</h6>
                                            <div className="event-date-detail">
                                                <small className="light-gray-color">{t('Aired on')}-</small>
                                                <small className="event-date">{handleDate(event.aired_detail)}</small>
                                                <small className="light-gray-color">{event.aired_detail.time}</small>
                                            </div>
                                                            <span onClick={() => getEventAndRedirect(event)}>{t('Click here to know more')}</span>
                                                            {event.can_edit ? (
                                                                <>
                                                                    <div className="submit-another-opt">
                                                                        {event.count < event.e_detail_limit ? (
                                                                            <a
                                                                                onClick={() =>{
                                                                                    navigateCreateEvent(`/booth-detail?event_id=${event.id}&event_count=${event.count}&create=${true}`, {
                                                                                        replace: false,
                                                                                        state: { event_id: event.id, create: true, eventCount: event.count }
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <span>{t('Report Again')}</span>
                                                                            </a>
                                                                        ) : (
                                                                            <a onClick={handleReportAgainClick}>
                                                                                <span>{t('Report Again')}</span>
                                                                            </a>
                                                                        )}
                                                                    </div>

                                                                </>) : (<> </>)}
                                                        </a>
                                                    </>)
                                                    :
                                                    (<>
                                                    </>)}
                                                {!(event.count != 0) && !event.can_create && !matchEventDates(event)? (<>
                                                    <a className="know-more text-danger">{t('Event not reported')}</a></>) : (<></>)}
                                                {!(event.count != 0) && matchEventDates(event) ? (<><a
                                                    className="know-more text-primary" onClick={() => warning(event.id)}>
                                                    <h6 className="event-name">{event.name}</h6>
                                            <div className="event-date-detail">
                                                <small className="light-gray-color">{t('Aired on')}-</small>
                                                <small className="event-date">{handleDate(event.aired_detail)}</small>
                                                <small className="light-gray-color">{event.aired_detail.time}</small>
                                            </div>
                                                    {t('Upcoming Event')}</a></>) : (<></>)}
                                                {event.can_create ? (
                                                    <>
                                                        <a className="know-more"
                                                                onClick={() => navigateCreateEvent(`/booth-detail?event_id=${event.id}&event_count=${event.count}&create=${true}`, {
                                                                    replace: false,
                                                                    state: {event_id: event.id, create: true,eventCount: event.count}
                                                                })}>
                                                            <h6 className="event-name">{event.name}</h6>
                                            <div className="event-date-detail">
                                                <small className="light-gray-color">{t('Aired on')}-</small>
                                                <small className="event-date">{handleDate(event.aired_detail)}</small>
                                                <small className="light-gray-color">{event.aired_detail.time}</small>
                                            </div>
                                                            {t('Report')}
                                                        </a>
                                                    </>) : (<> </>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col p-0 align-center position-relative">
                                        <div className="align-center" style={{backgroundImage: `url(${zigZagImage})`}}>
                                            <img className="event-pose-img"
                                                 src={event.event_photo ? event.event_photo : defaultPose}
                                                 alt=""/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                                    </>)}
                            </div>
                        ))
                    }
                    {!eventList.length || countlist === 0 ? (<> <p className="text-center">{t('No Programs')}</p> </>) : (<> </>)}
                </div>
            </div>
        </div>
    )
}
