import React, {useEffect, useState} from 'react';
import './review.css';
import editReview from '../../../../public/images/Vector.svg'
import eventDefault1 from '../../../../public/images/default-event1.png'
import eventDefault2 from '../../../../public/images/default-event2.png'
import {useLocation, useNavigate} from "react-router-dom";
import {ACID, AUTH_TOKEN} from "../SharingConstants";
import {toast} from "react-toastify";
import Loader from "react-js-loader";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import api from "../api";

let formData = new FormData();
let formResult = {};

export default function Review() {
    const navigate = useNavigate();
    const {handleSubmit} = useForm();
    const [formDataAppend, setFormDataAppend] = useState(true);
    const [loaderEnable, setloaderEnable] = useState(false);
    const [submitEnable, setSubmitEnable] = useState(false);
    const [submitMsg, setSubmitMsg] = useState(false);
    const location = useLocation();
    const data = location.state;
    const { t } = useTranslation();

    useEffect(() => {
        if (formDataAppend && data !== null) {
            resetFormData();
            formData.append('sk_id', data.is_reported_event_detail ? data.event_detail.sk_id : data.formResult.sk_id);
            formData.append('booth_id', data.is_reported_event_detail ? data.event_detail.location[0].id : data.formResult.booth_id);
            formData.append('sk_name', data.is_reported_event_detail ? data.event_detail.sk_name : data.formResult.sk_name);
            formData.append('booth_name', data.is_reported_event_detail ? data.event_detail.location[0].name : data.formResult.booth_name);
            formData.append('total_attendees',data.is_reported_event_detail ? data.event_detail.total_attendees : data.formResult.total_attendees);
            formData.append('address', data.is_reported_event_detail ? data.event_detail.address : data.formResult.address);
            formData.append('description', data.is_reported_event_detail ? data.event_detail.description : data.formResult.description);
            formData.append('file_one', data.is_reported_event_detail ?  (data.event_detail.photo1.length ? data.event_detail.photo1 : '') : data.formResult.file_one);
            formData.append('file_two', data.is_reported_event_detail ? (data.event_detail.photo2.length ? data.event_detail.photo2 : '')  : data.formResult.file_two);
            formData.append('event_id', data.is_reported_event_detail ? data.event_id : data.formResult.event_id);
            formData.append('event_count', data.is_reported_event_detail ? data.event_count : data.formResult.event_count);
            formData.append('event_detail_id', data.is_reported_event_detail ? data.event_detail.id : data.formResult.event_detail_id);
            formData.delete('photo1');
            formData.append('photo1', data.is_reported_event_detail ? data.event_detail.photo1 : data.formResult.photo1);
            formData.delete('photo2');
            formData.append('photo2', data.is_reported_event_detail ? data.event_detail.photo2 : data.formResult.photo2);
            formData.append('latitude', data.is_reported_event_detail ? data.event_detail.latitude : data.formResult.latitude);
            formData.append('longitude', data.is_reported_event_detail ? data.event_detail.longitude : data.formResult.longitude);
            formData.append(
                'VidhanSabha',
                JSON.stringify(
                    data.is_reported_event_detail
                        ? {
                            id: data.event_detail.ac[0].id,
                            name: data.event_detail.ac[0].name
                        }
                        : {
                            id: data.formResult.VidhanSabha.id,
                            name: data.formResult.VidhanSabha.name
                        }
                )
            );
            formData.append(
                'country_state',
                JSON.stringify(
                    data.is_reported_event_detail
                        ? {
                            id: data.event_detail.country_state[0].id,
                            name: data.event_detail.country_state[0].name
                        }
                        : {
                            id: data.formResult.country_state.id,
                            name: data.formResult.country_state.name
                        }
                )
            );
            setFormDataAppend(false);
            setSubmitEnable(data.is_reported_event_detail ? data.can_edit : data.formResult.can_create)
            setSubmitMsg((data.is_reported_event_detail && data.can_edit) ? 'Update' : 'Submit' )
        } else {
            navigate('/program', {replace: false})
        }
    }, [])

    function resetFormData() {
        formData.delete('sk_id');
        formData.delete('booth_id');
        formData.delete('sk_name');
        formData.delete('booth_name');
        formData.delete('total_attendees');
        formData.delete('address');
        formData.delete('description');
        formData.delete('file_one');
        formData.delete('file_two');
        formData.delete('event_id');
        formData.delete('event_detail_id');
        formData.delete('latitude');
        formData.delete('longitude');
    }

    const submitUserDetails = async () => {
        setloaderEnable(true);
        try {
            let result = await api.post('api/event_tracker/create_event_details', formData, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                }
            }); // Make API call using the api instance
            console.log('API Call Response:', result.data);
            result = await result.data;
            setloaderEnable(false);
            if (result.status) {
                if (result.limit_exceeded) {
                    navigate('/limit-exceeded', {replace: false});
                } else {
                    navigate('/success', {replace: false});
                }
            }
            toast(t(result.message))
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setloaderEnable(false);
    };


  function navigateToBoothDetail() {
      const vidhanSabha = (data.is_reported_event_detail ? {
                  id: data.event_detail.ac[0].id,
                  name: data.event_detail.ac[0].name
              }
              : {
                  id: data.formResult.VidhanSabha.id,
                  name: data.formResult.VidhanSabha.name
              }
      )
      const country_state = (data.is_reported_event_detail ? {
                  id: data.event_detail.country_state[0].id,
                  name: data.event_detail.country_state[0].name
              }
              : {
                  id: data.formResult.country_state.id,
                  name: data.formResult.country_state.name
              }
      )
    formResult = {
      'sk_id': formData.get('sk_id'),
      'booth_id': formData.get('booth_id'),
      'sk_name': formData.get('sk_name'),
      'booth_name': formData.get('booth_name'),
      'total_attendees': formData.get('total_attendees'),
      'address': formData.get('address'),
      'description': formData.get('description'),
      'file_one': formData.get('file_one'),
      'photo1': formData.get('photo1'),
      'file_two': formData.get('file_two'),
      'photo2': formData.get('photo2'),
      'event_id': formData.get('event_id'),
      'path': location.pathname,
      'can_submit': submitEnable,
      'event_detail_id': formData.get('event_detail_id'),
      'latitude': formData.get('latitude'),
      'longitude': formData.get('longitude'),
        'VidhanSabha': vidhanSabha,
        'country_state':country_state,
        'event_count': data.is_reported_event_detail ? data.event_count : data.formResult.event_count
    };
    navigate(`/booth-detail?event_id=${formResult.event_id}&event_count=${formResult.event_count}`, {replace: true, state: {formResult}})
  }

    return (
        <div>
            {data === null ? (
                navigate('/booth-detail', {replace: false})
            ) : (
                <>
                    {submitEnable ? submitMsg==='Submit' ? (<> <button className='editReview'>
                        <img className='arrowImg' onClick={navigateToBoothDetail} src={editReview} alt=""/>
                    </button> </>) : (<> </>) : (<> </>)}
                    <form action="" onSubmit={handleSubmit(submitUserDetails)}>
                        <div className='reviewContainer'>
                            <div className='reviewHeader'>
                                <h5 className='reviewText'>{t('Review')}</h5>
                                {loaderEnable ? (<> <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={t('Loading..')}
                                                            color={'#FFFFFF'}
                                                            size={100}/> </>) : (<> </>)}
                            </div>
                            <div className='reviewBody'>
                                <div className='locationDetails'>
                                    <div className='boothDetails'>
                                        <p className='vSabhaHeader'>{t('Vidhan Sabha')}</p>
                                        <p className='locationValue'>{data.is_reported_event_detail ? data.event_detail.ac[0].name : data.formResult.ac_name}</p>
                                    </div>
                                    <div className='stateDetails'>
                                        <p className='stateHeader'>{t('State')}</p>
                                        <p className='locationValue'>{data.is_reported_event_detail ? data.event_detail.country_state[0].name : data.formResult.country_state.name}</p>
                                    </div>
                                </div>
                                <div className='attendees'>
                                    <p className='attendeesTitle'>{t('Total Attendees')}</p>
                                    <p className='locationValue'>{data.is_reported_event_detail ? data.event_detail.total_attendees : data.formResult.total_attendees}</p>
                                </div>
                              <div className='boothAddress'>
                                {/*<p className='boothTitle'>{t('Shakti Kendra')}</p>*/}
                                {/*<p className='locationValue'>{data.is_reported_event_detail ? data.event_detail.sk_name : data.formResult.sk_name}</p>*/}
                              </div>
                                <div className='boothAddress'>
                                    <p className='boothTitle'>{t('Booth')}</p>
                                    <p className='locationValue'>{data.is_reported_event_detail ? data.event_detail.location[0].name: data.formResult.booth_name}</p>
                                </div>
                              <div className='boothAddress'>
                                <p className='boothTitle'>{t('Address')}</p>
                                <p className='addresslocationValue'>{data.is_reported_event_detail ? data.event_detail.address : data.formResult.address}</p>
                              </div>
                                <div className='description'>
                                    <p className='descriptionTitle'>{t('Remarks')}</p>
                                    <p className='addresslocationValue'>{data.is_reported_event_detail ? data.event_detail.description : data.formResult.description}</p>
                                </div>
                                <div className='imagesContainer'>
                                    <p className='imagesTitle'>{t('Images')}</p>
                                    <div className='imageGallery'>
                                      {
                                        (data.formResult && data.formResult.photo1.length) ||  (data.is_reported_event_detail && data.event_detail.photo1.length) ?
                                          (<>
                                            <img className='imageBox' src={(data.is_reported_event_detail && data.event_detail.photo1.length) ? data.event_detail.photo1 : (data.formResult && data.formResult.photo1.length) ? data.formResult.photo1 : ''} alt='Image 1'/>
                                          </>)
                                          :
                                          (<> </>)
                                      }
                                      {
                                        (data.formResult && data.formResult.photo2.length) ||  (data.is_reported_event_detail && data.event_detail.photo2.length) ?
                                          (<>
                                            <img className='imageBox' src={(data.is_reported_event_detail && data.event_detail.photo2.length) ? data.event_detail.photo2 : (data.formResult && data.formResult.photo2.length) ? data.formResult.photo2 : ''} alt='Image 2'/>
                                          </>)
                                          :
                                          (<> </>)
                                      }
                                    </div>
                                </div>
                                {/*{submitEnable ? (<> <input type="submit" className='nextButton' value={t(submitMsg)}/> </>) : (<> </>)}*/}
                                <div className="review-button-container">
                                    {submitEnable ? submitMsg === 'Submit' ?
                                    (
                                        <>
                                            <input type="submit" className='nextButton' value={t(submitMsg)}/>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <button className='nextButton' onClick={navigateToBoothDetail}>{t(submitMsg)}</button>
                                        </>
                                    ) :
                                    (
                                        <> </>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            )}
        </div>
    )
}