import React, {useContext, useEffect, useState} from 'react';
import './report-vidhanSabha.css';
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AUTH_TOKEN, LEVELNAME} from "../SharingConstants";
import Skeleton from "@mui/material/Skeleton";
import rectangle from "../../../../public/images/rectangle-tab.svg";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../public/images/back-button.svg";
import {createFilterOptions, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {Tooltip} from "antd";
import {toast} from "react-toastify";
import {CameratoggleContext} from "../../context/cameratoggle-context";
import api from "../api";

const ReportVidhanSabha = ({eventId}) => {
    const [shimmerEffect, setShimmerEffect] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState("");
    const [zilaList, setZilaList] = useState([]);
    const [zila, setZila] = useState("");
    const [acData, setAcData] = useState([])
    const [viewFilter,setViewFilter] = useState(true);
    const [isEventBlank, setIsEventBlank] = useState(false);
    const {t} = useTranslation();
    const [sortBy, setSortBy] = useState('High to Low');
    const [overAllData, setOverAllData] = useState("");
    const {userLevel, setUserLevel} = useContext(CameratoggleContext);

    useEffect(() => {
        if (userLevel === "Zila") {
            getZilas();
        } else {
            getStates();
        }
    }, []);


    const getStates = async () => {
        setShimmerEffect(true);
        try {
            let states = await api.get('api/report/allotted_states', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await states.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setStateList(res?.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };


    const getZilas = async (state_id) => {
        setShimmerEffect(true);
        try {
            let country_state_id = ((state_id === undefined) ? "" : state_id);
            let zilas = await api.get("api/report/allotted_zilas?state_id=" + country_state_id, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await zilas.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setZilaList(res?.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };



    const getAcData = async (eventId,zila_id) => {
        setShimmerEffect(true);
        try {
            let acData = await api.get("api/report/ac_wise_reports?event_id=" + eventId + "&zila_id=" + zila_id, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await acData.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setOverAllData(res.total_data);
            if (sortBy === '') {
                setAcData(res?.data);
            } else {
                setAcData(res?.data);
                sortACData(sortBy,res.data);
            }
            if (res.data.length == 0) {
                setIsEventBlank(true);
            } else {
                setIsEventBlank(false);
            }
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };


    const handleSetState = (event,value) => {
        if(value === null) {
            setState("");
            setZila("");
            setZilaList([]);
            setAcData([]);
            setIsEventBlank(false);
        } else {
            setState(value);
            getZilas(value.id);
            setAcData([]);
            setZila("");
        }
    };
    const handleSetZila = (event,value) => {
        if (value === null) {
            setZila("");
            setAcData([]);
            setIsEventBlank(false);
        } else {
            setZila(value);
            setAcData([]);
            getAcData(eventId,value.id);
        }
    }

    const showShimmer = () => {
        return (<Box spacing={1}>
            <br/>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={400} style={{backgroundColor: '#ECECEC'}}/>
        </Box>);
    };

    const handleViewFilterFalse = () => {
        setViewFilter(false);
    }
    const handleViewFilterTrue = () => {
        setViewFilter(true);
    }

    const handleSortBy = (event) => {
        if (zilaList === []) {
            setSortBy(event.target.value);
        } else {
            setSortBy(event.target.value);
            sortACData(event.target.value,acData);
        }
    }

    const sortACData = (sortBy, data) => {
        if (sortBy === "High to Low") {
            data.sort((p1, p2) => (p1.ff_booth_count < p2.ff_booth_count) ? 1 : -1);
        } else if (sortBy === "Low to High") {
            data.sort((p1, p2) => (p1.ff_booth_count > p2.ff_booth_count) ? 1 : -1);
        } else if (sortBy === "A-Z") {
            data.sort((p1, p2) => (p1.ac_name > p2.ac_name) ? 1 : -1);
        }
    }


    return (
        <div>
            {shimmerEffect ? showShimmer() : <>
                {viewFilter ? <>
                    <div>
                        {userLevel === "Zila" ? <></> : <>
                            <div className="view-more"  onClick={handleViewFilterFalse}>
                                <u>{t('View Less')}</u>
                                <img className='up-button-icon' src={BackButton} alt=""/>
                            </div>
                        </>}
                        {userLevel === "Zila" ? <></> : <>
                            <Autocomplete
                                className="select-event-dropdown autocomplete"
                                id="combo-box-demo"
                                options={stateList}
                                value={state}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionValue = {(option) => option.id || ""}
                                filterOptions={createFilterOptions({
                                    stringify: (option) => option.id + option.name
                                })}
                                onChange={handleSetState}
                                renderInput={(params) => <TextField {...params} label={t('State')} InputLabelProps={{
                                    style: {
                                        color: 'black'
                                    }
                                }}/>}
                            />
                        </>}

                            <Autocomplete
                                className="select-event-dropdown autocomplete"
                                id="combo-box-demo"
                                options={zilaList}
                                value={zila}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionValue = {(option) => option.id || ""}
                                filterOptions={createFilterOptions({
                                    stringify: (option) => option.id + option.name
                                })}
                                onChange={handleSetZila}
                                renderInput={(params) => <TextField {...params} label={t('Zila')} InputLabelProps={{
                                    style: {
                                        color: 'black'
                                    }
                                }}/>}
                            />
                    </div>
                </> : <>
                    {userLevel === "Zila" ? <></> : <>
                        <div className="view-more" onClick={handleViewFilterTrue}>
                            <u>{t('View More')}</u>
                            <img className='down-button-icon' src={BackButton} alt=""/>
                        </div>
                    </>}
                    <Autocomplete
                        className="select-event-dropdown autocomplete"
                        id="combo-box-demo"
                        options={zilaList}
                        value={zila}
                        getOptionLabel={(option) => option.name || ""}
                        getOptionValue = {(option) => option.id || ""}
                        filterOptions={createFilterOptions({
                            stringify: (option) => option.id + option.name
                        })}
                        onChange={handleSetZila}
                        renderInput={(params) => <TextField {...params} label={t('Zila')} InputLabelProps={{
                            style: {
                                color: 'black'
                            }
                        }}/>}
                    />
                </>}

                <div className="ac-reports-container">
                    <div className="vidhan-sabha-report-header">
                        <div className="report-title">
                            <img className="rectangle-tab" src={rectangle} alt=""/>
                            {/*<h5>{t('Vidhan Sabha Wise Reports')}</h5>*/}
                            <span className='report-vidhan-sabha-text'>{t('Vidhan Sabha Wise Reports')}</span>
                        </div>

                        <FormControl size="small">
                            <InputLabel size="small" id="demo-simple-select-label">{t('Sort By')}</InputLabel>
                            <Select
                                className='sort-by-select'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={'A-Z'}
                                value={sortBy}
                                label={t('Sort By')}
                                style={{outline: '1px solid #CCCCCC;', borderRadius: '6px', height: '31px'}}
                                size="small"
                                onChange={handleSortBy}>
                                <MenuItem value={'High to Low'}>{t('High to Low')}</MenuItem>
                                <MenuItem value={'Low to High'}>{t('Low to High')}</MenuItem>
                                <MenuItem value={'A-Z'}>A-Z</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {acData.length? <>
                        <div className="overall-ac-count-container">
                            <div className='overall-ac-count'>
                                <span className='overall-count-text'>{t('OVERALL')}</span>
                                <Tooltip title={overAllData?.total_booths.toLocaleString('en-IN')} placement="top">
                                    <b className="detail-value">{overAllData.formatted_total_booths}</b>
                                </Tooltip>
                            </div>
                            <div className="vertical-line-zila"></div>
                            <div className='overall-ac-count'>
                                <span className='overall-count-text'>{t('OVERALL')}</span>
                                <Tooltip title={overAllData?.submission_total_attendees.toLocaleString('en-IN')} placement="top">
                                    <b className="detail-value">{overAllData.formatted_submission_total_attendees}</b>
                                </Tooltip>
                            </div>
                        </div>
                        {acData?.map((data) => (
                            <div className="ac-report-detail-card">
                                <div className="ac-detail">
                                    <p className="ac-number">{data.ac_number}</p>
                                    <b className="ac-name">{data.ac_name}</b>
                                </div>
                                <div className="ac-report-detail">
                                    <div className="ac-count">
                                        <p className="detail-text">{t('Form Filled Booths')}</p>
                                        <b className="detail-value">{data.ff_booth_count}</b>
                                    </div>
                                    <div className="vertical-line"></div>
                                    <div className="ac-count">
                                        <p className="detail-text">{t('Total Attendees')}</p>
                                        <b className="detail-value">{data.total_attendees === null ? '0' : data.total_attendees.toLocaleString('en-IN')}</b>
                                    </div>
                                </div>
                                <hr className="horizontal-line"/>
                            </div>
                        ))}
                    </> : <>
                        <div className="blank-report">{isEventBlank? <>{t('No Reports Available')}</> : <></>}</div>
                    </>}
                </div>

            </>}
        </div>
    );
};

export default ReportVidhanSabha;