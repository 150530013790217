import React, {useEffect, useState} from 'react';
import './report-details.css';
import {Chip} from "@mui/material";
import ReportZila from './report-zila';
import ReportBooth from './report-Booth';
import ReportVidhan from './report-vidhanSabha';
import {AUTH_TOKEN} from "../SharingConstants";
import {useTranslation} from "react-i18next";


const ReportDetails = ({eventId}) => {

    const [selectedChip, setSelectedChip] = useState(0);
    const {t} = useTranslation();

    const handleClick = (index) => {
        setSelectedChip(index);
    };

    return (
        <>
            <div className='report-chip-container'>
                <Chip
                    label={<span>{t('Zila')}</span>}
                    className={selectedChip === 0 ? 'report-chip-selected' : 'report-chip'}
                    onClick={() => handleClick(0)}
                />
                <Chip
                    label={<span>{t('Vidhan Sabha')}</span>}
                    onClick={() => handleClick(1)}
                    className={selectedChip === 1 ? 'report-chip-selected' : 'report-chip'}
                />
                <Chip
                    label={<span>{t('Booth')}</span>}
                    onClick={() => handleClick(2)}
                    className={selectedChip === 2 ? 'report-chip-selected' : 'report-chip'}
                />
            </div>
            <div>
                {selectedChip === 0 ? <ReportZila eventId={eventId}/> : null}
                {selectedChip === 1 ? <ReportVidhan eventId={eventId}/> : null}
                {selectedChip === 2 ? <ReportBooth eventId={eventId}/> : null}
            </div>
        </>

    );
};

export default ReportDetails;