import React from "react"
import App from "./app";
import {CameratoggleProvider} from "../context/cameratoggle-context";
import {BrowserRouter, useRoutes} from "react-router-dom";


import './i18n';
import {ACID, USERNAME} from "./SharingConstants";


const AppWrapper = () => {
    return (
        <BrowserRouter>
            <CameratoggleProvider>
                <App />
            </CameratoggleProvider>
        </BrowserRouter>
    );
};

export default AppWrapper;