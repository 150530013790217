import React, {createContext, useEffect, useState} from "react";
import {AUTH_STATUS, AUTH_TOKEN, LEVELNAME} from "../components/SharingConstants";
import {toast} from "react-toastify";

export const CameratoggleContext = createContext({
    togglefooter:false,
    showLogin:true,
    setTogglefooter: () => {},
    setShowLogin: () => {},
    userLevel:"",
    setUserLevel: () => {}
});

export const CameratoggleProvider = ({ children }) => {
    const [ togglefooter, setTogglefooter ] = useState(null);
    const [ showLogin, setShowLogin ] = useState(true);
    const [userLevel, setUserLevel] = useState(localStorage.getItem(LEVELNAME));

    const value = { togglefooter, setTogglefooter, showLogin, setShowLogin, userLevel, setUserLevel }
    return(
        <CameratoggleContext.Provider value={ value }>
            { children }
        </CameratoggleContext.Provider>
    )
}