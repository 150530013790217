import React, {useEffect} from 'react';
import './profile.css';
import profileBgFrame from '../../../../public/images/profile_bg_frame.svg';
import {useTranslation} from "react-i18next";

export default function Profile() {
  const { t } = useTranslation();
    return (
        <div className='profileContainer'>
            <div className='imageBackground'>
                    <img className='profileBgFrame' src={profileBgFrame} alt=''/>

            </div>
            <div className='detailsContainer'>
                <h6 className='nameText'>{t('Name')}</h6>
                <p className='phoneNumber'>{t('Phone Number')}</p>
            </div>

        </div>
    )
}
