import React, {useContext, useEffect, useState} from 'react';
import './report-Booth.css'
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AUTH_TOKEN, LEVELNAME} from "../SharingConstants";
import {useTranslation} from "react-i18next";
import rectangle from '../../../../public/images/rectangle-tab.svg';
import BackButton from "../../../../public/images/back-button.svg";
import {Tooltip} from "antd";
import Autocomplete from "@mui/material/Autocomplete";
import {createFilterOptions, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import {toast} from "react-toastify";
import {CameratoggleContext} from "../../context/cameratoggle-context";
import api from "../api";

const ReportBooth = ({eventId}) => {
    const [shimmerEffect, setShimmerEffect] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState("");
    const [vidhanSabhaList, setVidhanSabhaList] = useState([]);
    const [vidhanSabha, setVidhanSabha] = useState("");
    const [boothList, setBoothList] = useState([]);
    const [booth, setbooth] = useState("");
    const [eventDetailsList, setEventDetailsList] = useState([]);
    const {t} = useTranslation();
    const [viewFilter,setViewFilter] = useState(true);
    const [isEventBlank, setIsEventBlank] = useState(false);
    const {userLevel, setUserLevel} = useContext(CameratoggleContext);

    useEffect(key => {
        if (userLevel === "Zila") {
            getVidhanSabhaForZilaLevel();
        }
        else {
            getStates();
        }
    }, []);


    const getStates = async () => {
        setShimmerEffect(true);
        try {
            let states = await api.get('api/report/allotted_states', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await states.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setStateList(res?.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };


    const getVidhanSabhaForZilaLevel = async () => {
        setShimmerEffect(true);
        try {
            let acs = await api.get("api/report/get_acs_for_report", {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await acs.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setVidhanSabhaList(res?.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };


    const getVidhanSabha = async (state_id) => {
        setShimmerEffect(true);
        try {
            let country_state_id = ((state_id === undefined) ? "" : state_id);
            let acs = await api.get("api/event_tracker/get_acs?country_state_id="+ country_state_id, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await acs.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setVidhanSabhaList(res.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };


    const getBooths = async (ac_id, event_id) => {
        setShimmerEffect(true);
        try {
            let booths = await api.get("api/report/booth_list_for_reports?ac_id=" + ac_id + "&event_id=" + event_id, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await booths.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setBoothList(res.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };


    const getEventDetails = async (event_id,booth_id) => {
        setShimmerEffect(true);
        try {
            let booth = ((booth_id === undefined) ? "" : booth_id);
            let event = ((event_id === undefined) ? "" : event_id);
            let eventDetails = await api.get("api/report/booth_wise_reports?event_id=" + event + "&booth_id=" + booth, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await eventDetails.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setEventDetailsList(res.data);
            if (res.data.length == 0) {
                setIsEventBlank(true)
            } else {
                setIsEventBlank(false)
            }
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };



    const handleSetState = (event,value) => {
        if (value === null) {
            setState("");
            setVidhanSabha("");
            setVidhanSabhaList([]);
            setbooth("");
            setBoothList([]);
            setEventDetailsList([]);
            setIsEventBlank(false);
        } else {
            setState(value);
            setVidhanSabha("");
            setVidhanSabhaList([]);
            setbooth("");
            setBoothList([]);
            setEventDetailsList([]);
            getVidhanSabha(value.id);
        }
    };

    const handleSetVidhanSabha = (event,value) => {
        if (value === null) {
            setVidhanSabha("");
            setbooth("");
            setBoothList([]);
            setEventDetailsList([]);
            setIsEventBlank(false);
        } else {
            setVidhanSabha(value);
            setbooth("");
            setBoothList([]);
            setEventDetailsList([]);
            getBooths(value.id, eventId);
        }
    };
    const handleSetBooth = (event,value) => {
        if (value === null) {
            setbooth("");
            setEventDetailsList([]);
            setIsEventBlank(false);
        } else {
            setbooth(value);
            setEventDetailsList([]);
            getEventDetails(eventId,value.id);
        }
    };

    const showShimmer = () => {
        return (<Box spacing={1}>
            <br/>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={400} style={{backgroundColor: '#ECECEC'}}/>
        </Box>);
    };

    const handleViewFilterFalse = () => {
        setViewFilter(false);
    }
    const handleViewFilterTrue = () => {
        setViewFilter(true);
    }

    return (
        <div>
            {shimmerEffect ? showShimmer() : <>
                {viewFilter ? <>
                    <div>
                        <div className="view-more"  onClick={handleViewFilterFalse}>
                            <u>{t('View Less')}</u>
                            <img className='up-button-icon' src={BackButton} alt=""/>
                        </div>
                        {userLevel === "Zila" ? <></> : <>

                            <Autocomplete
                                className="select-event-dropdown autocomplete"
                                id="combo-box-demo"
                                options={stateList}
                                value={state}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionValue = {(option) => option.id || ""}
                                filterOptions={createFilterOptions({
                                    stringify: (option) => option.id + option.name
                                })}
                                onChange={handleSetState}
                                renderInput={(params) => <TextField {...params} label={t('State')} InputLabelProps={{
                                    style: {
                                        color: 'black'
                                    }
                                }}/>}
                            />
                        </>}

                        <Autocomplete
                            className="select-event-dropdown autocomplete"
                            id="combo-box-demo"
                            options={vidhanSabhaList}
                            value={vidhanSabha}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionValue = {(option) => option.id || ""}
                            filterOptions={createFilterOptions({
                                stringify: (option) => option.id + option.name
                            })}
                            onChange={handleSetVidhanSabha}
                            renderInput={(params) => <TextField {...params} label={t('Vidhan Sabha')} InputLabelProps={{
                                style: {
                                    color: 'black'
                                }
                            }}/>}
                        />

                        <Autocomplete
                            className="select-event-dropdown autocomplete"
                            id="combo-box-demo"
                            options={boothList}
                            value={booth}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionValue = {(option) => option.id || ""}
                            renderOption={(props, option) => {
                                return (
                                    <Grid container{...props}>
                                        <Grid item xs={11}>{option.name}</Grid>
                                        <Grid item xs={1}>{option.total_reports}</Grid>
                                    </Grid>
                                );
                            }}
                            filterOptions={createFilterOptions({
                                stringify: (option) => option.id + option.name
                            })}
                            onChange={handleSetBooth}
                            renderInput={(params) => <TextField {...params} label={t('Booth')} InputLabelProps={{
                                style: {
                                    color: 'black'
                                }
                            }}/>}
                        />
                    </div>
                </> : <>
                    <div className="view-more" onClick={handleViewFilterTrue}>
                        <u>{t('View More')}</u>
                        <img className='down-button-icon' src={BackButton} alt=""/>
                    </div>
                    <Autocomplete
                        className="select-event-dropdown autocomplete"
                        id="combo-box-demo"
                        options={boothList}
                        value={booth}
                        getOptionLabel={(option) => option.name || ""}
                        getOptionValue = {(option) => option.id || ""}
                        renderOption={(props, option) => {
                            return (
                                <Grid container{...props}>
                                    <Grid item xs={11}>{option.name}</Grid>
                                    <Grid item xs={1}>{option.total_reports}</Grid>
                                </Grid>
                            );
                        }}
                        filterOptions={createFilterOptions({
                            stringify: (option) => option.id + option.name
                        })}
                        onChange={handleSetBooth}
                        renderInput={(params) => <TextField {...params} label={t('Booth')} InputLabelProps={{
                            style: {
                                color: 'black'
                            }
                        }}/>}
                    />
                </>}

                <div className="booth-reports-container">
                    <div className="booth-report-header">
                        <img className="rectangle-tab" src={rectangle} alt=""/>
                        <h5>{t('Booth Wise Reports')}</h5>
                    </div>
                    {eventDetailsList.length? <>
                        {eventDetailsList?.map((eventDetail) => (
                            <div className="booth-event-detail-card">
                                <div className="event-detail-field">
                                    <p className="details-lable">{t('Reportee Name & No')}. :&nbsp; </p>

                                    <Tooltip title={eventDetail.reportee_name_and_number.length ? eventDetail.reportee_name_and_number : ""} placement="right">
                                        <p className="details-value">{eventDetail.reportee_name_and_number.length ? eventDetail.reportee_name_and_number : ""}</p>
                                    </Tooltip>
                                </div>
                                <div className="event-detail-field">
                                    <p className="details-lable">{t('Total Attendees')} :&nbsp; </p>
                                    <Tooltip title={eventDetail.total_attendees.length ? eventDetail.total_attendees : ""} placement="right">
                                        <p className="details-value">{eventDetail.total_attendees.length ? eventDetail.total_attendees : ""}</p>
                                    </Tooltip>
                                </div>
                                <div className="event-detail-field">
                                    <p className="details-lable">{t('Remark')} :&nbsp; </p>
                                    <Tooltip title={eventDetail.remark.length ? eventDetail.remark : ""} placement="right">
                                        <p className="details-value">{eventDetail.remark.length ? eventDetail.remark : ""}</p>
                                    </Tooltip>
                                </div>
                                <div className="event-detail-field">
                                    <p className="details-lable">{t('Address')} :&nbsp;</p>
                                    <Tooltip title={eventDetail.address.length ? eventDetail.address : ""} placement="right">
                                        <p className="details-value">{eventDetail.address.length ? eventDetail.address : ""}</p>
                                    </Tooltip>
                                </div>
                                <div className="dotted-line"></div>
                                <div className="event-detail-field">
                                    <div>
                                        {eventDetail.photo_one.length ? (<>
                                            <img className='imageBox' src={eventDetail.photo_one.length ? eventDetail.photo_one : ""}/>
                                        </>) : (<></>)}
                                    </div>
                                    <div>
                                        {eventDetail.photo_two.length ? (<>
                                            <img className='imageBox' src={eventDetail.photo_two.length ? eventDetail.photo_two : ""}/>
                                        </>) : (<></>)}

                                    </div>
                                </div>
                            </div>
                        ))}
                    </>: <>
                        <div className="blank-report">{isEventBlank? <>{t('No Reports Available')}</> : <></>}</div>
                    </>}
                </div>
            </>}
        </div>
    );
};

export default ReportBooth;