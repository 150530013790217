import React, {useContext, useEffect, useState} from 'react';
import './report.style.css';
import BackButton from "../../../../public/images/back-button.svg";
import {useNavigate} from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ReportsOverview from "../reports-overview/reports-overview";
import ReportDetails from "../report-details/report-details";
import {ACID, AUTH_STATUS, AUTH_TOKEN, USERNAME} from "../SharingConstants";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";
import {createFilterOptions, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {toast} from "react-toastify";
import {CameratoggleContext} from "../../context/cameratoggle-context";
import api from "../api";

const ReportComponent = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState('1');
    const [event, setEvent] = useState('')
    const [eventId, setEventId] = useState('');
    const [eventData, setEventData] = useState([]);
    const [eventStartDate, setEventStartDate] = useState('');
    const [eventEndDate, setEventEndDate] = useState('');
    const [eventName, setEventName] = useState('');
    const {t} = useTranslation();
    const {userLevel, setUserLevel} = useContext(CameratoggleContext);


    const getEventsName = async () => {
        try {
            const events = await api.get('api/report/get_events_for_reports', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await events.data;
            if (res.status === false) {
                toast(res.message);
                return;
            }
            setEventData(res?.data);
            setEventId(res?.data[0].id);
            setEvent(res?.data[0])
            setEventStartDate(res?.data[0].start_date);
            setEventEndDate(res?.data[0].end_date);
            setEventName(res?.data[0].name)
        } catch (error) {
            console.error('API Call Error:', error);
        }
    };


    const getUserLevel = async () => {
        try {
            const level = await api.get('api/report/user_level', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await level.data;
            if (res.status === false) {
                toast(res.message);
                return;
            }
            setUserLevel(res.data.level_name);
        } catch (error) {
            console.error('API Call Error:', error);
        }
    };


    useEffect(() => {
        let authStatus= JSON.parse(localStorage.getItem(AUTH_STATUS));
        if(authStatus) {
            getEventsName();
            getUserLevel();
        }
    }, []);

    useEffect(() => {
        let authStatus= JSON.parse(localStorage.getItem(AUTH_STATUS));
        if(!authStatus) {
            navigate('/', { replace: false })
        }
    });

    useEffect(() =>{
        {
            if(!(localStorage.getItem(USERNAME) && localStorage.getItem(ACID) && localStorage.getItem('satateId'))){
                navigate('/login')
            }
        }
    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEventChange = (event, value) => {
        if (value === null) {
            toast(t('Please select an event'))
            setEvent('');
            setEventId('');
            setEventStartDate('');
            setEventEndDate('');
            setEventName('');
        } else {
            setEvent(value);
            setEventName(value.name);
            setEventId(value.id);
            setEventStartDate(value.start_date);
            setEventEndDate(value.end_date);
            setEventName(value.name);
            // handleEventDates(value);
        }
    };

    const handleEventDates = (option) => {
        setEventStartDate(option.start_date);
        setEventEndDate(option.end_date);
        setEventName(option.name);
    }

    return (
        <div className='container-fluid report'>

            <span className='reports-header'>
                <img className='backButtonIcon' src={BackButton} alt='Home' onClick={() => (navigate('/'))}/>
                <span className='reports-text'>{value === '1' ? (<><span>{t('Reports')}</span></>) : eventName}</span>
            </span>

            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

                        {eventId === "" ?
                            <TabList onChange={handleChange} variant="fullWidth">
                                <Tab label={<span style={{margin: '0 0 0 53px'}}>{t('Overview')}</span>} value="1"
                                     className='report-overview-text'
                                     style={{color: value === '1' ? '#173B58' : '#B3B3B3'}}/>
                                <Tab disabled label={<span style={{margin: '0 0 0 53px'}}>{t('Report')}</span>}
                                     value="2" className='report-text'
                                     style={{color: value === '2' ? '#173B58' : '#B3B3B3'}}/>
                            </TabList>
                            :
                            <TabList onChange={handleChange} variant="fullWidth">
                                <Tab label={<span style={{margin: '0 0 0 53px'}}>{t('Overview')}</span>} value="1"
                                     className='report-overview-text'
                                     style={{color: value === '1' ? '#173B58' : '#B3B3B3'}}/>
                                <Tab label={<span style={{margin: '0 0 0 53px'}}>{t('Report')}</span>} value="2"
                                     className='report-text' style={{color: value === '2' ? '#173B58' : '#B3B3B3'}}/>
                            </TabList>
                        }

                    </Box>

                    {value === '1' ?
                        <Autocomplete
                            className="select-event-dropdown"
                            id="combo-box-demo"
                            options={eventData}
                            blurOnSelect={true}
                            value={event}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionValue={(option) => option.id || ""}
                            filterOptions={createFilterOptions({
                                stringify: (option) => option.id + option.name
                            })}
                            onChange={handleEventChange}
                            renderInput={(params) => <TextField {...params} label={t('Select Event')} InputLabelProps={{
                                style: {
                                    color: 'black'
                                }
                            }}/>}
                        />
                        : null}

                    <TabPanel className="report-overview-section" value="1"><ReportsOverview eventId={eventId}
                                                                                             eventStartDate={eventStartDate}
                                                                                             eventEndDate={eventEndDate}/></TabPanel>
                    <TabPanel className="report-overview-section" value="2"><ReportDetails
                        eventId={eventId}/></TabPanel>
                </TabContext>
            </Box>

        </div>
    );
};

export default ReportComponent;