import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCuJZh1as7lca4eUqZBBAc7DUdcVXeYqyA",
        authDomain: "mannkibaat-c0082.firebaseapp.com",
            projectId: "mannkibaat-c0082",
                storageBucket: "mannkibaat-c0082.appspot.com",
                    messagingSenderId: "1003173601522",
                        appId: "1:1003173601522:web:a74e8ce2a714ee8f1d706b",
                            measurementId: "G-8DCHYQ8EV1",
                                };

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);