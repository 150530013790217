export const AUTH_STATUS = 'authStatus';
export const AUTH_TOKEN = 'authToken';
export const USERID = 'userId';
export const USERNAME = 'userName';
export const USERPHONE = 'userPhone';
export const USERIMAGE = 'userImage';

// location
export const STATENAME = 'satateName';
export const STATEID = 'satateId';
export const ACNAME = 'acName';
export const ACID = 'acId';
export const PARTYZILANAME = 'partyZilaName';
export const PARTYZILAID = 'partyZilaId';
export const MANDALNAME = 'mandalName';
export const MANDALID = 'mandalId';
export const BOOTHNAME = 'boothName';
export const BOOTHID = 'boothId';
export const LEVELNAME = 'levelName';

export const AUTHERROR = ["Access denied!. Token invalid. No verification key available", "Authentication token not available", "Access denied!. Token invalid. Not enough or too many segments"]