import React from "react";
import { render } from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Index from "../components/Index";

document.addEventListener("turbolinks:load", () => {
  render(
      <Index />,
      document.body.appendChild(document.createElement("div"))
  );
});