import React, {useContext, useEffect} from 'react';
import './footer.css';
import FooterImgs from '../../../../public/images/footer-img.png'
import {CameratoggleContext} from "../../context/cameratoggle-context";

export default function Footer() {
    const {showLogin} = useContext(CameratoggleContext);
    return (
        <div>
                <div className='footerContainer'>
                    <div className='footerImgBackground'></div>
                </div>
        </div>
    )
}
