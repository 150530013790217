import React, {useContext, useEffect, useState} from 'react';
import './sidebar.css';
import pfp from '../../../../public/images/Vector.png';
import homeIcon from '../../../../public/images/house-door-fill.svg'
import profileIcon from '../../../../public/images/person-fill.svg';
import logOutIcon from '../../../../public/images/box-arrow-right.svg';
import sideBarIcon from '../../../../public/images/Vector.png';
import {useNavigate} from "react-router-dom";
import defaultAvatar from '../../../../public/images/defaultAvatar'
import {AUTH_STATUS, AUTH_TOKEN, LEVELNAME, USERIMAGE, USERNAME, USERPHONE} from "../SharingConstants";
import {useTranslation} from 'react-i18next';
import PollIcon from '@mui/icons-material/Poll';
import {toast} from "react-toastify";
import {CameratoggleContext} from "../../context/cameratoggle-context";

toast.configure()
let userInfo = {};
export default function Sidebar() {

    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userPhoto, setUserPhoto] = useState('');
    const {setShowLogin} = useContext(CameratoggleContext);
    const {t} = useTranslation();
    const {userLevel, setUserLevel} = useContext(CameratoggleContext);


    async function getUserInfo() {
        let states = await fetch(window.location.origin + "/api/event_tracker/get_user_info", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
        });
        const response = await states.json();
        setUserInfoState(response);
    }

    function setUserInfoState(user) {
        setUserName(user.data.name);
        setUserPhone(user.data.phone);
        setUserPhoto(user.data.photo);
    }

    function logOut() {
        const defaultLang = localStorage.getItem('defaultLang');
        localStorage.clear();
        localStorage.setItem('defaultLang', defaultLang)
        navigate('/', {replace: false});
        setShowLogin(true);
        toast(t('Logout Successfully'))
    }

    function navigateToProfile() {
        closeNav();
        navigate('/edit-profile', {replace: false});
    }

    function navigateToProgram() {
        closeNav();
        navigate('/', {replace: false});
    }

    const navigateToReport = () => {
        closeNav();
        navigate('/report', {replace: false});
    }

    return (
        <div className='sideNavBar'>
            <div id="mySidebar" className="sidebar">
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>×</a>
                <div className='imageContainer'>
                    <img className='pfpImage' src={userPhoto ? userPhoto : defaultAvatar} alt='Not available'/>
                    <div className='personInfo'>
                        <p className='personName m-0 text-break'>{userName}</p>
                        <p className='personNumber m-0 text-break'>{userPhone}</p>
                    </div>
                </div>
                <div className='sidebar-button-container'>
                    <button className='homeButton' onClick={navigateToProgram}>
                        <img className='iconButton' src={homeIcon} alt=""/>
                        <p className='iconText'>{t('Reported Program')}</p>
                    </button>
                </div>
                <hr/>
                <div className='sidebar-button-container'>
                    <button className='profileButton' onClick={navigateToProfile}>
                        <img className='iconButton' src={profileIcon} alt=""/>
                        <p className='iconText'>{t('Profile')}</p>
                    </button>
                </div>
                <hr></hr>
                { userLevel ?(<>
                    <div className='sidebar-button-container extra-padding'>
                        <button className='profileButton' onClick={navigateToReport}>
                            {/*<img className='iconButton' src={PollIcon} alt=""/>*/}
                            <PollIcon/>
                            <p className='iconText extra-text-padding'>{t('Report')}</p>
                        </button>
                    </div>
                    <hr></hr>
                    </>) : null}
                <div className='sidebar-button-container'>
                    <button className='logOutButton' onClick={logOut}>
                        <img className='iconButton' src={logOutIcon} alt=""/>
                        <p className='iconText'>{t('Logout')}</p>
                    </button>
                </div>
            </div>

            <div id="main">
                <button onClick={(event) => {
                    openNav();
                    getUserInfo();
                }}>
                    <img className='sideBarIcon' src={sideBarIcon} alt=''/>
                </button>
            </div>
        </div>
    )
}

function openNav() {
    document.getElementById("mySidebar").style.width = "85%";
    document.getElementById("main").style.marginLeft = "85%";
}

function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
}