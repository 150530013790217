import React, {useEffect, useContext} from 'react';
import './header.css';
import bjpLogoImage from '../../../../public/images/BJP-logo.svg';
import langChangeImg from '../../../../public/images/Polygon 5.svg';
import bjpNameImg from '../../../../public/images/bjpname.svg';
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../sidebar/Sidebar'
import {AUTH_STATUS, AUTH_TOKEN, DEFAULTlANGUAGE} from "../SharingConstants";
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import {CameratoggleContext} from "../../context/cameratoggle-context";
toast.configure()

export default function Header() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { Option } = Select;
    const {showLogin} = useContext(CameratoggleContext);

  function changeLang(lang) {
    i18n.changeLanguage(lang)
    localStorage.setItem('defaultLang', lang);
  }

    function navigateToHome(s, replace) {
        navigate(s, replace)
    }

    let headerFlag = localStorage.getItem('acId') !== null && localStorage.getItem('acId') !== '';
    return (
        <div>
                <div className='headerContainer'>
                    <div className={headerFlag ? "" : 'disableSidebar'}>
                        {localStorage.getItem(AUTH_STATUS) ? ( <> <Sidebar /> </> ) : ( <> </> )}
                    </div>
                    <div className={headerFlag? 'bjpLogo' : 'disableLogo'}>
                        <a onClick={() => navigateToHome('/', { replace: false })}><img className='bjpLogoImg' src={bjpLogoImage} alt=""/></a>
                    </div>
                    <div className='bjpName'>
                        <img className='bjpNameImg' src={bjpNameImg} alt="" />
                    </div>
                    {localStorage.getItem(AUTH_STATUS) ?
                        ( <Select
                            defaultValue={localStorage.getItem('defaultLang') ? localStorage.getItem('defaultLang') : "en" }
                            style={{ width: 100 , marginRight: 12 }}
                            onChange={(e)=> {changeLang(e)}}
                            disabled={false}>
                            <Option value="en">English</Option>
                            <Option value="hn">Hindi</Option>
                            <Option value="gj">Gujarati</Option>
                            <Option value="pb">Punjabi</Option>
                            <Option value="mh">Marathi</Option>
                            <Option value="bg">Bangla</Option>
                            <Option value="tm">Tamil</Option>
                            <Option value="ml">Malayalam</Option>
                            <Option value="kn">Kannada</Option>
                            <Option value="as">Assamese</Option>
                            <Option value="od">Odia</Option>
                            <Option value="tl">Telugu</Option>
                        </Select>)
                        : ( <> </> )}
                </div>

        </div>
  )
}

