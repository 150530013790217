import React, {useEffect} from 'react';
import './success.css';
import success from '../../../../public/images/Success.svg';
import {AUTH_STATUS} from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function Success() {
    const navigate = useNavigate();
  const { t } = useTranslation();
    useEffect(() => {
        let authStatus= JSON.parse(localStorage.getItem(AUTH_STATUS));
        if(!authStatus) {
            navigate('/', { replace: false })
        }
    });
    return (
        <div className='successContainer'>
            <div className='successImageContainer'>
                <img className='successImage' src={success} alt=''/>
            </div>
            <div className='successMessage'>
                <h6 className='successfully'>{t('Program Reported Successfully')}</h6>
                <h6 className='goodWork'>{t('Yay! Keep doing the good work!')}</h6>
            </div>
        </div>
    )
}
