// api.js

import axios from 'axios';
import {AUTHERROR} from "./SharingConstants";
import {toast} from "react-toastify";

const api = axios.create({
    baseURL: process.env.BASE_URL, // Replace with your API's base URL
});
// Request interceptor
api.interceptors.request.use((config) => {
    // console.log('API Request:', config);
    return config;
}, (error) => {
    // console.error('API Request Error:', error);
    return Promise.reject(error);
});

// Response interceptor
api.interceptors.response.use((response) => {
    // console.log('API Response:', response);
    return response;
}, (error) => {
    // console.error('API Response Error:', error);
    if (AUTHERROR.includes(error.response.data.message)){
        toast.dismiss();
        const defaultLang = localStorage.getItem('defaultLang');
        localStorage.clear();
        localStorage.setItem('defaultLang', defaultLang);
        toast(error.response.data.message);
        setTimeout(() => {
            window.location.reload();
        },1000)
        return
    }
    return Promise.reject(error);
});


export default api;