import React, {useEffect} from 'react';
import './limitexceeded.css';
import limitExceeded from '../../../../public/images/limitExceeded.svg';
import {AUTH_STATUS} from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function Success() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    useEffect(() => {
        let authStatus= JSON.parse(localStorage.getItem(AUTH_STATUS));
        if(!authStatus) {
            navigate('/', { replace: false })
        }
    });
    return (
        <div className='limitExceedContainer'>
            <div className='limitImageContainer'>
                <img className='limitImage' src={limitExceeded} alt=''/>
            </div>
            <div className='limitExceedMessage'>
                <h6 className='cannotReport'>{t("Program Can't be Reported")}</h6>
                <h6 className='maxReportText'>{t('You have reached the maximum limit of report entries')}</h6>
                <a className='goReport' href="/program">{t('Go to Reported Programs')}</a>
            </div>
        </div>
    )
}
