import React, {useEffect, useState} from 'react';
import './areaTimeChart.css'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {useTranslation} from "react-i18next";
// import DatePicker from "react-horizontal-datepicker";

const AreaTimeChart = ({areaData}) => {

    const {t} = useTranslation();

    const chartDataSeries = areaData?.map(dataPoint => ({
        y: dataPoint.count
    }));
    const chartDataCategories = areaData?.map(dataPoint => ({
        x: dataPoint.time
    }));
    const chartOptions = {
        chart: {
            type: 'area'
        }, xAxis: {
            categories: chartDataCategories.map(l => l.x),
            tickWidth: 1,
            tickmarkPlacement: 'on',
            tickColor: '#000000',
            lineColor: '#000000',
            startOnTick: 'true',
            gridLineWidth: 1,
            gridLineDashStyle: 'dot',
            gridLineColor: '#CCCCCC',
            labels: {
                style: {
                    color: '#000000', format: '{value}%', marginRight: '118px'
                }
            },
        },
        plotOptions: {
            area: {
                events: {
                    legendItemClick: function () {
                        return false; // Disable legend item click
                    }
                }
            }
        },
        yAxis: {
            title: {
                text: null
            },
            tickWidth: 2,
            labels: {
                style: {
                    color: '#000000'
                }
            },
            min: 0,
            minRange: 0.1,
            tickColor: '#000000',
            startOnTick: true,
            gridLineWidth: 1,
            gridLineDashStyle: 'dot',
            gridLineColor: '#CCCCCC',
            lineWidth: 2,
            lineColor: '#000000',
        },

        credits: {
            enabled: false
        },

        title: {
            text: null
        }, legend: {
            itemStyle: {
                fontSize: 11, fontFamily: 'Quicksand', fontWeight: '700'
            }, align: 'centre', verticalAlign: 'top', itemMarginTop: 10, itemMarginBottom: 10,
        }, series: [{
            name: 'Events Reported', data: chartDataSeries, connectNulls: true,
        }]
    };

    return (<div>
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    </div>);
};

export default AreaTimeChart;