import React, {useEffect, useState} from 'react';
import './stackedCard.css'
import {AUTH_TOKEN} from "../SharingConstants";
import Skeleton from "@mui/material/Skeleton";
import {useTranslation} from "react-i18next";
import {Tooltip} from "antd";

const StackedCard = ({boothWiseData}) => {

    const formatNumber = (num) => {
        num = ((99999 < num) && (num < 9999999)) ? ((num / 100000).toFixed(1) + 'L') : ((num > 9999999) ? ((num / 10000000).toFixed(1) + 'Cr') : num)
        return num.toLocaleString('en-IN');
    }

    const {t} = useTranslation();
    return (<>
        <div className='report-overview-stacked-card'>
            <Tooltip title={boothWiseData?.total_event_details.toLocaleString('en-IN')} placement="top">
                <span className='report-overview-details'>
                    <span className='vidhan-Form-total-stats'>{boothWiseData?.formatted_total_event_details}</span>
                    <span className='vidhan-Form-total-text'>{t('Total Event Reports')}</span>
                </span>
            </Tooltip>
            <Tooltip title={boothWiseData?.booth_covered.toLocaleString('en-IN')} placement="top">
                <span className='report-overview-details'>
                    <span className='vidhan-Form-total-stats'>{boothWiseData?.formatted_booth_covered}</span>
                    <span className='vidhan-Form-total-text'>{t('Form Filled Booths')}</span>
                </span>
            </Tooltip>
            <Tooltip title={boothWiseData?.total_attendees === null ? '0' : parseInt(boothWiseData?.total_attendees, 10).toLocaleString('en-IN')} placement="top">
                <span className='report-overview-details'>
                    <span className='vidhan-Form-total-stats'>{boothWiseData?.formatted_ta}</span>
                    <span className='vidhan-Form-total-text'>{t('Total Attendees')}</span>
                </span>
            </Tooltip>
        </div>
    </>)
};

export default StackedCard;