import React, {useEffect, useState} from 'react';
import './semi-circle.chart.css'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useTranslation} from "react-i18next";
import {Tooltip} from "antd";

const SemiCircleChart = ({semiCircleData}) => {
    const {t} = useTranslation();

    const options = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            height: '100%',
        },
        title: {
            useHTML: true,
            text: `<span class="semi-circle-ellipse">${semiCircleData?.formatted_reported_booth}<br>Reported Booths</span>`,
            align: 'center',
            verticalAlign: 'middle',
            y: 100
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false, // Disable tooltip
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    // distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                showInLegend: false,
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '100%',
                colorByPoint: true,
                colors: ['#FF9559', '#FFEADF'],
            },
        },
        // plotOptions: {
        //     pie: {
        //         innerSize: '50%',
        //         dataLabels: {
        //             enabled: false
        //         },
        //         showInLegend: true
        //     }
        // },
        series: [{
            innerSize: '60%',
            data: [{
                name: 'reported booth percentage',
                // y: 30,
                y: semiCircleData?.reported_booth_percentage
            }, {
                name: 'left booth percentage',
                // y: 70
                y: semiCircleData?.left_booth_percentage
            }]
        }],
    };

    // const xyz = (semiCircleData?.reported_booth_percentage);

    return (
        <div className='semi-circle-container'>

            <span className='events-reported-booth-text'>{t('Events Reported Booth')}</span>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}/>

            <div className="position-relative">
                <div className="gauge-percentage">
                    <p className='reported-booth-percentage-text'>{(Math.round((semiCircleData?.reported_booth_percentage)*100)/100)+"%"}</p>
                    <p className='reported-booth-percentage-text'>{(Math.round((semiCircleData?.left_booth_percentage)*100))/100+"%"}</p>
                    {/*<p className='reported-booth-percentage-text'>{Math.round(semiCircleData?.left_booth_percentage)+"%"}</p>*/}
                </div>
            </div>

            <span className='semi-circle-total-booths'>
                <span className='semi-circle-total-booths-text'>{t('Total Booths')}</span>
                 <Tooltip title={semiCircleData?.total_booths.toLocaleString('en-IN')} placement="top">
                    <span className='semi-circle-total-booths-data'>{semiCircleData?.formatted_total_booth}</span>
                </Tooltip>
            </span>
        </div>

    );
};

export default SemiCircleChart;
