import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Logout successfully": "Logout successfully",
      "Update": "Update",
      "Start reporting on : ": "Start reporting on : ",
      "You can only post after the program starts. ": "You can only post after the program starts. ",
      "No Programs": "No Programs",
      "Please enter a valid Name": "Please enter a valid Name",
      "Event Address": "Event Address",
      "Image (Max 2)": "Image (Max 2)",
      "Geolocation not supported": "Geolocation not supported",
      "Geolocation not found": "Geolocation not found",
      "Permission denied": "Permission denied",
      "(Go to setting >> Site settings >>": "(Go to setting >> Site settings >>",
      "Locations >> mannkibaatprogram >> Allow access to location)": "Locations >> mannkibaatprogram >> Allow access to location)",
      "(Go to setting >> Privacy and security >> Site settings >>": "(Go to setting >> Privacy and security >> Site settings >>",
      "Shakti Kendra": "Shakti Kendra",
      "Bharatiya Janata Party" : "Bharatiya Janata Party",
      "Reported Program" : "Reported Program",
      "Profile" : "Profile",
      "Login" : "Login",
      "Logout" : "Logout",
      "Mobile Number" : "Mobile Number",
      "Send OTP" : "Send OTP",
      "Verify Details" : "Verify Details",
      "Enter OTP sent to" : "Enter OTP sent to",
      "seconds left in Resend OTP" : "seconds left in Resend OTP",
      "Otp Sent successfully" : "Otp Sent successfully",
      "Login successfully" : "Login successfully",
      "Invalid OTP" : "Invalid OTP",
      "Phone Required" : "Phone Required",
      "Programs" : "Programs",
      "Aired on" : "Aired on",
      "Report" : "Report",
      "Event not reported" : "Event not reported",
      "Click here to know more" : "Click here to know more",
      "Edit Profile" : "Edit Profile",
      "Phone Number" : "Phone Number",
      "Name" : "Name",
      "Name required" : "Name required",
      "Select profile photo" : "Select profile photo",
      "State" : "State",
      "State required" : "State required",
      "Vidhan Sabha required" : "Vidhan Sabha required",
      "Vidhan Sabha" : "Vidhan Sabha",
      "Party Zila" : "Party Zila",
      "Party Zila required" : "Party Zila required",
      "Party Mandal required" : "Party Mandal required",
      "Phone required" : "Phone required",
      "Booth required" : "Booth required",
      "Mandal" : "Mandal",
      "Booth" : "Booth",
      "WhatsApp Number" : "WhatsApp Number",
      "Education" : "Education",
      "Profession" : "Profession",
      "Voter ID" : "Voter ID",
      "Pincode" : "Pincode",
      "File can't be upload please select file upto 1 MB" : "File can't be upload please select file upto 1 MB",
      "File should be JPG or PNG format" : "File should be JPG or PNG format",
      "Save" : "Save",
      "Event Reported Successfully" : "Event Reported Successfully",
      "User Updated Successfully" : "User Updated Successfully",
      "Enter Details" : "Enter Details",
      "Total Attendees" : "Total Attendees",
      "TOTAL ATTENDEES" : "TOTAL ATTENDEES",
      "Remarks" : "Remarks",
      "Upload Image" : "Upload Image",
      "Capture Image" : "Capture Image",
      "Capture" : "Capture",
      "Switch" : "Switch",
      "Close" : "Close",
      "Review" : "Review",
      "Please fill all * mandatory fields" : "Please fill all * mandatory fields",
      "Upload minimum one image" : "Upload minimum one image",
      "Total Attendees required" : "Total Attendees required",
      "Enter Your Full Name" : "Enter Your Full Name",
      "Select State" : "Select State",
      "Next" : "Next",
      "Program Reported Successfully" : "Program Reported Successfully",
      "Yay! Keep doing the good work!" : "Yay! Keep doing the good work!",
      "Address" : "Address",
      "Images" : "Images",
      "Submit" : "Submit",
      "Upcoming Event" : "Upcoming Event",
      "Booth in Shakti Kendra" : "Booth in Shakti Kendra",
      "Shakti kendra in AC" : "Shakti kendra in AC ",
      "Shakti kendra required" : "Shakti kendra required",
      "Other Booth in AC" : "Other Booth in AC",
      "Address (where event is organised)" : "Address (where event is organised)",
      "Phone number not valid and should be 10 digit" : "Phone number not valid and should be 10 digit",
      "Invalid Phone Number" : "Invalid Phone Number",
      "Logout Successfully" : "Logout Successfully",
      "Didn’t recieve OTP" : "Didn’t recieve OTP",
      "Resend" : "Resend",
      "Program" : "Program",
      "OR" : "OR",
      "Report Again" : "Report Again",
      "File size exceeds limit! Please select a file upto 10 MB" : "File size exceeds limit! Please select a file upto 10 MB",
      "View Less" : "View Less",
      "View More" : "View More",
      "Reports" : "Reports",
      "Overview" : "Overview",
      "Select Event" : "Select Event",
      "Events Reported" : "Events Reported",
      "Vidhan Sabha Covered" : "Vidhan Sabha Covered",
      "VIDHAN SABHA COVERED" : "VIDHAN SABHA COVERED",
      "Form Filled Booths" : "Form Filled Booths",
      "FORM FILLED BOOTHS" : "FORM FILLED BOOTHS",
      "Events Reported Booths" : "Events Reported Booths",
      "Reported Booths" : "Reported Booths",
      "Total Booths" : "Total Booths",
      "Zila" : "Zila",
      "Zila Wise Reports" : "Zila Wise Reports",
      "Vidhan Sabha Wise Reports" : "Vidhan Sabha Wise Reports",
      "Booth Wise Reports" : "Booth Wise Reports",
      "Reportee Name & No" : "Reportee Name & No",
      "Remark" : "Remark",
      "No Reports Available" : "No Reports Available",
      "Sort By" : "Sort By",
      "High to Low" : "High to Low",
      "Low to High" : "Low to High",
      "Please select an event" : "Please select an event",
      "Total Event Reports" : "Total Event Reports",
      "OVERALL" : "OVERALL"
    }
  },
  hn: {
    translation: {
      "Address (where event is organised) ": "पता  (जहाँ कार्यक्रम आयोजित हुआ)",
      "Update":"अपडेट करे",
      "Loading..": "लोड हो रहा है..",
      " Enter OTP sent to": " पर भेजा गया ओटीपी दर्ज करें",
      "Please wait": "कृपया प्रतीक्षा करें",
      "Start reporting on ": "कृपया सही समय पर रिपोर्ट करें: ",
      "Please enter a valid pincode": "कृपया वैध पिनकोड लिखें",
      "Please enter a valid WhatsApp number(10 digits)": "व्हाट्सएप नंबर अमान्य है ",
      "Shakti kendra in AC": "विधानसभा के अंतर्गत शक्तिकेंद्र",
      "Image": "फ़ोटो",
      "OR": "या",
      "Start reporting on : ":"इस पर रिपोर्ट करना शुरू करें : ",
      "You can only post after the program starts. ": "आप कार्यक्रम शुरू होने के बाद ही पोस्ट कर सकते हैं. ",
      "No Programs": "कोई कार्यक्रम नहीं",
      "Please enter a valid Name": "कृपया कोई मान्य नाम दर्ज करें",
      "Event Address": "कार्यक्रम का पता",
      "Image (Max 2)": "फ़ोटो (अधिकतम 2)",
      "Geolocation not supported": "जियोलोकेशन का सपोर्ट नहीं है ",
      "Geolocation not found": "जियोलोकेशन नहीं मिला",
      "Permission denied": "अनुमति नहीं है",
      "(Go to setting >> Site settings >>": "(सेटिंग पर जाएं >> साइट सेटिंग्स >>",
      "Locations >> mannkibaatprogram >> Allow access to location)": "लोकेशन >> मन की बात >> लोकेशन एक्सेस की अनुमति)",
      "(Go to setting >> Privacy and security >> Site settings >>": "(सेटिंग पर जाएं >> प्राइवेसी एवं सिक्योरिटी >> साइट सेटिंग्स >>",
      "Shakti Kendra": "शक्ति केंद्र",
      "Bharatiya Janata Party" : "भारतीय जनता पार्टी",
      "Reported Program" : "कार्यक्रम सूचना ",
      "Profile" : "प्रोफ़ाइल",
      "Login" : "लॉग इन",
      "Logout" : "लॉग आउट",
      "Mobile Number" : "मोबाइल नंबर",
      "Send OTP" : "OTP भेजें",
      "Verify Details" : "विवरण सत्यापित करें",
      "Enter OTP sent to" : "OTP भेजा गया",
      "seconds left in Resend OTP" : "OTP पुन: भेजने में सेकंड ",
      "Otp Sent successfully" : "OTP सफलतापूर्वक भेजा गया",
      "Login successfully" : "सफलतापूर्वक लॉगिन",
      "Invalid OTP" : "अमान्य ओटीपी",
      "Phone Required" : "फोन अनिवार्य है",
      "Programs" : "कार्यक्रम",
      "Aired on" : "पर प्रसारित किया गया",
      "Report" : "रिपोर्ट",
      "Event not reported" : "घटना की रिपोर्ट नहीं",
      "Click here to know more" : "अधिक जानकारी के लिए यहां क्लिक करें",
      "Edit Profile" : "प्रोफ़ाइल संपादित करें",
      "Phone Number" : "फ़ोन नंबर",
      "Name" : "नाम",
      "Name required" : "नाम अनिवार्य है",
      "Select profile photo" : "प्रोफ़ाइल फोटो का चयन करें",
      "State" : "राज्य",
      "State required" : "राज्य अनिवार्य है",
      "Vidhan Sabha required" : "विधान सभा अनिवार्य है",
      "Vidhan Sabha" : "विधान सभा",
      "Party Zila" : "पार्टी जिला",
      "Party Zila required" : "पार्टी जिला अनिवार्य है",
      "Party Mandal required" : "पार्टी मंडल अनिवार्य है",
      "Phone required" : "फ़ोन नंबर अनिवार्य है",
      "Booth required" : "बूथ अनिवार्य है",
      "Mandal" : "मंडल",
      "Booth" : "बूथ",
      "WhatsApp Number" : "व्हाट्सएप नंबर",
      "Education" : "शिक्षा",
      "Profession" : "व्यवसाय",
      "Voter ID" : "मतदाता पहचान पत्र",
      "Pincode" : "पिन कोड",
      "File can't be upload please select file upto 1 MB" : "फ़ाइल अपलोड नहीं की जा सकती कृपया 1 MB तक की फ़ाइल चुनें",
      "File should be JPG or PNG format" : "फ़ाइल JPG या PNG प्रारूप में होनी चाहिए",
      "Save" : "जमा करे",
      "Event Reported Successfully" : "कार्यक्रम की जानकारी सफलतापूर्वक प्राप्त कर ली गयी है",
      "User Updated Successfully" : "उपयोगकर्ता की जानकारी सफलतापूर्वक अपडेट कर दी गई है",
      "Enter Details" : "जानकारी भरे",
      "Total Attendees" : "कुल उपस्थित श्रोता",
      "TOTAL ATTENDEES" : "कुल उपस्थित श्रोता",
      "Remarks" : "टिप्पणी",
      "Upload Image" : "फ़ोटो अपलोड करे",
      "Capture Image" : "फ़ोटो क्लिक करे",
      "Capture" : "खींचे",
      "Switch" : "बदलें",
      "Close" : "बंद करे",
      "Review" : "समीक्षा",
      "Please fill all * mandatory fields" : "कृपया सभी * अनिवार्य फ़ील्ड भरें",
      "Upload minimum one image" : "कम से कम एक फ़ोटो अपलोड करें",
      "Total Attendees required" : "कुल उपस्थित श्रोता की जानकारी अनिवार्य है",
      "Enter Your Full Name" : "अपना पूरा नाम लिखे",
      "Select State" : "राज्य चुनें",
      "Next" : "अगला",
      "Program Reported Successfully" : "धन्यवाद, कार्यक्रम की जानकारी सफलतापूर्वक प्राप्त कर ली गयी है।",
      "Yay! Keep doing the good work!" : "ऐसे ही सहयोग देते रहे।",
      "Address" : "पता",
      "Images" : "फ़ोटो",
      "Submit" : "सबमिट करे।",
      "Upcoming Event" : "आगामी कार्यक्रम",
      "Booth in Shakti Kendra" : "शक्ति केंद्र के अंतर्गत बूथ",
      "Shakti kendra required" : "शक्ति केंद्र का चयन अनिवार्य",
      "Other Booth in AC" : "इस विधान सभा के अन्य बूथ",
      "Address (where event is organised)" : "पता (जहाँ कार्यक्रम आयोजित हुआ)",
      "Phone number not valid and should be 10 digit" : "फ़ोन नंबर मान्य नहीं है और 10 अंकों का होना चाहिए",
      "Invalid Phone Number" : "अवैध फोन नंबर",
      "Logout Successfully" : "सफलतापूर्वक लॉगआउट",
      "Didn’t recieve OTP" : "ओटीपी प्राप्त नहीं हुआ",
      "Resend" : "पुन: भेजें",
      "Program" : "कार्यक्रम",
      "Report Again" : "फिर से रिपोर्ट करें",
      "File size exceeds limit! Please select a file upto 10 MB" : "फ़ाइल का साइज अधिक है! कृपया 10 MB तक की फ़ाइल का चयन करें",
      "View Less" : "कम देखें",
      "View More" : "और देखें",
      "Reports" : "रिपोर्ट",
      "Overview" : "संक्षिप्त",
      "Select Event" : "कार्यक्रम का चयन करें",
      "Events Reported" : "रिपोर्ट किये गए कार्यक्रम",
      "Vidhan Sabha Covered" : "विधानसभा कवर्ड",
      "VIDHAN SABHA COVERED" : "विधानसभा कवर्ड",
      "Form Filled Booths" : "बूथ जहाँ फॉर्म भरे गए",
      "FORM FILLED BOOTHS" : "बूथ जहाँ फॉर्म भरे गए",
      "Events Reported Booths" : "बूथ जहाँ कार्यक्रम रिपोर्ट किया",
      "Reported Booths" : "रिपोर्ट करे गए बूथ",
      "Total Booths" : "कुल बूथ",
      "Zila" : "ज़िला",
      "Zila Wise Reports" : "ज़िला वार रिपोर्ट",
      "Vidhan Sabha Wise Reports" : "विधानसभा वार रिपोर्ट",
      "Booth Wise Reports" : "बूथ वार रिपोर्ट",
      "Reportee Name & No" : "रिपोर्ट करने वाले का नाम और नंबर",
      "Remark" : "टिप्पणी",
      "No Reports Available" : "कोई रिपोर्ट उपलब्ध नहीं है",
      "Sort By" : "क्रमबद्ध करें",
      "High to Low" : "अधिक से कम",
      "Low to High" : "कम से अधिक",
      "Please select an event" : "कृपया एक कार्यक्रम का चयन करें",
      "Total Event Reports" : "कुल कार्यक्रम रिपोर्ट",
      "OVERALL" : "कुल"
    }
  },
  gj: {
    translation: {
      "Enter OTP sent to ": "મોકલેલ OTP દાખલ કરો",
      " Enter OTP sent to": "મોકલેલ OTP દાખલ કરો",
      "Shakti kendra in AC": "વિધાનસભા માં શક્તિ કેન્દ્ર ",
      "Image": "છબીઓ",
      "OR": "અથવા",
      "Please wait": "મહેરબાની કરી રાહ જુવો",
      "Start reporting on : ":"આના પર જાણ કરવાનું શરૂ કરો : ",
      "You can only post after the program starts. ": "તમે કાર્યક્રમ શરૂ થયા પછી જ પોસ્ટ કરી શકો છો. ",
      "No Programs": "કોઈ કાર્યક્રમો નથી",
      "Please enter a valid Name":"કૃપા કરીને માન્ય નામ દાખલ કરો",
      "Event Address": "કાર્યક્રમ સરનામું",
      "Image (Max 2)": "છબીઓ (મહત્તમ 2)",
      "Geolocation not supported": "ભૌગોલિક સ્થાન સપોર્ટેડ નથી",
      "Geolocation not found": "ભૌગોલિક સ્થાન મળ્યું નથી",
      "Permission denied": "મંજૂરી નથી",
      "(Go to setting >> Site settings >>":" (સેટિંગ્સ પર જાઓ >> સાઇટ સેટિંગ્સ >>",
      "Locations >> mannkibaatprogram >> Allow access to location)": "લોકેશન >> માન કી બાત >> લોકેશન ઍક્સેસ કરવાની મંજૂરી આપો)",
      "(Go to setting >> Privacy and security >> Site settings >>": "(સેટિંગ્સ પર જાઓ >> પ્રાઇવસી અને સેક્યુરીટી >> સાઇટ સેટિંગ્સ >>",
      "Shakti Kendra": "શક્તિ કેન્દ્ર",
      "Bharatiya Janata Party" : "ભારતીય જનતા પાર્ટી",
      "Reported Program" : "અહેવાલ કાર્યક્રમ",
      "Profile" : "પ્રોફાઇલ",
      "Login" : "પ્રવેશ કરો",
      "Logout" : "લૉગ આઉટ",
      "Mobile Number" : "મોબાઇલ નંબર",
      "Send OTP" : "ઓટીપી મોકલો",
      "Verify Details" : "વિગતો ચકાસો",
      "Enter OTP sent to" : "OTP મોકલવામાં આવ્યો છે",
      "seconds left in Resend OTP" : "OTP ફરીથી મોકલવામાં સેકન્ડ બાકી છે",
      "Otp Sent successfully" : "Otp સફળતાપૂર્વક મોકલ્યો",
      "Login successfully" : "સફળતાપૂર્વક લૉગિન",
      "Invalid OTP" : "અમાન્ય OTP",
      "Phone Required" : "ફોન જરૂરી છે",
      "Programs" : "કાર્યક્રમો",
      "Aired on" : "પર પ્રસારિત",
      "Report" : "જાણ કરો",
      "Event not reported" : "ઈવેન્ટની જાણ કરી નથી",
      "Click here to know more" : "વધુ જાણવા માટે અહીં ક્લિક કરો",
      "Edit Profile" : "પ્રોફાઇલ સંપાદિત કરો",
      "Phone Number" : "ફોન નંબર",
      "Name" : "નામ",
      "Name required" : "નામ જરૂરી છે",
      "Select profile photo" : "પ્રોફાઇલ ફોટો પસંદ કરો",
      "State" : "રાજ્ય",
      "State required" : "રાજ્ય જરૂરી છે",
      "Vidhan Sabha required" : "વિધાનસભા જરૂરી છે",
      "Vidhan Sabha" : "વિધાનસભા",
      "Party Zila" : "પાર્ટી જિલ્લા",
      "Party Zila required" : "પાર્ટી જિલ્લા જરૂરી છે",
      "Party Mandal required" : "પાર્ટી મંડળ જરૂરી છે",
      "Phone required" : "ફોન જરૂરી છે",
      "Booth required" : "બૂથ જરૂરી",
      "Mandal" : "મંડળ",
      "Booth" : "બૂથ",
      "WhatsApp Number" : "વોટ્સએપ નંબર",
      "Education" : "શિક્ષણ",
      "Profession" : "વ્યવસાય",
      "Voter ID" : "મતદાર આઈડી",
      "Pincode" : "પીન કોડ",
      "File can't be upload please select file upto 1 MB" : "ફાઇલ અપલોડ કરી શકાતી નથી, કૃપા કરીને 1 MB સુધીની ફાઇલ પસંદ કરો",
      "File should be JPG or PNG format" : "ફાઇલ JPG અથવા PNG ફોર્મેટ હોવી જોઈએ",
      "Save" : "સાચવો",
      "Event Reported Successfully" : "કાર્યક્રમની સફળતાપૂર્વક જાણ કરવામાં આવી",
      "User Updated Successfully" : "વપરાશકર્તા સફળતાપૂર્વક અપડેટ થયું",
      "Enter Details" : "વિગતો દાખલ કરો",
      "Total Attendees" : "કુલ હાજરી",
      "TOTAL ATTENDEES" : "કુલ હાજરી",
      "Remarks" : "રિમાર્કસ",
      "Upload Image" : "છબી અપલોડ કરો",
      "Capture Image" : "છબી કેપ્ચર કરો",
      "Capture" : "કેપ્ચર",
      "Switch" : "સ્વિચ",
      "Close" : "બંધ",
      "Review" : "સમીક્ષા",
      "Please fill all * mandatory fields" : "કૃપા કરીને તમામ * ફરજિયાત ફીલ્ડ્સ ભરો",
      "Upload minimum one image" : "ઓછામાં ઓછી એક છબી અપલોડ કરો",
      "Total Attendees required" : "કુલ હાજરી જરૂરી છે",
      "Enter Your Full Name" : "તમારું પૂરું નામ લખો",
      "Select State" : "રાજ્ય પસંદ કરો",
      "Next" : "આગળ",
      "Program Reported Successfully" : "પ્રોગ્રામની સફળતાપૂર્વક જાણ કરવામાં આવી",
      "Yay! Keep doing the good work!" : "હા! સારું કામ કરતા રહો!",
      "Address" : "સરનામું",
      "Images" : "છબીઓ",
      "Submit" : "સબમિટ કરો",
      "Upcoming Event" : "આગામી કાર્યક્રમ",
      "Booth in Shakti Kendra" : "શક્તિ કેન્દ્રમાં બૂથ",
      "Shakti kendra required" : "શક્તિ કેન્દ્ર જરૂરી",
      "Other Booth in AC" : "વિધાનસભા માં અન્ય બૂથ",
      "Address (where event is organised)" : "સરનામું (જ્યાં કાર્યક્રમનું આયોજન કરવામાં આવ્યું છે)",
      "Phone number not valid and should be 10 digit" : "ફોન નંબર માન્ય નથી અને ૧૦ આંકડાનો હોવો જોઈએ",
      "Invalid Phone Number" : "અમાન્ય ફોન નંબર",
      "Logout Successfully" : "સફળતાપૂર્વક લૉગઆઉટ થઈ ગયું",
      "Didn’t recieve OTP" : "OTP પ્રાપ્ત થયો નથી",
      "Resend" : "ફરીથી મોકલો",
      "Program" : "કાર્યક્રમો",
      "Report Again" : "ફરી રિપોર્ટ કરો",
      "File size exceeds limit! Please select a file upto 10 MB" : "ફાઇલનું કદ ઓળંગ્યું! કૃપા કરીને 10 MB સુધીની ફાઇલ પસંદ કરો",
      "View Less" : "ઓછું જુઓ",
      "View More" : "વધુ જોવો",
      "Reports" : "રિપોર્ટ ",
      "Overview" : "ઝાંખી",
      "Select Event" : "કાર્યક્રમ પસંદ કરો",
      "Events Reported" : "કાર્યક્રમ ની જાણકારી",
      "Vidhan Sabha Covered" : "વિધાનસભા આવરી લેવામાં આવી છે",
      "VIDHAN SABHA COVERED" : "વિધાનસભા આવરી લેવામાં આવી છે",
      "Form Filled Booths" : "બૂથ જ્યાં ફોર્મ ભરવામાં આવ્યા હતા",
      "FORM FILLED BOOTHS" : "બૂથ જ્યાં ફોર્મ ભરવામાં આવ્યા હતા",
      "Events Reported Booths" : "બૂથ જ્યાં કાર્યક્રમ અહેવાલ રજૂ થયા ",
      "Reported Booths" : "રિપોર્ટ કરેલા બૂથ ",
      "Total Booths" : "કુલ બૂથ",
      "Zila" : "જિલ્લો ",
      "Zila Wise Reports" : "જિલ્લા પ્રમાણે રિપોર્ટ ",
      "Vidhan Sabha Wise Reports" : "વિધાન સભા પ્રમાણે રિપોર્ટ ",
      "Booth Wise Reports" : "બૂથ પ્રમાણે રિપોર્ટ ",
      "Reportee Name & No" : "રિપોર્ટ કરવા વાળ નું નામ અને નંબર ",
      "Remark" : "ટિપ્પણી ",
      "No Reports Available" : "કોઈ અહેવાલો ઉપલબ્ધ નથી",
      "Sort By" : "સૉર્ટ કરો",
      "High to Low" : "વધારે થી ઓછું ",
      "Low to High" : "ઓછા થી વધારે ",
      "Please select an event" : "કૃપા કરીને એક ઇવેન્ટ પસંદ કરો",
      "Total Event Reports" : "કુલ કાર્યક્રમ રિપોર્ટ ",
      "OVERALL" : "કુલ "
    }
  },
  pb: {
    translation: {
      "Bharatiya Janata Party" : "ਭਾਰਤੀ ਜਨਤਾ ਪਾਰਟੀ",
      "Reported Program" : "ਪ੍ਰੋਗਰਾਮ ਦੀ ਜਾਣਕਾਰੀ",
      "Profile" : "ਪ੍ਰੋਫਾਈਲ",
      "Login" : "ਲਾਗਇਨ ਕਰੋ",
      "Logout" : "ਲਾੱਗ ਆਊਟ",
      "Mobile Number" : "ਮੋਬਾਇਲ ਨੰਬਰ",
      "Send OTP" : "OTP ਭੇਜੋ",
      "Verify Details" : "ਵੇਰਵਿਆਂ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
      "Enter OTP sent to" : "OTP ਭੇਜਿਆ ਗਿਆ",
      "seconds left in Resend OTP" : "OTP ਦੁਬਾਰਾ ਭੇਜਣ ਲਈ ਸਕਿੰਟ",
      "Otp Sent successfully" : "OTP ਸਫਲਤਾਪੂਰਵਕ ਭੇਜਿਆ",
      "Login successfully" : "ਸਫਲਤਾਪੂਰਵਕ ਲੌਗਇਨ ਕਰੋ",
      "Invalid OTP" : "ਅਵੈਧ otp",
      "Phone Required" : "ਫ਼ੋਨ ਜ਼ਰੂਰੀ ਹੈ",
      "Programs" : "ਪ੍ਰੋਗਰਾਮ",
      "Aired on" : "ਤੇ ਪ੍ਰਸਾਰਿਤ ਕੀਤਾ ਗਿਆ",
      "Report" : "ਰਿਪੋਰਟ",
      "Event not reported" : "ਕੋਈ ਘਟਨਾ ਦੀ ਰਿਪੋਰਟ ਨਹੀਂ",
      "Click here to know more" : "ਹੋਰ ਜਾਣਕਾਰੀ ਲਈ ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ",
      "Edit Profile" : "ਪ੍ਰੋਫਾਈਲ ਐਡਿਟ ਕਰੋ",
      "Phone Number" : "ਫੋਨ ਨੰਬਰ",
      "Name" : "ਨਾਮ",
      "Name required" : "ਨਾਮ ਜਰੂਰੀ ਹੈ  ",
      "Select profile photo" : "ਪ੍ਰੋਫਾਈਲ ਫੋਟੋ ਦੀ ਚੋਣ ਕਰੋ",
      "State" : "ਰਾਜ",
      "State required" : "ਰਾਜ ਜ਼ਰੂਰੀ ਹੈ",
      "Vidhan Sabha required" : "ਵਿਧਾਨ ਸਭਾ ਜਰੂਰੀ ਹੈ",
      "Vidhan Sabha" : "ਵਿਧਾਨ ਸਭਾ",
      "Party Zila" : "ਪਾਰਟੀ ਜ਼ਿਲ੍ਹਾ",
      "Party Zila required" : "ਪਾਰਟੀ ਜ਼ਿਲ੍ਹਾ ਜਰੂਰੀ ਹੈ",
      "Party Mandal required" : "ਪਾਰਟੀ ਮੰਡਲ ਜਰੂਰੀ ਹੈ ",
      "Phone required" : "ਫ਼ੋਨ ਨੰਬਰ ਵੀ ਜਰੂਰੀ ਹੈ",
      "Booth required" : "ਬੂਥ ਦੀ ਜਰੂਰੀ ਹੈ",
      "Mandal" : "ਮੰਡਲ ",
      "Booth" : "ਬੂਥ",
      "WhatsApp Number" : "ਵਟਸਐਪ ਨੰਬਰ",
      "Education" : "ਸ਼ਿਕ੍ਸ਼ਾ ",
      "Profession" : "ਵਵਸਾਏ",
      "Voter ID" : "ਵੋਟਰ ਆਈ ,ਡੀ ",
      "Pincode" : "ਪਿੰਨ ਕੋਡ",
      "File can't be upload please select file upto 1 MB" : "ਫ਼ਾਈਲ ਨੂੰ ਅੱਪਲੋਡ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ 1MB ਤੱਕ ਦੀ ਇੱਕ ਫ਼ਾਈਲ ਚੁਣੋ",
      "File should be JPG or PNG format" : "ਫਾਇਲ jpg ਜਾਂ png ਫਾਰਮੈਟ ਵਿੱਚ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ",
      "Save" : "ਜਮ੍ਹਾ",
      "Event Reported Successfully" : "ਪ੍ਰੋਗਰਾਮ ਜਾਣਕਾਰੀ ਸਫਲਤਾਪੂਰਵਕ ਪ੍ਰਾਪਤ ਕੀਤੀ ਗਈ ਹੈ।",
      "User Updated Successfully" : "ਉਪਭੋਗਤਾ ਜਾਣਕਾਰੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ ਹੈ",
      "Enter Details" : "ਜਾਣਕਾਰੀ ਭਰੋ",
      "Total Attendees" : "ਕੁਲ ਉਪਸਥਿਤ ਲੋਗ ",
      "TOTAL ATTENDEES" : "ਕੁਲ ਉਪਸਥਿਤ ਲੋਗ ",
      "Remarks" : "ਟਿੱਪਣੀ",
      "Upload Image" : "ਫੋਟੋ ਅੱਪਲੋਡ ਕਰੋ",
      "Capture Image" : "ਫੋਟੋ ਕਲਿੱਕ ਕਰੋ.",
      "Capture" : "ਖਿੱਚੋ",
      "Switch" : "ਬਦਲੋ",
      "Close" : "ਬੰਦ ਕਰੋ",
      "Review" : "ਸਮੀਕਸ਼ਾ ",
      "Please fill all * mandatory fields" : "ਕਿਰਪਾ ਕਰਕੇ ਸਾਰੇ * ਜ਼ਰੂਰੀ  ਭਰੋ",
      "Upload minimum one image" : "ਘੱਟੋ-ਘੱਟ ਇੱਕ ਫੋਟੋ ਅੱਪਲੋਡ ਕਰੋ",
      "Total Attendees required" : "ਕੁਲ ਉਪਸਥਿਤ ਲੋਗੋ ਕੀ ਜਾਨਕਾਰੀ ਜ਼ਰੂਰੀ ਹੈ ",
      "Enter Your Full Name" : "ਆਪਣਾ ਪੂਰਾ ਨਾਮ ਲਿਖੋ",
      "Select State" : "ਰਾਜ ਚੁਣੋ",
      "Next" : "ਅਗਲਾ",
      "Program Reported Successfully" : "ਧੰਨਵਾਦ, ਪ੍ਰੋਗਰਾਮ ਦੀ ਜਾਣਕਾਰੀ ਸਫਲਤਾਪੂਰਵਕ ਪ੍ਰਾਪਤ ਕੀਤੀ ਗਈ ਹੈ।",
      "Yay! Keep doing the good work!" : "ਇਸੇ ਤਰਾਂ ਹੀ ਸਹਿਯੋਗ ਦਿੰਦੇ ਰਹੋ।",
      "Address" : "ਪਤਾ ",
      "Images" : "ਫੋਟੋ ",
      "Submit" : "ਜਮ੍ਹਾਂ ਕਰੇ",
      "Upcoming Event" : "ਆਉਣ - ਵਾਲੇ ਪ੍ਰੋਗਰਾਮ",
      "Booth in Shakti Kendra" : "ਸ਼ਕਤੀ ਕੇਂਦਰ ਦੇ ਅਧੀਨ ਬੂਥ",
      "Shakti kendra in AC" : "ਵਿਧਾਨ ਸਭਾ ਕੇ ਅਧਿਨ ਸ਼ਕਤੀ ਕੇਂਦਰ",
      "Shakti kendra required" : "ਸ਼ਕਤੀ ਕੇਂਦਰ ਦੀ ਚੋਣ ਜਰੂਰੀ  ਹੈ",
      "Other Booth in AC" : "ਇਸ ਵਿਧਾਨ ਸਭਾ ਦੇ ਹੋਰ ਬੂਥ",
      "Address (where event is organised)" : "ਪਤਾ (ਜਿੱਥੇ ਪ੍ਰੋਗਰਾਮ ਹੋਇਆ ਸੀ)",
      "Phone number not valid and should be 10 digit" : "ਫ਼ੋਨ ਨੰਬਰ ਵੈਧ ਨਹੀਂ ਹੈ ਅਤੇ 10 ਅੰਕਾਂ ਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
      "Invalid Phone Number" : "ਅਵੈਧ ਫ਼ੋਨ ਨੰਬਰ",
      "Logout Successfully" : "ਸਫਲਤਾਪੂਰਵਕ ਲਾਗਆਉਟ",
      "Didn’t recieve OTP" : "OTP ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਯਾ",
      "Resend" : "ਦੁਬਾਰਾ ਭੇਜੋ",
      "Program" : "ਪ੍ਰੋਗਰਾਮ",
      "Update" : "ਅੱਪਡੇਟ ਕਰੋ",
      "Start reporting on : " : "ਇਸ 'ਤੇ ਰਿਪੋਰਟ ਕਰਨਾ ਸ਼ੁਰੂ ਕਰੋ:",
      "You can only post after the program starts. " : "ਤੁਸੀਂ ਪ੍ਰੋਗਰਾਮ ਸ਼ੁਰੂ ਹੋਣ ਤੋਂ ਬਾਅਦ ਹੀ ਪੋਸਟ ਕਰ ਸਕਦੇ ਹੋ।",
      "No Programs" : "ਕੋਈ ਪ੍ਰੋਗਰਾਮ ਨਹੀਂ",
      "Please enter a valid Name" : "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਨਾਮ ਦਾਖਲ ਕਰੋ",
      "Event Address" : "ਪ੍ਰੋਗਰਾਮ ਦਾ ਪਤਾ",
      "Image (Max 2)" : "ਫੋਟੋਆਂ (ਅਧਿਕਤਮ 2)",
      "Geolocation not supported" : "ਭੂਗੋਲਿਕ ਸਥਾਨ ਨਹੀਂ ਮਿਲਿਆ",
      "Geolocation not found" : "ਭੂਗੋਲਿਕ ਨਹੀਂ ਲਭਿਆ",
      "Permission denied" : "ਇਜਾਜ਼ਤ ਨਹੀਂ ਹੈ",
      "(Go to setting >> Site settings >>" : "(ਸੈਟਿੰਗ 'ਤੇ ਜਾਓ>>ਸਾਈਟ ਸੈਟਿੰਗ>>",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "ਸਥਾਨ>> ਮਨ ਕੀ ਬਾਤ >> ਸਥਾਨ ਪਹੁੰਚ ਦੀ ਇਜਾਜ਼ਤ)",
      "(Go to setting >> Privacy and security >> Site settings >>" : "(ਸੈਟਿੰਗ 'ਤੇ ਜਾਓ>>ਗੋਪਨੀਯਤਾ ਅਤੇ ਸੁਰੱਖਿਆ>>ਸਾਈਟ ਸੈਟਿੰਗਾਂ>>",
      "Shakti Kendra" : "ਸ਼ਕਤੀ ਕੇਂਦਰ",
      "OR" : "ਜਾਂ",
      "Report Again" : "ਦੁਬਾਰਾ ਰਿਪੋਰਟ ਕਰੋ",
      "File size exceeds limit! Please select a file upto 10 MB" : "ਫ਼ਾਈਲ ਦਾ ਆਕਾਰ ਵੱਧ ਗਿਆ ਹੈ! ਕਿਰਪਾ ਕਰਕੇ 10MB ਤੱਕ ਦੀ ਫ਼ਾਈਲ ਚੁਣੋ",
      "View Less" : "ਘੱਟ ਦੇਖੋ",
      "View More" : "ਹੋਰ ਵੇਖੋ",
      "Reports" : "ਰਿਪੋਰਟ",
      "Overview" : "ਸੰਖੇਪ ਜਾਣਕਾਰੀ",
      "Select Event" : "ਇਵੈਂਟ ਚੁਣੋ",
      "Events Reported" : "ਘਟਨਾਵਾਂ ਦੀ ਰਿਪੋਰਟ ਕੀਤੀ ਗਈ",
      "Vidhan Sabha Covered" : "ਵਿਧਾਨ ਸਭਾ ਨੂੰ ਕਵਰ ਕੀਤਾ",
      "VIDHAN SABHA COVERED" : "ਵਿਧਾਨ ਸਭਾ ਨੂੰ ਕਵਰ ਕੀਤਾ",
      "Form Filled Booths" : "ਫਾਰਮ ਭਰੇ ਬੂਥ",
      "FORM FILLED BOOTHS" : "ਫਾਰਮ ਭਰੇ ਬੂਥ",
      "Events Reported Booths" : "ਇਵੈਂਟਸ ਰਿਪੋਰਟ ਕੀਤੇ ਬੂਥ",
      "Reported Booths" : "ਬੂਥਾਂ ਦੀ ਰਿਪੋਰਟ ਕੀਤੀ",
      "Total Booths" : "ਕੁੱਲ ਬੂਥ",
      "Zila" : "ਜਿਲਾ",
      "Zila Wise Reports" : "ਜਿਲਾ ਵਾਈਜ਼ ਰਿਪੋਰਟ",
      "Vidhan Sabha Wise Reports" : "ਵਿਧਾਨ ਸਭਾ ਦੀਆਂ ਰਿਪੋਰਟਾਂ",
      "Booth Wise Reports" : "ਬੂਥ ਵਾਈਜ਼ ਰਿਪੋਰਟਾਂ",
      "Reportee Name & No" : "ਰਿਪੋਰਟਰ ਦਾ ਨਾਮ ਅਤੇ ਨੰ",
      "Remark" : "ਟਿੱਪਣੀ",
      "No Reports Available" : "ਕੋਈ ਰਿਪੋਰਟਾਂ ਉਪਲਬਧ ਨਹੀਂ ਹਨ",
      "Sort By" : "ਕ੍ਰਮਬੱਧ ਕਰੋ",
      "High to Low" : "ਉੱਚ ਤੋਂ ਨੀਵਾਂ",
      "Low to High" : "ਘੱਟ ਤੋਂ ਉੱਚਾ",
      "Please select an event" : "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਕਰਿਆਕਾਰਮ  ਚੁਣੋ",
      "Total Event Reports" : "ਕੁੱਲ ਇਵੈਂਟ ਰਿਪੋਰਟਾਂ",
      "OVERALL" : "ਕੁੱਲ ਮਿਲਾ ਕੇ"
    }
  },
  mh: {
    translation: {
      "Bharatiya Janata Party" : "भारतीय जनता पार्टी ",
      "Reported Program" : "अहवाल कार्यक्रम ",
      "Profile" : "प्रोफाइल ",
      "Login" : "लॉगिन ",
      "Logout" : "लॉगआउट ",
      "Mobile Number" : "मोबाइल नंबर ",
      "Send OTP" : "OTP पाठवा ",
      "Verify Details" : "माहिती सत्यापित करा",
      "Enter OTP sent to" : "पाठवलेला OTP एंटर करा",
      "seconds left in Resend OTP" : "OTP पुन्हा पाठवा काही सेकंद बाकी",
      "Otp Sent successfully" : "OTP यशस्वीरीत्या पाठवला",
      "Login successfully" : "लॉगिन यशस्वी",
      "Invalid OTP" : "अवैध OTP",
      "Phone Required" : "फोन आवश्यक",
      "Programs" : "कार्यक्रम",
      "Aired on" : "वर प्रसारित झाला",
      "Report" : "अहवाल",
      "Event not reported" : "इवेंट नोंदवला नाही",
      "Click here to know more" : "अधिक जाणून घेण्यासाठी येथे क्लिक करा",
      "Edit Profile" : "प्रोफाईल संपादित करा",
      "Phone Number" : "फोन नंबर ",
      "Name" : "नाव ",
      "Name required" : "नाव अनिवार्य आहे ",
      "Select profile photo" : "प्रोफाइल फोटो निवडा",
      "State" : "राज्य",
      "State required" : "राज्य अनिवार्य आहे ",
      "Vidhan Sabha required" : "विधानसभा अनिवार्य आहे ",
      "Vidhan Sabha" : "विधानसभा ",
      "Party Zila" : "पार्टी जिल्हा ",
      "Party Zila required" : "पार्टी जिल्हा अनिवार्य आहे ",
      "Party Mandal required" : "पार्टी मंडल अनिवार्य आहे ",
      "Phone required" : "फोन नंबर अनिवार्य आहे ",
      "Booth required" : "बूथ अनिवार्य आहे ",
      "Mandal" : "मंडल ",
      "Booth" : "बूथ",
      "WhatsApp Number" : "व्हाट्सएप नंबर",
      "Education" : "शिक्षण ",
      "Profession" : "व्यवसाय ",
      "Voter ID" : "मतदार ओळखपत्र",
      "Pincode" : "पिनकोड ",
      "File can't be upload please select file upto 1 MB" : "फाइल अपलोड केली जाऊ शकत नाही कृपया 1 MB पर्यंतची फाइल निवडा",
      "File should be JPG or PNG format" : "फाइल JPG किंवा PNG फॉरमॅट असावी",
      "Save" : "जतन करा",
      "Event Reported Successfully" : "इव्हेंटचा यशस्वीपणे अहवाल दिला",
      "User Updated Successfully" : "वापरकर्ता यशस्वीरित्या अद्यतनित",
      "Enter Details" : "तपशील प्रविष्ट करा",
      "Total Attendees" : "एकूण उपस्थित",
      "TOTAL ATTENDEES" : "एकूण उपस्थित",
      "Remarks" : "टिप्पणी",
      "Upload Image" : "फोटो अपलोड करा",
      "Capture Image" : "फोटो  कॅप्चर करा",
      "Capture" : "कॅप्चर करा",
      "Switch" : "स्विच करा",
      "Close" : "बंद करा ",
      "Review" : "पुनरावलोकन करा",
      "Please fill all * mandatory fields" : "कृपया सर्व * अनिवार्य फील्ड भरा",
      "Upload minimum one image" : "किमान एक फोटो अपलोड करा",
      "Total Attendees required" : "एकूण उपस्थित आवश्यक",
      "Enter Your Full Name" : "तुमचे पूर्ण नाव एंटर करा",
      "Select State" : "राज्य निवडा",
      "Next" : "पुढे",
      "Program Reported Successfully" : "कार्यक्रम यशस्वीरित्या नोंदवला",
      "Yay! Keep doing the good work!" : "असाच प्रतिसाद देत रहा. ",
      "Address" : "पत्ता",
      "Images" : "फोटो ",
      "Submit" : "सादर करा ",
      "Upcoming Event" : "आगामी कार्यक्रम ",
      "Booth in Shakti Kendra" : "शक्ति केंद्रामधील बूथ ",
      "Shakti kendra in AC" : "विधानसभा च्या अंतर्गत शक्तिकेंद्र",
      "Shakti kendra required" : "शक्ती केंद्र आवश्यक",
      "Other Booth in AC" : "विधानसभा मध्ये इतर बूथ",
      "Address (where event is organised)" : "पत्ता (जिथे कार्यक्रम आयोजित केला आहे)",
      "Phone number not valid and should be 10 digit" : "फोन नंबर वैध नाही. फोन नंबर 10 अंकी असावा ",
      "Invalid Phone Number" : "अवैध फोन नंबर ",
      "Logout Successfully" : "यशस्वीरित्या लॉगआउट ",
      "Didn’t recieve OTP" : "OTP प्राप्त झाला नाही",
      "Resend" : "पुनः पाठवा ",
      "Program" : "कार्यक्रम ",
      "Update" : "अपडेट करा",
      "Start reporting on : " : "यावर अहवाल देणे सुरू करा:",
      "You can only post after the program starts. " : "कार्यक्रम सुरू झाल्यानंतरच तुम्ही पोस्ट करू शकता.",
      "No Programs" : "कोणतेही कार्यक्रम नाहीत",
      "Please enter a valid Name" : "कृपया एक वैध नाव प्रविष्ट करा",
      "Event Address" : "कार्यक्रमाचा पत्ता",
      "Image (Max 2)" : "फोटो (कमाल २)",
      "Geolocation not supported" : "जियोलोकेशन सपोर्ट करीत नाही ",
      "Geolocation not found" : "जियोलोकेशन प्राप्त झाले नाही ",
      "Permission denied" : "अनुमति नाही ",
      "(Go to setting >> Site settings >>" : "(सेटिंग  वर जा >> साइट सेटिंग्ज >>",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "लोकेशन >> मन की बात >> लोकेशन एक्सेस ची अनुमति )",
      "(Go to setting >> Privacy and security >> Site settings >>" : "(सेटिंग वर जा >> गोपनीयता आणि सुरक्षा >> साइट सेटिंग्ज >> ",
      "Shakti Kendra" : "शक्ति केंद्र ",
      "OR" : "किंवा",
      "Report Again" : "पुन्हा अहवाल द्या ",
      "File size exceeds limit! Please select a file upto 10 MB" : "फाईलचा आकार जास्त आहे! कृपया 10MB पर्यंतची फाइल निवडा",
      "View Less" : "कमी पहा",
      "View More" : "अधिक पहा",
      "Reports" : "रिपोर्ट",
      "Overview" : "संक्षिप्त",
      "Select Event" : "इव्हेंट निवडा",
      "Events Reported" : "घटनांचा अहवाल दिला",
      "Vidhan Sabha Covered" : "विधानसभा झाकलेले",
      "VIDHAN SABHA COVERED" : "विधानसभा झाकलेले",
      "Form Filled Booths" : "फॉर्म भरलेले बूथ",
      "FORM FILLED BOOTHS" : "फॉर्म भरलेले बूथ",
      "Events Reported Booths" : "कार्यक्रम नोंदवलेले बूथ",
      "Reported Booths" : "अहवाल दिलेले बूथ",
      "Total Booths" : "एकूण बूथ",
      "Zila" : "जिल्हा",
      "Zila Wise Reports" : "जिल्हा वर आधारित अहवाल",
      "Vidhan Sabha Wise Reports" : "विधानसभा वर आधारित रिपोर्ट",
      "Booth Wise Reports" : "बूथ वर आधारित रिपोर्ट",
      "Reportee Name & No" : "तक्रारदाराचे नाव आणि क्र",
      "Remark" : "पाहणे (नोंद घेणे)",
      "No Reports Available" : "कोणताही अहवाल उपलब्ध नाही",
      "Sort By" : "क्रमवारी लावा",
      "High to Low" : "उच्च ते निम्न",
      "Low to High" : "कमी ते उच्च",
      "Please select an event" : "कृपया एक कार्यक्रम निवडा",
      "Total Event Reports" : "एकूण इव्हेंट रिपोर्ट",
      "OVERALL" : "एकूण"
    }
  },
  bg: {
    translation: {
      "Bharatiya Janata Party" : "ভারতীয় জনতা পার্টি ",
      "Reported Program" : "রিপোর্টেড অনুষ্ঠান ",
      "Profile" : "প্রোফাইল ",
      "Login" : "লগইন ",
      "Logout" : "লগ আউট ",
      "Mobile Number" : "মোবাইল নম্বর ",
      "Send OTP" : "সেন্ড  ওটিপি ",
      "Verify Details" : "তথ্য যাচাইকরণ ",
      "Enter OTP sent to" : "এন্টার ওটিপি সেন্ড টু ",
      "seconds left in Resend OTP" : "সেকেন্ড বাকি পুনরায় ওটিপি পাঠানো ",
      "Otp Sent successfully" : "ওটিপি পাঠানো সফল ",
      "Login successfully" : "সফলভাবে লগইন ",
      "Invalid OTP" : "ওটিপি সঠিক নয় ",
      "Phone Required" : "ফোন দরকার ",
      "Programs" : "প্রোগ্রামস",
      "Aired on" : "সম্প্রচারিত হয়েছে ",
      "Report" : "রিপোর্ট ",
      "Event not reported" : "প্রোগ্রাম এখনো রিপোর্ট করা হয়নি ",
      "Click here to know more" : "বিশদে জানতে এখানে ক্লিক করুন ",
      "Edit Profile" : "এডিট প্রোফাইল ",
      "Phone Number" : "ফোন নম্বর",
      "Name" : "নাম",
      "Name required" : "নাম দরকার",
      "Select profile photo" : "প্রোফাইল ফটো নির্বাচন",
      "State" : "রাজ্য",
      "State required" : "রাজ্য দরকার",
      "Vidhan Sabha required" : "বিধানসভা দরকার",
      "Vidhan Sabha" : "বিধানসভা",
      "Party Zila" : "পার্টি  জেলা",
      "Party Zila required" : "পার্টি  জেলা  দরকার",
      "Party Mandal required" : "পার্টি  মণ্ডল  দরকার",
      "Phone required" : "ফোন  দরকার",
      "Booth required" : "বুথ  দরকার",
      "Mandal" : "মণ্ডল",
      "Booth" : "বুথ",
      "WhatsApp Number" : "হোয়াটস্যাপ  নম্বর",
      "Education" : "শিক্ষা",
      "Profession" : "পেশা",
      "Voter ID" : "ভোটার  আইডি",
      "Pincode" : "পিনকোড",
      "File can't be upload please select file upto 1 MB" : "এই ফাইলটি আপলোড করা যাচ্ছে না  , দয়া করে ১ mb এর মধ্যে ফাইল আপলোড করুন ",
      "File should be JPG or PNG format" : "ফাইলটি অবশ্যই JPG অথবা PNG ফরম্যাটের হতে হবে ",
      "Save" : "সেভ ",
      "Event Reported Successfully" : "অনুষ্ঠান সফলভাবে রিপোর্ট করা হয়েছে ",
      "User Updated Successfully" : "ইউজার সফলভাবে আপডেটেড করা হয়েছে ",
      "Enter Details" : "এন্টার  ডিটেলস",
      "Total Attendees" : "মোট উপস্থিতি",
      "TOTAL ATTENDEES" : "মোট উপস্থিতি",
      "Remarks" : "রিমার্কস",
      "Upload Image" : "আপলোড  ফটো",
      "Capture Image" : "ফটো তোলা",
      "Capture" : "তোলা",
      "Switch" : "পরিবর্তন",
      "Close" : "বন্ধ",
      "Review" : "পুনরায় দেখা",
      "Please fill all * mandatory fields" : "দয়া করে আবশ্যিক ফিল্ডগুলো ভর্তি করুন ",
      "Upload minimum one image" : "সর্বনিম্ন ১টি ফটো আপলোড করুন ",
      "Total Attendees required" : "মোট উপস্থিতি দরকার ",
      "Enter Your Full Name" : "পুরো নাম এন্টার করুন ",
      "Select State" : "রাজ্য নির্বাচন ",
      "Next" : "পরবর্তী ",
      "Program Reported Successfully" : "অনুষ্ঠান সফলভাবে রিপোর্ট করা হয়েছে ",
      "Yay! Keep doing the good work!" : "এইভাবেই ভালোভাবে কাজ করতে থাকুন ",
      "Address" : "ঠিকানা ",
      "Images" : "ফটোগুলি ",
      "Submit" : "সাবমিট ",
      "Upcoming Event" : "আগামী অনুষ্ঠান ",
      "Booth in Shakti Kendra" : "শক্তিকেন্দ্রের অন্তর্গত বুথগুলি ",
      "Shakti kendra in AC" : "বিধানসভার  অন্তরগত শক্তিকেন্দ্র",
      "Shakti kendra required" : "শক্তিকেন্দ্র দরকার ",
      "Other Booth in AC" : "বিধানসভার অন্তর্গত অন্যান্য বুথ ",
      "Address (where event is organised)" : "ঠিকানা (যেখানে অনুষ্ঠান অনুষ্ঠিত হয়েছে )",
      "Phone number not valid and should be 10 digit" : "ফোননম্বর বৈধ নয় এবং অবশ্যই ১০নম্বরের হতে হবে ",
      "Invalid Phone Number" : "ফোননম্বর বৈধ নয় ",
      "Logout Successfully" : "সফলভাবে লগ আউট ",
      "Didn’t recieve OTP" : "ওটিপি পাওয়া যায়নি ",
      "Resend" : "পুনরায় পাঠানো ",
      "Program" : "অনুষ্ঠান ",
      "Update" : "আপডেট ",
      "Start reporting on : " : "রিপোর্টিং শুরু করুন ",
      "You can only post after the program starts. " : "আপনি অনুষ্ঠান শুরু হওয়ার পর পোস্ট করতে পারবেন ",
      "No Programs" : "কোনো অনুষ্ঠান হয় নি ",
      "Please enter a valid Name" : "দয়া করে বৈধ নাম লিখুন ",
      "Event Address" : "অনুষ্ঠানের ঠিকানা ",
      "Image (Max 2)" : "ছবি (সর্বোচ্চ ২টি  )",
      "Geolocation not supported" : "ভৌগোলিক অবস্থান সাপোর্ট করছে না ",
      "Geolocation not found" : "ভৌগোলিক অবস্থান খুঁজে পাওয়া যাচ্ছে না ",
      "Permission denied" : "অনুমতি নেই ",
      "(Go to setting >> Site settings >>" : "( গো টু সেটিংস >>সাইট সেটিংস ",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "লোকেশনস >> মন কি বাত প্রোগাম  >> লোকেশন জানার অনুমতি দিন )",
      "(Go to setting >> Privacy and security >> Site settings >>" : "(গো টু সেটিংস >> প্রাইভেসী এবং সিকিউরিটি >> সাইট সেটিংস ",
      "Shakti Kendra" : "শক্তিকেন্দ্র ",
      "OR" : "বা",
      "Report Again" : "আবার রিপোর্ট করুন",
      "File size exceeds limit! Please select a file upto 10 MB" : "ফাইলের আকার বোরো আছে! দয়া করে 10 MB পর্যন্ত ফাইলটি নির্বাচন করুন",
      "View Less" : "কম দেখুন",
      "View More" : "আরো দেখুন",
      "Reports" : "রিপোর্ট",
      "Overview" : "সংক্ষিপ্ত",
      "Select Event" : "ইভেন্ট নির্বাচন করুন",
      "Events Reported" : "ইভেন্ট রিপোর্ট করা হয়েছে",
      "Vidhan Sabha Covered" : "বিধান শোভা কভার",
      "VIDHAN SABHA COVERED" : "বিধান শোভা কভার",
      "Form Filled Booths" : "ফর্ম ভর্তি বুথ",
      "FORM FILLED BOOTHS" : "ফর্ম ভর্তি বুথ",
      "Events Reported Booths" : "ইভেন্ট রিপোর্ট বুথ",
      "Reported Booths" : "রিপোর্ট করা বুথ",
      "Total Booths" : "মোট বুথ",
      "Zila" : "জেলা",
      "Zila Wise Reports" : "জেলা ওয়াইজ  রিপোর্ট",
      "Vidhan Sabha Wise Reports" : "বিধানসভা ওয়াইজ রিপোর্ট",
      "Booth Wise Reports" : "বুথ ওয়াইজ রিপোর্ট",
      "Reportee Name & No" : "রিপোর্টার নাম ও নং",
      "Remark" : "মন্তব্য",
      "No Reports Available" : " কোনা রিপোর্ট উপলব্ধ নেই",
      "Sort By" : "ক্রমানুসার",
      "High to Low" : "বেশি থেকে কম",
      "Low to High" : "কম থেকে বেশি",
      "Please select an event" : "একটি ইভেন্ট নির্বাচন করুন",
      "Total Event Reports" : "মোট কার্যকম রিপোর্ট",
      "OVERALL" : "মোট "
    }
  },
  tm: {
    translation: {
      "Bharatiya Janata Party" : "பாரதிய ஜனதா கட்சி",
      "Reported Program" : "பதிவுசெய்யப்பட்ட நிகழ்வு",
      "Profile" : "சுயவிவரம்",
      "Login" : "உள்நுழைவு",
      "Logout" : "வெளியேறு",
      "Mobile Number" : "மொபைல் எண்",
      "Send OTP" : "OTP ஐ அனுப்பு",
      "Verify Details" : "விவரங்களை சரிபார்க்கவும்",
      "Enter OTP sent to" : "இதற்கு அனுப்பப்பட்ட OTP ஐ உள்ளிடவும்",
      "seconds left in Resend OTP" : "OTP ஐ மீண்டும் அனுப்புவதில் மீதமுள்ள விநாடிகள்",
      "Otp Sent successfully" : "Otp வெற்றிகரமாக அனுப்பப்பட்டது",
      "Login successfully" : "வெற்றிகரமாக உள்நுழைக",
      "Invalid OTP" : "தவறான OTP",
      "Phone Required" : "தொலைபேசியை குறிப்பிடவும்",
      "Programs" : "நிகழ்ச்சிகள்",
      "Aired on" : "ஒளிபரப்பப்பட்ட தேதி",
      "Report" : "அறிக்கை",
      "Event not reported" : "நிகழ்வு அறிவிக்கப்படவில்லை",
      "Click here to know more" : "மேலும் அறிய இங்கே கிளிக் செய்யவும்",
      "Edit Profile" : "சுயவிவரத்தைத் திருத்து",
      "Phone Number" : "தொலைபேசி எண்",
      "Name" : "பெயர்",
      "Name required" : "பெயர்யை குறிப்பிடவும்",
      "Select profile photo" : "சுயவிவரப் புகைப்படத்தைத் தேர்ந்தெடுக்கவும்",
      "State" : "மாநிலம்",
      "State required" : "மாநிலம் என்ன என்பதை குறிப்பிடவும்",
      "Vidhan Sabha required" : "சட்டமன்ற தொகுதியை குறிப்பிடவும்",
      "Vidhan Sabha" : "சட்டமன்ற தொகுதி",
      "Party Zila" : "கட்சி ஜிலா",
      "Party Zila required" : "கட்சி ஜிலாவை குறிப்பிடவும்",
      "Party Mandal required" : "கட்சி மண்டலை குறிப்பிடவும்",
      "Phone required" : "தொலைபேசியை குறிப்பிடவும்",
      "Booth required" : "வாக்குச்சாவடியை குறிப்பிடவும்",
      "Mandal" : "மண்டல்",
      "Booth" : "வாக்குச்சாவடி",
      "WhatsApp Number" : "வாட்ஸ்அப் எண்",
      "Education" : "கல்வி",
      "Profession" : "தொழில்",
      "Voter ID" : "வாக்காளர் அடையாள அட்டை",
      "Pincode" : "அஞ்சல் குறியீடு",
      "File can't be upload please select file upto 1 MB" : "கோப்பைப் பதிவேற்ற முடியாது, 1MB வரையிலான கோப்பைத் தேர்ந்தெடுக்கவும்",
      "File should be JPG or PNG format" : "கோப்பு JPG அல்லது PNG வடிவத்தில் இருக்க வேண்டும்",
      "Save" : "சேமி",
      "Event Reported Successfully" : "நிகழ்வு வெற்றிகரமாக அறிவிக்கப்பட்டது",
      "User Updated Successfully" : "பயனர் விவரம் வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
      "Enter Details" : "விவரங்களை உள்ளிடவும்",
      "Total Attendees" : "மொத்த பங்கேற்பாளர்கள்",
      "TOTAL ATTENDEES" : "மொத்த பங்கேற்பாளர்கள்",
      "Remarks" : "கருத்துக்கள்",
      "Upload Image" : "படத்தை பதிவேற்றம் செய்யவும்",
      "Capture Image" : "படம் பிடிக்கவும்",
      "Capture" : "பிடிப்பு",
      "Switch" : "மாற்று",
      "Close" : "வெளியேறு",
      "Review" : "கருத்து",
      "Please fill all * mandatory fields" : "* செய்யப்பட அனைத்து விவரங்களையும் பூர்த்தி செய்யவும்",
      "Upload minimum one image" : "குறைந்தபட்சம் ஒரு படத்தை பதிவேற்றவும்",
      "Total Attendees required" : "மொத்த பங்கேற்பாளர்களை பூர்த்தி செய்யவும்",
      "Enter Your Full Name" : "உங்கள் முழுப் பெயரை உள்ளிடவும்",
      "Select State" : "மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
      "Next" : "அடுத்து",
      "Program Reported Successfully" : "வெற்றிகரமாக நிகழ்ச்சி பதிவுசெய்யப்பட்டது",
      "Yay! Keep doing the good work!" : "உங்கள் இந்த வெற்றிகரமான வேலையை தொடர்ந்து செய்யவும்.",
      "Address" : "முகவரி",
      "Images" : "படங்கள்",
      "Submit" : "சமர்ப்பிக்கவும்",
      "Upcoming Event" : "வரவிருக்கும் நிகழ்வு",
      "Booth in Shakti Kendra" : "சக்தி கேந்திராவில் வாக்குச்சாவடி",
      "Shakti kendra in AC" : "சட்டமன்ற தொகுதியில் சக்தி கேந்திரா ",
      "Shakti kendra required" : "சக்தி கேந்திரா தேவை",
      "Other Booth in AC" : "சட்டமன்ற தொகுதியில் உள்ள மற்ற வாக்குச்சாவடி",
      "Address (where event is organised)" : "முகவரி (நிகழ்வு ஏற்பாடு செய்யப்பட்ட இடம்)",
      "Phone number not valid and should be 10 digit" : "தொலைபேசி எண் தவறானது மற்றும் 10 இலக்கமாக இருக்க வேண்டும்",
      "Invalid Phone Number" : "தவறான தொலைபேசி எண்",
      "Logout Successfully" : "வெற்றிகரமாக வெளியேறு்",
      "Didn’t recieve OTP" : "OTP பெறவில்லை",
      "Resend" : "மீண்டும் அனுப்பு",
      "Program" : "நிகழ்வு",
      "Update" : "புதுப்பிக்கவும்",
      "Start reporting on : " : "புகாரளிக்கத் தொடங்குங்கள்:",
      "You can only post after the program starts. " : "நிகழ்வு தொடங்கிய பின்னரே நீங்கள் பதிவிட முடியும்.",
      "No Programs" : "நிகழ்வுகள் இல்லை",
      "Please enter a valid Name" : "சரியான பெயரை உள்ளிடவும்",
      "Event Address" : "நிகழ்வு நடந்த முகவரி",
      "Image (Max 2)" : "புகைப்படங்கள் (அதிகபட்சம் 2)",
      "Geolocation not supported" : "புவிஇருப்பிடம் ஆதரிக்கப்படவில்லை",
      "Geolocation not found" : "புவி இருப்பிடம் கிடைக்கவில்லை",
      "Permission denied" : "அனுமதி இல்லை",
      "(Go to setting >> Site settings >>" : "அமைப்புகளுக்குச் செல்க >> தள அமைப்புகள்",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "இடம் >> மன் கி பாத் >> இருப்பிட அணுகல் அனுமதி)",
      "(Go to setting >> Privacy and security >> Site settings >>" : "அமைப்புகளுக்குச் செல்க >> தனியுரிமை மற்றும் பாதுகாப்பு >> தள அமைப்புகள்",
      "Shakti Kendra" : "சக்தி கேந்திரம்",
      "OR" : "அல்லது",
      "Report Again" : "மீண்டும் அறிக்கை",
      "File size exceeds limit! Please select a file upto 10 MB" : "கோப்பின் அளவு வரம்பை மீறுகிறது! 10 MB வரையிலான கோப்பைத் தேர்ந்தெடுக்கவும்.",
      "View Less" : "குறைவாக பார்க்கவும்",
      "View More" : "மேலும் பார்க்க",
      "Reports" : "அறிக்கைகள்",
      "Overview" : "கண்ணோட்டம்",
      "Select Event" : "நிகழ்வைத் தேர்ந்தெடுக்கவும்",
      "Events Reported" : "நிகழ்வுகள் பதிவாகியுள்ளன",
      "Vidhan Sabha Covered" : "விதானசபா மூடப்பட்டது",
      "VIDHAN SABHA COVERED" : "விதானசபா மூடப்பட்டது",
      "Form Filled Booths" : "படிவம் நிரப்பப்பட்ட சாவடிகள்",
      "FORM FILLED BOOTHS" : "படிவம் நிரப்பப்பட்ட சாவடிகள்",
      "Events Reported Booths" : "நிகழ்வுகள் அறிக்கை சாவடிகள்",
      "Reported Booths" : "அறிக்கையிடப்பட்ட சாவடிகள்",
      "Total Booths" : "மொத்த சாவடிகள்",
      "Zila" : "ஜிலா",
      "Zila Wise Reports" : "ஜிலா வைஸ் அறிக்கைகள்",
      "Vidhan Sabha Wise Reports" : "விதானசபா வாரியான அறிக்கைகள்",
      "Booth Wise Reports" : "பூத் வாரியான அறிக்கைகள்",
      "Reportee Name & No" : "அறிக்கையாளர் பெயர் & எண்",
      "Remark" : "கருத்து",
      "No Reports Available" : "அறிக்கைகள் எதுவும் கிடைக்கவில்லை",
      "Sort By" : "வரிசைப்படுத்தவும்",
      "High to Low" : "உயர்ந்தது குறைந்தது",
      "Low to High" : "குறைந்த உயர்ந்த",
      "Please select an event" : "நிகழ்வைத் தேர்ந்தெடுக்கவும்",
      "Total Event Reports" : "மொத்த நிகழ்வு அறிக்கைகள்",
      "OVERALL" : "ஒட்டுமொத்த"
    }
  },
  ml: {
    translation: {
      "Bharatiya Janata Party" : "ഭാരതീയ ജനതാ പാർട്ടി",
      "Reported Program" : "റിപ്പോർട്ട് ചെയ്ത പ്രോഗ്രാം",
      "Profile" : "പ്രൊഫൈൽ",
      "Login" : "ലോഗിൻ",
      "Logout" : "പുറത്തുകടക്കുക",
      "Mobile Number" : "മൊബൈൽ നമ്പർ",
      "Send OTP" : "OTP അയയ്ക്കുക",
      "Verify Details" : "വിശദാംശങ്ങൾ പരിശോധിക്കുക",
      "Enter OTP sent to" : "അയച്ച OTP നൽകുക",
      "seconds left in Resend OTP" : "OTP വീണ്ടും അയയ്‌ക്കുന്നതിൽ സെക്കൻഡുകൾ ശേഷിക്കുന്നു",
      "Otp Sent successfully" : "Otp വിജയകരമായി അയച്ചു",
      "Login successfully" : "വിജയകരമായി ലോഗിൻ ചെയ്യുക",
      "Invalid OTP" : "അസാധുവായ OTP",
      "Phone Required" : "ഫോൺ ആവശ്യമാണ്",
      "Programs" : "പ്രോഗ്രാമുകൾ",
      "Aired on" : "സംപ്രേഷണം ചെയ്തു",
      "Report" : "റിപ്പോർട്ട് ചെയ്യുക",
      "Event not reported" : "ഇവന്റ്‌ റിപ്പോർട്ട് ചെയ്തിട്ടില്ല",
      "Click here to know more" : "കൂടുതൽ അറിയാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക",
      "Edit Profile" : "പ്രൊഫൈൽ എഡിറ്റ് ചെയ്യുക",
      "Phone Number" : "ഫോൺ നമ്പർ",
      "Name" : "പേര്",
      "Name required" : "പേര് ആവശ്യമാണ്",
      "Select profile photo" : "പ്രൊഫൈൽ ഫോട്ടോ തിരഞ്ഞെടുക്കുക",
      "State" : "സംസ്ഥാനം",
      "State required" : "സംസ്ഥാനം ആവശ്യമാണ്",
      "Vidhan Sabha required" : "നിയമസഭ ആവശ്യമാണ്",
      "Vidhan Sabha" : "വിധാൻ സഭ",
      "Party Zila" : "പാർട്ടി ജില്ല",
      "Party Zila required" : "പാർട്ടി ജില്ല ആവശ്യമാണ്",
      "Party Mandal required" : "പാർട്ടി മണ്ഡലം ആവശ്യമാണ്",
      "Phone required" : "ഫോൺ ആവശ്യമാണ്",
      "Booth required" : "ബൂത്ത് ആവശ്യമാണ്",
      "Mandal" : "മണ്ഡലം",
      "Booth" : "ബൂത്ത്",
      "WhatsApp Number" : "വാട്ട്‌സ്ആപ്പ് നമ്പർ",
      "Education" : "വിദ്യാഭ്യാസം",
      "Profession" : "തൊഴിൽ",
      "Voter ID" : "വോട്ടർ ഐ.ഡി",
      "Pincode" : "പിൻ കോഡ്",
      "File can't be upload please select file upto 1 MB" : "ഫയൽ അപ്‌ലോഡ് ചെയ്യാൻ കഴിയില്ല, ദയവായി 1 MB വരെയുള്ള ഫയൽ തിരഞ്ഞെടുക്കുക",
      "File should be JPG or PNG format" : "ഫയൽ JPG അല്ലെങ്കിൽ PNG ഫോർമാറ്റ് ആയിരിക്കണം",
      "Save" : "സേവ് ",
      "Event Reported Successfully" : "ഇവന്റ് വിജയകരമായി റിപ്പോർട്ട് ചെയ്തു",
      "User Updated Successfully" : "ഉപയോക്താവ് അപ്‌ഡേറ്റ് ചെയ്‌തു",
      "Enter Details" : "വിശദാംശങ്ങൾ നൽകുക",
      "Total Attendees" : "ആകെ പങ്കെടുത്തവർ",
      "TOTAL ATTENDEES" : "ആകെ പങ്കെടുത്തവർ",
      "Remarks" : "പരാമർശത്തെ",
      "Upload Image" : "ചിത്രം അപ്ലോഡ് ചെയ്യുക",
      "Capture Image" : "ചിത്രം പകർത്തുക",
      "Capture" : "ക്യാപ്ചർ",
      "Switch" : "മാറുക",
      "Close" : "അടയ്ക്കുക",
      "Review" : "അവലോകനം",
      "Please fill all * mandatory fields" : "എല്ലാ * നിർബന്ധിത ഫീൽഡുകളും പൂരിപ്പിക്കുക",
      "Upload minimum one image" : "കുറഞ്ഞത് ഒരു ചിത്രം അപ്‌ലോഡ് ചെയ്യുക",
      "Total Attendees required" : "ആകെ പങ്കെടുക്കുന്നവർ ആവശ്യമാണ്",
      "Enter Your Full Name" : "നിങ്ങളുടെ പൂർണ നാമം എഴുതുക",
      "Select State" : "സംസ്ഥാനം തിരഞ്ഞെടുക്കുക",
      "Next" : "അടുത്തത്",
      "Program Reported Successfully" : "പ്രോഗ്രാം വിജയകരമായി റിപ്പോർട്ട് ചെയ്തു",
      "Yay! Keep doing the good work!" : "യായ്! നല്ല ജോലി ചെയ്യുന്നത് തുടരുക!",
      "Address" : "വിലാസം",
      "Images" : "ചിത്രങ്ങൾ",
      "Submit" : "സമർപ്പിക്കുക",
      "Upcoming Event" : "വരാനിരിക്കുന്ന ഇവന്റ്",
      "Booth in Shakti Kendra" : "ശക്തി കേന്ദ്രത്തിലെ ബൂത്ത്",
      "Shakti kendra in AC" : "നിയമസഭാ മണ്ഡലത്തിലെ ശക്തി കേന്ദ്രം",
      "Shakti kendra required" : "ശക്തി കേന്ദ്രം ആവശ്യമാണ്",
      "Other Booth in AC" : "എസിയിലെ മറ്റ് ബൂത്ത്",
      "Address (where event is organised)" : "വിലാസം (ഇവന്റ് സംഘടിപ്പിക്കുന്നിടത്ത്)",
      "Phone number not valid and should be 10 digit" : "ഫോൺ നമ്പർ അസാധുവാണ് കൂടാതെ 10 അക്കമായിരിക്കണം",
      "Invalid Phone Number" : "Invalid Phone Number",
      "Logout Successfully" : "ലോഗ്ഔട്ട് വിജയകരമായി",
      "Didn’t recieve OTP" : "OTP ലഭിച്ചില്ല",
      "Resend" : "വീണ്ടും അയയ്ക്കുക",
      "Program" : "പ്രോഗ്രാം",
      "Update" : "അപ്ഡേറ്റ് ചെയ്യുക",
      "Start reporting on : " : "റിപ്പോർട്ടിംഗ് ആരംഭിക്കുക:",
      "You can only post after the program starts. " : "പ്രോഗ്രാം ആരംഭിച്ചതിന് ശേഷം മാത്രമേ നിങ്ങൾക്ക് പോസ്റ്റ് ചെയ്യാൻ കഴിയൂ.",
      "No Programs" : "പ്രോഗ്രാമുകളൊന്നുമില്ല",
      "Please enter a valid Name" : "ദയവായി ഒരു സാധുതയുള്ള പേര് നൽകുക",
      "Event Address" : "ഇവന്റ് വിലാസം",
      "Image (Max 2)" : "ചിത്രം (പരമാവധി 2)",
      "Geolocation not supported" : "ജിയോലൊക്കേഷൻ പിന്തുണയ്ക്കുന്നില്ല",
      "Geolocation not found" : "ജിയോലൊക്കേഷൻ കണ്ടെത്തിയില്ല",
      "Permission denied" : "അനുമതി നിഷേധിച്ചു",
      "(Go to setting >> Site settings >>" : "(ക്രമീകരണം >> സൈറ്റ് ക്രമീകരണങ്ങൾ >> എന്നതിലേക്ക് പോകുക",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "ലൊക്കേഷനുകൾ >> മൻ കി ബാത്ത് പ്രോഗ്രാം >> ലൊക്കേഷനിലേക്ക് ആക്സസ് അനുവദിക്കുക)",
      "(Go to setting >> Privacy and security >> Site settings >>" : "(ക്രമീകരണം >> സ്വകാര്യതയും സുരക്ഷയും >> സൈറ്റ് ക്രമീകരണങ്ങൾ >> എന്നതിലേക്ക് പോകുക",
      "Shakti Kendra" : "ശക്തി കേന്ദ്ര",
      "OR" : "അഥവാ",
      "Report Again" : "റിപ്പോർട്ട് എഗൈൻ",
      "File size exceeds limit! Please select a file upto 10 MB" : "ഫയലിന്റെ വലുപ്പം പരിധി കവിഞ്ഞു! 10 MB വരെയുള്ള ഒരു ഫയൽ തിരഞ്ഞെടുക്കുക",
      "View Less" : "കുറവ് കാണുക",
      "View More" : "കൂടുതൽ കാണു",
      "Reports" : "റിപ്പോർട്ടുകൾ",
      "Overview" : "അവലോകനം",
      "Select Event" : "ഇവന്റ് തിരഞ്ഞെടുക്കുക",
      "Events Reported" : "സംഭവങ്ങൾ റിപ്പോർട്ട് ചെയ്തു",
      "Vidhan Sabha Covered" : "വിധാൻസഭ കവർ ചെയ്തു",
      "VIDHAN SABHA COVERED" : "വിധാൻസഭ കവർ ചെയ്തു",
      "Form Filled Booths" : "ഫോം പൂരിപ്പിച്ച ബൂത്തുകൾ",
      "FORM FILLED BOOTHS" : "ഫോം പൂരിപ്പിച്ച ബൂത്തുകൾ",
      "Events Reported Booths" : "ഇവന്റുകൾ റിപ്പോർട്ട് ചെയ്ത ബൂത്തുകൾ",
      "Reported Booths" : "റിപ്പോർട്ട് ചെയ്യപ്പെട്ട ബൂത്തുകൾ",
      "Total Booths" : "ആകെ ബൂത്തുകൾ",
      "Zila" : "ജില്ല",
      "Zila Wise Reports" : "ജില്ല വൈസ് റിപ്പോർട്ടുകൾ",
      "Vidhan Sabha Wise Reports" : "നിയമസഭാ തിരിച്ചുള്ള റിപ്പോർട്ടുകൾ",
      "Booth Wise Reports" : "ബൂത്ത് വൈസ് റിപ്പോർട്ടുകൾ",
      "Reportee Name & No" : "റിപ്പോർട്ട് ചെയ്യുന്നയാളുടെ പേര് & നമ്പർ",
      "Remark" : "പരാമർശം",
      "No Reports Available" : "റിപ്പോർട്ടുകളൊന്നും ലഭ്യമല്ല",
      "Sort By" : "ക്രമീകരിച്ചയാൾ",
      "High to Low" : "ഉയർന്നതും താഴ്ന്നതും",
      "Low to High" : "താഴ്ന്നതും ഉയർന്നതും",
      "Please select an event" : "ദയവായി ഒരു ഇവന്റ് തിരഞ്ഞെടുക്കുക",
      "Total Event Reports" : "മൊത്തം ഇവന്റ് റിപ്പോർട്ടുകൾ",
      "OVERALL" : "മൊത്തത്തിൽ"
    }
  },
  kn:{
    translation:{
      "Bharatiya Janata Party" : "ಭಾರತೀಯ ಜನತಾ ಪಕ್ಷ",
      "Reported Program" : "ವರದಿ ಮಾಡಿದ ಕಾರ್ಯಕ್ರಮ",
      "Profile" : "ಪ್ರೊಫೈಲ್",
      "Login" : "ಲಾಗಿನ್",
      "Logout" : "ಲಾಗ್ ಔಟ್",
      "Mobile Number" : "ಮೊಬೈಲ್ ನಂಬರ",
      "Send OTP" : "OTP ಕಳುಹಿಸಿ",
      "Verify Details" : "ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ",
      "Enter OTP sent to" : "OTP ಕಳುಹಿಸಲಾಗಿದೆ",
      "seconds left in Resend OTP" : "ಸೆಕೆಂಡುಗಳು OTP ಮರುಕಳುಹಿಸಲು ಉಳಿದಿವೆ",
      "Otp Sent successfully" : "OTP ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ",
      "Login successfully" : "ಯಶಸ್ವಿಯಾಗಿ ಲಾಗಿನ್ ಆಗಿದೆ",
      "Invalid OTP" : "OTP ತಪ್ಪಾಗಿದೆ",
      "Phone Required" : "ಫೋನ್ ನಂಬರ ಅಗತ್ಯವಿದೆ",
      "Programs" : "ಕಾರ್ಯಕ್ರಮಗಳು",
      "Aired on" : "ಪ್ರಸಾರವಾಗಿದೆ",
      "Report" : "ವರದಿ",
      "Event not reported" : "ಈವೆಂಟ್ ವರದಿಯಾಗಿಲ್ಲ",
      "Click here to know more" : "ಇನ್ನಷ್ಟು ತಿಳಿಯಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
      "Edit Profile" : "ಪ್ರೊಫೈಲ್ ಬದಲಾವಣೆ ಮಾಡಿ",
      "Phone Number" : "ಫೋನ್ ನಂಬರ್",
      "Name" : "ಹೆಸರು",
      "Name required" : "ಹೆಸರು ಅಗತ್ಯವಿದೆ",
      "Select profile photo" : "ಪ್ರೊಫೈಲ್ ಫೋಟೋ ಆಯ್ಕೆಮಾಡಿ",
      "State" : "ರಾಜ್ಯ",
      "State required" : "ರಾಜ್ಯ ಅಗತ್ಯವಿದೆ",
      "Vidhan Sabha required" : "ವಿಧಾನಸಭೆ ಅಗತ್ಯವಿದೆ",
      "Vidhan Sabha" : "ವಿಧಾನ ಸಭೆ",
      "Party Zila" : "ಪಾರ್ಟಿ ಜಿಲ್ಲೆ",
      "Party Zila required" : "ಪಾರ್ಟಿ ಜಿಲ್ಲೆ ಅಗತ್ಯವಿದೆ",
      "Party Mandal required" : "ಪಕ್ಷದ ಮಂಡಲ ಅಗತ್ಯವಿದೆ",
      "Phone required" : "ಫೋನ್ ನಂಬರ ಅಗತ್ಯವಿದೆ",
      "Booth required" : "ಬೂತ್ ಅಗತ್ಯವಿದೆ",
      "Mandal" : "ಮಂಡಲ್",
      "Booth" : "ಬೂತ್",
      "WhatsApp Number" : "ವಾಟ್ಸಪ್ಪ್ ನಂಬರ್",
      "Education" : "ಶಿಕ್ಷಣ",
      "Profession" : "ವೃತ್ತಿ",
      "Voter ID" : "ಮತದಾರರ ಗುರುತಿನ ಐಡಿ",
      "Pincode" : "ಪಿನ್‌ಕೋಡ್",
      "File can't be upload please select file upto 1 MB" : "ಫೈಲ್ ಅನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ ದಯವಿಟ್ಟು 1 MB ಗಿಂತ ಕಡಿಮೆ ಇರುವ ಫೈಲ್ ಅನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "File should be JPG or PNG format" : "ಫೈಲ್ JPG ಅಥವಾ PNG ಫಾರ್‌ಮೇಟ್ ಆಗಿರಬೇಕು",
      "Save" : "ಸೇವ್",
      "Event Reported Successfully" : "ಈವೆಂಟ್ ಯಶಸ್ವಿಯಾಗಿ ವರದಿಯಾಗಿದೆ",
      "User Updated Successfully" : "ಬಳಕೆದಾರರ ಮಾಹಿತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
      "Enter Details" : "ವಿವರಗಳನ್ನು ತುಂಬಿಸಿ",
      "Total Attendees" : "ಒಟ್ಟು ಪಾಲ್ಗೊಳ್ಳುವವರು",
      "TOTAL ATTENDEES" : "ಒಟ್ಟು ಪಾಲ್ಗೊಳ್ಳುವವರು",
      "Remarks" : "ಟೀಕೆಗಳು",
      "Upload Image" : "ಫೋಟೋ ಅಪ್ಲೋಡ್ ಮಾಡಿಸಿ",
      "Capture Image" : "ಫೋಟೋ ಕ್ಲಿಕ್ ಮಾಡಿಸಿ",
      "Capture" : "ಸೆರೆಹಿಡಿಯಿರಿ",
      "Switch" : "ಬದಲಾವಣೆ ಮಾಡಿ",
      "Close" : "ಮುಚ್ಚಿ",
      "Review" : "ಸಮೀಕ್ಷೆ",
      "Please fill all * mandatory fields" : "ದಯವಿಟ್ಟು ಎಲ್ಲಾ * ಕಡ್ಡಾಯ ಕ್ಷೇತ್ರಗಳನ್ನು ತುಂಬಿಸಿ",
      "Upload minimum one image" : "ಕನಿಷ್ಠ ಒಂದು ಪೋಟೊ ಅಪ್ಲೋಡ್ ಮಾಡಿ",
      "Total Attendees required" : "ಒಟ್ಟು ಪಾಲ್ಗೊಳ್ಳುವವರು ಅಗತ್ಯವಿದೆ",
      "Enter Your Full Name" : "ನಿಮ್ಮ ಪೂರ್ಣ ಹೆಸರನ್ನು ತುಂಬಿಸಿ",
      "Select State" : "ರಾಜ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "Next" : "ಮುಂದೆ",
      "Program Reported Successfully" : "ಕಾರ್ಯಕ್ರಮವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ವರದಿ ಮಾಡಲಾಗಿದೆ",
      "Yay! Keep doing the good work!" : "ಹೀಗೆ ಸಹಕಾರ ನೀಡುತ್ತಾ ಇರಿ.",
      "Address" : "ವಿಳಾಸ",
      "Images" : "ಫೋಟೋ",
      "Submit" : "ಸಲ್ಲಿಸು",
      "Upcoming Event" : "ಮುಂಬರುವ ಕಾರ್ಯಕ್ರಮ",
      "Booth in Shakti Kendra" : "ಶಕ್ತಿ ಕೇಂದ್ರದಲ್ಲಿ ಬೂತ್",
      "Shakti kendra in AC" : "ವಿಧಾನಸಭಾ ಕ್ಷೇತ್ರದಲ್ಲಿ ಶಕ್ತಿ ಕೇಂದ್ರ",
      "Shakti kendra required" : "ಶಕ್ತಿ ಕೇಂದ್ರದ ಆಯ್ಕೆ ಕಡ್ಡಾಯವಾಗಿದೆ",
      "Other Booth in AC" : "ವಿಧಾನಸಭಾ ಕ್ಷೇತ್ರದ ಇತರೆ ಬೂತಗಳು",
      "Address (where event is organised)" : "ವಿಳಾಸ (ಕಾರ್ಯಕ್ರಮ ಅನ್ನು ಎಲ್ಲಿ ಆಯೋಜಿಸಲಾಗಿದೆ)",
      "Phone number not valid and should be 10 digit" : "ಫೋನ್ ನಂಬರ್ ಮಾನ್ಯವಾಗಿಲ್ಲ ಮತ್ತು 10 ಸಂಖ್ಯೆಗಳು ಇರಬೇಕು",
      "Invalid Phone Number" : "ಫೋನ್ ನಂಬರ್ ತಪ್ಪಾಗಿದೆ",
      "Logout Successfully" : "ಯಶಸ್ವಿಯಾಗಿ ಲಾಗ್ ಔಟ್",
      "Didn’t recieve OTP" : "OTP ಸ್ವೀಕರಿಸಲಾಗಿಲ್ಲ",
      "Resend" : "ಮಾತ್ತೊಮ್ಮೆ ಕಳುಹಿಸಿ",
      "Program" : "ಕಾರ್ಯಕ್ರಮ",
      "Update" : "ಅಪ್ಡೇಟ್ ಮಾಡಿ",
      "Start reporting on : " : "ವರದಿ ಮಾಡಲು ಪ್ರಾರಂಭಿಸಿ :",
      "You can only post after the program starts. " : "ಪ್ರೋಗ್ರಾಂ ಪ್ರಾರಂಭವಾದ ನಂತರವೇ ನೀವು ಪೋಸ್ಟ್ ಮಾಡಬಹುದು.",
      "No Programs" : "ಯಾವುದೇ ಕಾರ್ಯಕ್ರಮವಿಲ್ಲ",
      "Please enter a valid Name" : "ದಯವಿಟ್ಟು ಸರಿಯಾದ ಹೆಸರನ್ನು ತುಂಬಿಸಿ",
      "Event Address" : "ಕಾರ್ಯಕ್ರಮದ ವಿಳಾಸ",
      "Image (Max 2)" : "ಫೋಟೋ (ಗರಿಷ್ಠ 2)",
      "Geolocation not supported" : "ಜಿಯೋಲೋಕೇಶನ(GPS) ಸಪೋರ್ಟ್ ಇಲ್ಲ",
      "Geolocation not found" : "ಜಿಯೋಲೋಕೇಶನ(GPS) ಕಂಡುಬಂದಿಲ್ಲ",
      "Permission denied" : "ಅನುಮತಿ ನೀಡಲಾಗಿಲ್ಲ",
      "(Go to setting >> Site settings >>" : "(ಸೆಟ್ಟಿಂಗ್ ಹೋಗಿ >> ಸೈಟ್ ಸೆಟ್ಟಿಂಗ್)",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "ಸ್ಥಳ >> ಮನ್ ಕಿ ಬಾಟ್ >> ಸ್ಥಳ ಪ್ರವೇಶಕ್ಕಾಗಿ ಅನುಮತಿಸಿ)",
      "(Go to setting >> Privacy and security >> Site settings >>" : "(ಸೆಟ್ಟಿಂಗ್ ಹೋಗಿ >> ಗೌಪ್ಯತೆ ಮತ್ತು ಸುರಕ್ಷತೆ >> ಸೈಟ್ ಸೆಟ್ಟಿಂಗ್ >>",
      "Shakti Kendra" : "ಶಕ್ತಿ ಕೇಂದ್ರ",
      "OR" : "ಅಥವಾ",
      "Report Again" : "ಮತ್ತೆ ವರದಿ ಮಾಡಿ",
      "File size exceeds limit! Please select a file upto 10 MB" : "ఫైల్ పరిమాణం పరిమితిని మించిపోయింది! దయచేసి 10MB వరకు ఫైల్‌ని ఎంచుకోండి",
      "View Less" : "ಕಡಿಮೆ ವೀಕ್ಷಿಸಿ",
      "View More" : "ಇನ್ನಷ್ಟು ವೀಕ್ಷಿಸಿ",
      "Reports" : "ವರದಿಗಳು",
      "Overview" : "ಅವಲೋಕನ",
      "Select Event" : "ಕಾರ್ಯಕ್ರಮ ಆಯ್ಕೆಮಾಡಿ",
      "Events Reported" : "ಕಾರ್ಯಕ್ರಮ ವರದಿಯಾಗಿದೆ",
      "Vidhan Sabha Covered" : "ವಿಧಾನಸಭೆ ವ್ಯಾಪ್ತಿ",
      "VIDHAN SABHA COVERED" : "ವಿಧಾನಸಭೆ ವ್ಯಾಪ್ತಿ",
      "Form Filled Booths" : "ಫಾರ್ಮ್ ತುಂಬಿದ ಬೂತ್‌ಗಳು",
      "FORM FILLED BOOTHS" : "ಫಾರ್ಮ್ ತುಂಬಿದ ಬೂತ್‌ಗಳು",
      "Events Reported Booths" : "ಕಾರ್ಯಕ್ರಮದ  ವರದಿ ಬೂತ್‌ಗಳು",
      "Reported Booths" : "ವರದಿ ಮಾಡಿದ ಬೂತ್‌ಗಳು",
      "Total Booths" : "ಒಟ್ಟು ಬೂತ್‌ಗಳು",
      "Zila" : "ಜಿಲ್ಲೆ",
      "Zila Wise Reports" : "ಜಿಲ್ಲಾ ಪ್ರಕಾರದ ವರದಿಗಳು ",
      "Vidhan Sabha Wise Reports" : "ವಿಧಾನಸಭೆ ಪ್ರಕಾರದ ವರದಿಗಳು ",
      "Booth Wise Reports" : "ಬೂತ್  ಪ್ರಕಾರದ ವರದಿಗಳು ",
      "Reportee Name & No" : "ವರದಿಗಾರರ ಹೆಸರು ಮತ್ತು ಸಂಖ್ಯೆ",
      "Remark" : "ಟಿಪ್ಪಣಿ ",
      "No Reports Available" : "ಯಾವುದೇ ವರದಿಗಳು ಲಭ್ಯವಿಲ್ಲ",
      "Sort By" : "ವಿಂಗಡಿಸು",
      "High to Low" : "ಹೆಚ್ಚರಿಂದ   ಕಡಿಮೆ ",
      "Low to High" : "ಕಡಿಮೆಯಿಂದ ಹೆಚ್ಚು",
      "Please select an event" : "ದಯವಿಟ್ಟು ಕಾರ್ಯಕ್ರಮವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      "Total Event Reports" : "ಒಟ್ಟು ಕಾರ್ಯಕ್ರಮದ  ವರದಿಗಳು",
      "OVERALL" : "ಒಟ್ಟು"
    }
  },
  as:{
    translation: {
      "Bharatiya Janata Party" : "ভাৰতীয় জনতা পাৰ্টি",
      "Reported Program" : "প্ৰতিবেদিত কাৰ্যসূচী",
      "Profile" : "প্ৰ'ফাইল",
      "Login" : "লগইন",
      "Logout" : "লগআউট",
      "Mobile Number" : "ম'বাইল নম্বৰ",
      "Send OTP" : "ওটিপি প্ৰেৰণ কৰক",
      "Verify Details" : "বিৱৰণ প্ৰমাণিত কৰক",
      "Enter OTP sent to" : "প্ৰেৰণ কৰা অ'টিপি প্ৰবিষ্ট কৰক",
      "seconds left in Resend OTP" : "পুনৰ প্ৰেৰণ অ'টিপিত ছেকেণ্ড বাকী আছে",
      "Otp Sent successfully" : "অটপ সফলতাৰে প্ৰেৰণ কৰা হৈছে",
      "Login successfully" : "সফলতাৰে লগইন কৰক",
      "Invalid OTP" : "অবৈধ অ'টিপি",
      "Phone Required" : "ফোনৰ প্ৰয়োজন",
      "Programs" : "প্ৰ'গ্ৰামসমূহ",
      "Aired on" : "সম্প্ৰচাৰিত",
      "Report" : "প্ৰতিবেদন",
      "Event not reported" : "ঘটনা প্ৰতিবেদিত হোৱা নাই",
      "Click here to know more" : "অধিক জানিবলৈ ইয়াত ক্লিক কৰক",
      "Edit Profile" : "প্ৰফাইল সম্পাদনা কৰক",
      "Phone Number" : "ফোন নম্বৰ",
      "Name" : "নাম",
      "Name required" : "নাম প্ৰয়োজন",
      "Select profile photo" : "প্ৰ'ফাইল ফটো বাছক",
      "State" : "ৰাজ্য",
      "State required" : "অৱস্থাৰ প্ৰয়োজন",
      "Vidhan Sabha required" : "বিধান সভাৰ প্ৰয়োজন",
      "Vidhan Sabha" : "বিধান সভা",
      "Party Zila" : "পাৰ্টি জিলা",
      "Party Zila required" : "পাৰ্টি জিলা প্ৰয়োজন",
      "Party Mandal required" : "পাৰ্টি মণ্ডলৰ প্ৰয়োজন",
      "Phone required" : "ফোনৰ প্ৰয়োজন",
      "Booth required" : "বুথৰ প্ৰয়োজন",
      "Mandal" : "মণ্ডল",
      "Booth" : "বুথ",
      "WhatsApp Number" : "হোৱাটছএপ নম্বৰ",
      "Education" : "শিক্ষা",
      "Profession" : "পেছা",
      "Voter ID" : "ভোটাৰ আইডি",
      "Pincode" : "পিনকোড",
      "File can't be upload please select file upto 1 MB" : "ফাইল আপলোড কৰিব নোৱাৰি অনুগ্ৰহ কৰি 1 এমবি লৈকে ফাইল বাছনি কৰক",
      "File should be JPG or PNG format" : "ফাইল জেপিজি বা পিএনজি ফৰ্মেট হ'ব লাগিব",
      "Save" : "সংৰক্ষণ কৰক",
      "Event Reported Successfully" : "ঘটনা সফলতাৰে প্ৰতিবেদিত হৈছে",
      "User Updated Successfully" : "ব্যৱহাৰকাৰী সফলতাৰে আপডেট কৰা হৈছে",
      "Enter Details" : "বিৱৰণ প্ৰবিষ্ট কৰক",
      "Total Attendees" : "মুঠ অংশগ্ৰহণকাৰী",
      "TOTAL ATTENDEES" : "মুঠ অংশগ্ৰহণকাৰী",
      "Remarks" : "মন্তব্য",
      "Upload Image" : "ছবি আপলোড কৰক",
      "Capture Image" : "ছবি তুলি লওক",
      "Capture" : "কেপচাৰ কৰক",
      "Switch" : "পৰিবৰ্তন কৰক",
      "Close" : "ওচৰ",
      "Review" : "পৰ্যালোচনা",
      "Please fill all * mandatory fields" : "অনুগ্ৰহ কৰি সকলো * বাধ্যতামূলক ক্ষেত্ৰ পূৰণ কৰক",
      "Upload minimum one image" : "নিম্নতম এটা ছবি আপলোড কৰক",
      "Total Attendees required" : "মুঠ অংশগ্ৰহণকাৰীৰ প্ৰয়োজন",
      "Enter Your Full Name" : "আপোনাৰ সম্পূৰ্ণ নাম প্ৰবিষ্ট কৰক",
      "Select State" : "অৱস্থা নিৰ্বাচন কৰক",
      "Next" : "পৰৱৰ্তী",
      "Program Reported Successfully" : "প্ৰ'গ্ৰাম সফলতাৰে প্ৰতিবেদিত হৈছে",
      "Yay! Keep doing the good work!" : "য়াই! ভাল কাম কৰি থাকক!",
      "Address" : "ঠিকনা",
      "Images" : "ছবিসমূহ",
      "Submit" : "দাখিল কৰা",
      "Upcoming Event" : "আগন্তুক অনুষ্ঠান",
      "Booth in Shakti Kendra" : "শক্তি কেন্দ্ৰৰ বুথ",
      "Shakti kendra in AC" : " বিধানসভাৰ  অন্তৰ্গত শক্তিকেন্দ্ৰ",
      "Shakti kendra required" : "শক্তি কেন্দ্ৰৰ প্ৰয়োজন",
      "Other Booth in AC" : "এচিৰ অন্যান্য বুথ",
      "Address (where event is organised)" : "ঠিকনা (য'ত অনুষ্ঠান আয়োজন কৰা হয়)",
      "Phone number not valid and should be 10 digit" : "ফোন নম্বৰ বৈধ নহয় আৰু 10 টা অংকৰ হ'ব লাগিব",
      "Invalid Phone Number" : "অবৈধ ফোন নম্বৰ",
      "Logout Successfully" : "লগআউট সফলতাৰে",
      "Didn’t recieve OTP" : "অ'টিপি প্ৰাপ্ত কৰা নাই",
      "Resend" : "পুনৰ প্ৰেৰণ কৰক",
      "Program" : "কাৰ্য্যক্ৰম",
      "Update" : "আপডেট  কৰক",
      "Start reporting on : " : "প্ৰতিবেদন আৰম্ভ কৰক  ",
      "You can only post after the program starts. " : "প্ৰ'গ্ৰাম আৰম্ভ হোৱাৰ পিছতহে আপুনি পোষ্ট কৰিব পাৰে",
      "No Programs" : "কোনো প্ৰগ্ৰাম নাই",
      "Please enter a valid Name" : "অনুগ্ৰহ কৰি এটা বৈধ নাম প্ৰবিষ্ট কৰক",
      "Event Address" : "ঘটনা ৰ ঠিকনা",
      "Image (Max 2)" : "ছবি (সৰ্বাধিক 2)",
      "Geolocation not supported" : "ভূ-অৱস্থান সমৰ্থিত নহয়",
      "Geolocation not found" : "ভূ-অৱস্থান পোৱা নগ'ল",
      "Permission denied" : "অনুমতি অস্বীকাৰ কৰা হৈছে",
      "(Go to setting >> Site settings >>" : "ছেটিংলৈ যাওক >>  ছাইট ছেটিংছ  >>",
      "Locations >> mannkibaatprogram >> Allow access to location)" : " অৱস্থানসমূহ  >>  মন কি বাত কাৰ্যসূচী  >>  অৱস্থানত প্ৰৱেশৰ অনুমতি দিয়ক  ",
      "(Go to setting >> Privacy and security >> Site settings >>" : "ছেটিংলৈ যাওক   >>  গোপনীয়তা আৰু সুৰক্ষা  >>  ছাইট ছেটিংছ",
      "Shakti Kendra" : "শক্তি কেন্দ্ৰ",
      "OR" : "অথবা",
      "Report Again" : "আকৌ ৰিপৰ্ট কৰক",
      "File size exceeds limit! Please select a file upto 10 MB" : "ফাইলৰ আকাৰ সীমা অধিক হৈছে! অনুগ্ৰহ কৰি 10MB লৈকে এটা ফাইল নিৰ্ব্বাচন কৰক ",
      "View Less" : "কম চাওক",
      "View More" : "আৰু চাওক",
      "Reports" : "ৰিপ'ৰ্ট",
      "Overview" : "সংক্ষিপ্ত",
      "Select Event" : "ইভেন্ট নিৰ্ব্বাচন কৰক",
      "Events Reported" : "ইভেন্টসমূহৰ ৰিপ'ৰ্ট",
      "Vidhan Sabha Covered" : "বিধান সভা আবৃত",
      "VIDHAN SABHA COVERED" : "বিধান সভা আবৃত",
      "Form Filled Booths" : "ফৰ্ম ভৰ্তি বুথ",
      "FORM FILLED BOOTHS" : "ফৰ্ম ভৰ্তি বুথ",
      "Events Reported Booths" : "ইভেন্ট ৰিপৰ্ট কৰা বুথ",
      "Reported Booths" : "ৰিপৰ্ট কৰা বুথ",
      "Total Booths" : "মুঠ বুথ",
      "Zila" : "জিলা",
      "Zila Wise Reports" : "জিলা ভিত্তিক ৰিপৰ্টছ",
      "Vidhan Sabha Wise Reports" : "বিধান সভা  ভিত্তিক ৰিপৰ্ট",
      "Booth Wise Reports" : "বুথ ভিত্তিক ৰিপৰ্ট",
      "Reportee Name & No" : "ৰিপ'ৰ্টীৰ নাম আৰু নং",
      "Remark" : "টোকা",
      "No Reports Available" : "কোনো ৰিপৰ্ট উপলব্ধ নাই",
      "Sort By" : "শৃখলাবদ্ধ",
      "High to Low" : "উচ্চৰ পৰা নিম্নলৈ",
      "Low to High" : "নিম্নৰ পৰা উচ্চলৈ",
      "Please select an event" : "অনুগ্ৰহ কৰি এটা অনুষ্ঠান নিৰ্বাচন কৰক",
      "Total Event Reports" : "মুঠ কাৰ্যসূচী ৰিপৰ্ট",
      "OVERALL" : "মুঠ"
    }
},
  od:{
    translation:{
      "Bharatiya Janata Party" : "ଭାରତୀୟ ଜନତା ପାର୍ଟି",
      "Reported Program" : "ରିପୋର୍ଟ ହୋଇଥିବା ପ୍ରୋଗ୍ରାମ୍ ",
      "Profile" : "ପ୍ରୋଫାଇଲ୍",
      "Login" : "ଭିତରକୁ ଯାଆନ୍ତୁ",
      "Logout" : "ପ୍ରସ୍ଥାନ କରନ୍ତୁ",
      "Mobile Number" : "ମୋବାଇଲ୍ ନମ୍ବର",
      "Send OTP" : "ଓଟିପି ପଠାନ୍ତୁ ",
      "Verify Details" : "ବିବରଣୀ ଯାଞ୍ଚ କରନ୍ତୁ ",
      "Enter OTP sent to" : "ପଠାଯାଇଥିବା ଓଟିପି ପ୍ରବେଶ କରନ୍ତୁ ",
      "seconds left in Resend OTP" : "ଓଟିପି ପୁନର୍ବାର ପଠେଇବା ରେ ସେକେଣ୍ଡ୍ ବାକି ଅଛି ",
      "Otp Sent successfully" : "ଓଟିପି ସଫଳତାର ସହିତ ପଠାଇଦିଯାଇଛି",
      "Login successfully" : "ସଫଳତାର ସହିତ ଲଗଇନ୍ ହୋଇଗଲା",
      "Invalid OTP" : "ଅବୈଧ ଓଟିପି |",
      "Phone Required" : "ଫୋନ୍ ଆବଶ୍ୟକ",
      "Programs" : "ପ୍ରୋଗ୍ରାମ୍",
      "Aired on" : "ପ୍ରସାରଣ କରାଯାଇଥିଲା",
      "Report" : "ରିପୋର୍ଟ",
      "Event not reported" : "ଇଭେଣ୍ଟ ରିପୋର୍ଟ ହୋଇନାହିଁ |",
      "Click here to know more" : "ଅଧିକ ଜାଣିବାକୁ ଏଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
      "Edit Profile" : "ପ୍ରୋଫାଇଲ୍ ଏଡ଼ିଟ୍ କରନ୍ତୁ",
      "Phone Number" : "ଫୋନ୍ ନମ୍ବର",
      "Name" : "ନାମ",
      "Name required" : "ନାମ ଆବଶ୍ୟକ",
      "Select profile photo" : "ପ୍ରୋଫାଇଲ୍ ଫଟୋ ଚୟନ କରନ୍ତୁ |",
      "State" : "ରାଜ୍ୟ",
      "State required" : "ରାଜ୍ୟ ଆବଶ୍ୟକ |",
      "Vidhan Sabha required" : "ବିଧାନ ସଭା ଆବଶ୍ୟକ |",
      "Vidhan Sabha" : "ବିଧାନ ସଭା",
      "Party Zila" : "ପାର୍ଟି ଜିଲା ",
      "Party Zila required" : "ପାର୍ଟି ଜିଲା ଆବଶ୍ୟକ ",
      "Party Mandal required" : "ପାର୍ଟି ମଣ୍ଡଳ ଆବଶ୍ୟକ ",
      "Phone required" : "ଫୋନ୍ ଆବଶ୍ୟକ ",
      "Booth required" : "ବୁଥ୍ ଆବଶ୍ୟକ ",
      "Mandal" : "ମଣ୍ଡଳ",
      "Booth" : "ବୁଥ",
      "WhatsApp Number" : "ୱାଟସାପ ନମ୍ବର |",
      "Education" : "ଶିକ୍ଷା",
      "Profession" : "ବୃତ୍ତି",
      "Voter ID" : "ଭୋଟର ଆଇଡି",
      "Pincode" : "ପିନକୋଡ୍",
      "File can't be upload please select file upto 1 MB" : "ଫାଇଲ୍ ଅପଲୋଡ୍ ହୋଇପାରିବ ନାହିଁ ଦୟାକରି ୧ MB ପର୍ଯ୍ୟନ୍ତ ଫାଇଲ୍ ଚୟନ କରନ୍ତୁ |",
      "File should be JPG or PNG format" : "ଫାଇଲ୍ ଯେପିଜି କିମ୍ବା ପିନ୍ଜି ଫର୍ମାଟ୍ ହେବା ଉଚିତ |",
      "Save" : "ସଞ୍ଚୟ କରନ୍ତୁ |",
      "Event Reported Successfully" : "ଇଭେଣ୍ଟ ସଫଳତାର ସହିତ ରିପୋର୍ଟ କରାଯାଇଛି |",
      "User Updated Successfully" : "ଉପଭୋକ୍ତାର ବିବରଣୀ ସଫଳତାର ସହିତ ଅପଡେଟ୍ ହୋଇଯାଇଛି |",
      "Enter Details" : "ବିବରଣୀ ପୂରଣ କରନ୍ତୁ |",
      "Total Attendees" : "ସମୁଦାୟ ଉପସ୍ଥିତ",
      "TOTAL ATTENDEES" : "ସମୁଦାୟ ଉପସ୍ଥିତ",
      "Remarks" : "ଟିପ୍ପଣୀ",
      "Upload Image" : "ପ୍ରତିଛବି ଅପଲୋଡ୍ କରନ୍ତୁ |",
      "Capture Image" : "ଚିତ୍ର ଉତ୍ତୋଳନ କରନ୍ତୁ |",
      "Capture" : "ଉତ୍ତୋଳନ କରନ୍ତୁ",
      "Switch" : "ପରିବର୍ତ୍ତନ କରନ୍ତୁ",
      "Close" : "ବନ୍ଦ କରନ୍ତୁ",
      "Review" : "ସମୀକ୍ଷା",
      "Please fill all * mandatory fields" : "ଦୟାକରି ସମସ୍ତ * ବାଧ୍ୟତାମୂଳକ କ୍ଷେତ୍ରଗୁଡିକ ପୁରଣ କରନ୍ତୁ |",
      "Upload minimum one image" :  "ସର୍ବନିମ୍ନ ଗୋଟିଏ ପ୍ରତିଛବି ଅପଲୋଡ୍ କରନ୍ତୁ |",
      "Total Attendees required" : "ସମୁଦାୟ ଉପସ୍ଥାପନା ଆବଶ୍ୟକ ଅଟେ |",
      "Enter Your Full Name" : "ତୁମର ସମ୍ପୂର୍ଣ୍ଣ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
      "Select State" : "ରାଜ୍ୟ ଚୟନ କରନ୍ତୁ |",
      "Next" : "ପରବର୍ତ୍ତୀ",
      "Program Reported Successfully" : "ପ୍ରୋଗ୍ରାମ୍ ସଫଳତାର ସହିତ ରିପୋର୍ଟ କରାଯାଇଛି |",
      "Yay! Keep doing the good work!" : "ବାଃ! ଭଲ କାମ ଜାରି ରଖ!",
      "Address" : "ଠିକଣା",
      "Images" : "ପ୍ରତିଛବିଗୁଡିକ",
      "Submit" : "ଦାଖଲ କର |",
      "Upcoming Event" : "ଆଗାମୀ ଇଭେଣ୍ଟ",
      "Booth in Shakti Kendra" : "ଶକ୍ତିକେନ୍ଦ୍ର ରେ ବୁଥ |",
      "Shakti kendra in AC" : " ବିଧାନସଭା ଅଧୀନରେ ଶକ୍ତି କେନ୍ଦ୍ର |",
      "Shakti kendra required" : "ଶକ୍ତିକେନ୍ଦ୍ର ଆବଶ୍ୟକ |",
      "Other Booth in AC" :  "ବିଧାନସଭା ରେ ଅନ୍ୟ ବୁଥ୍",
      "Address (where event is organised)" : "ଠିକଣା (ଯେଉଁଠାରେ କାର୍ୟକର୍ମ ଆୟୋଜନ କରାଯାଇଥିଲା)",
      "Phone number not valid and should be 10 digit" : "ଫୋନ୍ ନମ୍ବର ବୈଧ ନୁହେଁ ଏବଂ ୧୦ ଅଙ୍କ ହେବା ଉଚିତ |",
      "Invalid Phone Number" : "ଅବୈଧ ଫୋନ୍ ନମ୍ବର |",
      "Logout Successfully" : "ସଫଳତାର ସହିତ ଲଗଆଉଟ୍ ହୋଇଯାଇଛି |",
      "Didn’t recieve OTP" : "ଓଟିପି ପ୍ରାପ୍ତ ହୋଇନାହିଁ |",
      "Resend" : "ପୁନର୍ବାର ପଠାନ୍ତୁ",
      "Program" : "ପ୍ରୋଗ୍ରାମ୍",
      "Update" : "ଅଦ୍ୟତନ କରନ୍ତୁ |",
      "Start reporting on : " : "ରିପୋର୍ଟ କରିବା ଆରମ୍ଭ କରନ୍ତୁ:",
      "You can only post after the program starts. " : "ପ୍ରୋଗ୍ରାମ ଆରମ୍ଭ ହେବା ପରେ ଆପଣ କେବଳ ପୋଷ୍ଟ କରିପାରିବେ |",
      "No Programs" : "କୋଉଣସି ପ୍ରୋଗ୍ରାମ୍ ନାହିଁ |",
      "Please enter a valid Name" : "ଦୟାକରି ଏକ ବୈଧ ନାମ ପୂରଣ କରନ୍ତୁ |",
      "Event Address" :  "କାର୍ୟକର୍ମ ଠିକଣା",
      "Image (Max 2)" : "ପ୍ରତିଛବି (ସର୍ବାଧିକ ୨)",
      "Geolocation not supported" : "ଜିଓଲୋକେସନ୍ ସମର୍ଥିତ ନୁହେଁ |",
      "Geolocation not found" : "ଜିଓଲୋକେସନ୍ ମିଳିଲା ନାହିଁ |",
      "Permission denied" : "ଗ୍ରହଣ କରାଗଲା ନାହି",
      "(Go to setting >> Site settings >>" : "(ସେଟିଂକୁ ଯାଆନ୍ତୁ >> ସାଇଟ୍ ସେଟିଂ>>  |",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "ଅବସ୍ଥାନ >> ମନ କି ବାତ୍ >> ଅବସ୍ଥାନକ ପାଇଁ ଅନୁମତି ଦିଅନ୍ତୁ)",
      "(Go to setting >> Privacy and security >> Site settings >>" : "(ସେଟିଂକୁ ଯାଆନ୍ତୁ  >> ଗୋପନୀୟତା ଏବଂ ସୁରକ୍ଷା >> ସାଇଟ୍ ସେଟିଂ>>",
      "Shakti Kendra" : "ଶକ୍ତି କେନ୍ଦ୍ର |",
      "OR" : "କିମ୍ବା",
      "Report Again" : "ପୁନର୍ବାର ରିପୋର୍ଟ କରନ୍ତୁ |",
      "File size exceeds limit! Please select a file upto 10 MB" : "ଫାଇଲ୍ ଆକାର ସୀମା ଅତିକ୍ରମ କରିଛି! ଦୟାକରି 10MB ପର୍ଯ୍ୟନ୍ତ ଏକ ଫାଇଲ୍ ଚୟନ କରନ୍ତୁ ",
      "View Less" : "ଅଳ୍ପ ଦେଖନ୍ତୁ |",
      "View More" : "ଅଧିକ ଦେଖନ୍ତୁ |",
      "Reports" : "ରିପୋର୍ଟଗୁଡିକ  |",
      "Overview" : "ସମୀକ୍ଷା  |",
      "Select Event" : "ଘଟଣା ଚୟନ କରନ୍ତୁ |",
      "Events Reported" : "ଘଟଣା ରିପୋର୍ଟ ହୋଇଛି |",
      "Vidhan Sabha Covered" : "ବିଧାନସଭା ଆଚ୍ଛାଦିତ |",
      "VIDHAN SABHA COVERED" : "ବିଧାନସଭା ଆଚ୍ଛାଦିତ |",
      "Form Filled Booths" : "ଫର୍ମ ପୂର୍ଣ୍ଣ ବୁଥଗୁଡିକ |",
      "FORM FILLED BOOTHS" : "ଫର୍ମ ପୂର୍ଣ୍ଣ ବୁଥଗୁଡିକ |",
      "Events Reported Booths" : "ଘଟଣା ରିପୋର୍ଟ ହୋଇଥିବା ବୁଥ୍ |",
      "Reported Booths" : "ରିପୋର୍ଟ ହୋଇଥିବା ବୁଥ୍ |",
      "Total Booths" : "ସମୁଦାୟ ବୁଥ୍ |",
      "Zila" : "ଜିଲ୍ଲା  |",
      "Zila Wise Reports" : "ଜିଲ୍ଲା ସ୍ତର ରିପୋର୍ଟ  |",
      "Vidhan Sabha Wise Reports" : "ବିଧାନସଭା ସ୍ତର ରିପୋର୍ଟ  |",
      "Booth Wise Reports" : "ବୁଥ୍  ସ୍ତର  ରିପୋର୍ଟଗୁଡିକ |",
      "Reportee Name & No" : "ରିପୋର୍ଟର ନାମ ଏବଂ ସଂଖ୍ୟା",
      "Remark" : "ଟିପ୍ପଣୀ |",
      "No Reports Available" : " ରିପୋର୍ଟ ଉପଲବ୍ଧ ନାହିଁ |",
      "Sort By" : "କ୍ରମବ‌‌ଦ୍ଧ",
      "High to Low" : "ଉଚ୍ଚରୁ ନିମ୍ନ |",
      "Low to High" : "ନିମ୍ନରୁ ଉଚ୍ଚ  |",
      "Please select an event" : "ଦୟାକରି ଏକ ଇଭେଣ୍ଟ ଚୟନ କରନ୍ତୁ |",
      "Total Event Reports" : "ମୋଟ ଇଭେଣ୍ଟ ରିପୋର୍ଟଗୁଡିକ |",
      "OVERALL" : "ମୋଟ ଉପରେ |"
    }
},
  tl:{
    translation:{
      "Bharatiya Janata Party" : "భారతీయ జనతా పార్టీ",
      "Reported Program" : "నివేదించబడిన ప్రోగ్రామ్",
      "Profile" : "ప్రొఫైల్",
      "Login" : "లాగిన్",
      "Logout" : "లాగ్అవుట్",
      "Mobile Number" : "మొబైల్ నంబర్",
      "Send OTP" : "otp పంపండి",
      "Verify Details" : "వివరాలను ధృవీకరించండి",
      "seconds left in Resend OTP" : "otpని మళ్లీ పంపడానికి మిగిలి ఉన్నాసెకన్లు",
      "Otp Sent successfully" : "Otp విజయవంతంగా పంపబడింది",
      "Login successfully" : "లాగిన్ విజయవంతమైంది",
      "Invalid OTP" : "చెల్లని otp",
      "Phone Required" : "ఫోన్ అవసరం",
      "Programs" : "కార్యక్రమాలు",
      "Aired on" : "ప్రసారం చేయబడింది",
      "Report" : "నివేదిక",
      "Event not reported" : "సంఘటన నివేదించబడలేదు",
      "Click here to know more" : "మరింత తెలుసుకోవడానికి ఇక్కడ క్లిక్ చేయండి",
      "Edit Profile" : "ప్రొఫైల్‌ని సవరించండి",
      "Phone Number" : "ఫోను నంబరు",
      "Name" : "పేరు",
      "Name required" : "పేరు అవసరం",
      "Select profile photo" : "ప్రొఫైల్ ఫోటోను ఎంచుకోండి",
      "State" : "రాష్ట్రం",
      "State required" : "రాష్ట్రం అవసరం",
      "Vidhan Sabha required" : "విధానసభ అవసరం",
      "Vidhan Sabha" : "విధానసభ",
      "Party Zila" : "పార్టీ జిలా",
      "Party Zila required" : "పార్టీ జిలా అవసరం",
      "Party Mandal required" : "పార్టీ మండలం అవసరం",
      "Phone required" : "ఫోన్ అవసరం",
      "Booth required" : "బూత్ అవసరం",
      "Mandal" : "మండలం",
      "Booth" : "బూత్",
      "WhatsApp Number" : "వాట్సాప్ నెంబర్ ",
      "Education" : "చదువు",
      "Profession" : "వృత్తి",
      "Voter ID" : "ఓటరు ఐడి",
      "Pincode" : "పిన్ కోడ్",
      "File can't be upload please select file upto 1 MB" : "ఫైల్‌ని అప్‌లోడ్ చేయడం సాధ్యం కాదు, దయచేసి 1 MB వరకు ఫైల్‌ని ఎంచుకోండి",
      "File should be JPG or PNG format" : "ఫైల్ తప్పనిసరిగా JPG లేదా PNG ఆకృతిలో ఉండాలి",
      "Save" : "సేవ్ చేయండి",
      "Event Reported Successfully" : "ఈవెంట్ విజయవంతంగా నివేదించబడింది",
      "User Updated Successfully" : "వినియోగదారు విజయవంతంగా నవీకరించబడ్డారు",
      "Enter Details" : "వివరాలను నమోదు చేయండి",
      "Total Attendees" : "మొత్తం హాజరైనవారు",
      "TOTAL ATTENDEES" : "మొత్తం హాజరైనవారు",
      "Remarks" : "వ్యాఖ్యలు",
      "Upload Image" : "చిత్రాన్ని అప్‌లోడ్ చేయండి",
      "Capture Image" : "చిత్రాన్ని క్యాప్చర్ చేయండి",
      "Capture" : "సంగ్రహించు",
      "Switch" : "మారండి",
      "Close" : "మూసివేయండి",
      "Review" : "సమీక్ష",
      "Please fill all * mandatory fields" : "దయచేసి అన్ని * తప్పనిసరి ఫీల్డ్‌లను పూరించండి",
      "Upload minimum one image" :  "కనీసం ఒక చిత్రం అప్‌లోడ్",
      "Total Attendees required" : "మొత్తం హాజరు కావాలి",
      "Enter Your Full Name" : "మీ పూర్తి పేరును నమోదు చేయండి",
      "Select State" : "రాష్ట్రం ఎంచుకోండి",
      "Next" : "తరువాత",
      "Program Reported Successfully" : "ప్రోగ్రామ్ విజయవంతంగా నివేదించబడింది",
      "Yay! Keep doing the good work!" : "అవును! మంచి పని చేస్తూ ఉండండి!",
      "Address" : "చిరునామా",
      "Images" : "చిత్రాలు",
      "Submit" : "సమర్పించండి",
      "Upcoming Event" : "రాబోయే ఈవెంట్",
      "Booth in Shakti Kendra" : "శక్తి కేంద్రంలో బూత్",
      "Shakti kendra in AC" : "అసెంబ్లీ నియోజకవర్గంలో శక్తి కేంద్రం",
      "Shakti kendra required" : "శక్తి కేంద్రం అవసరం",
      "Other Booth in AC" :  "విధానసభలోని ఇతర బూత్",
      "Address (where event is organised)" : "చిరునామా (ఈవెంట్ నిర్వహించబడిన ప్రదేశం)",
      "Phone number not valid and should be 10 digit" : "ఫోన్ నంబర్ చెల్లదు మరియు 10 అంకెలు ఉండాలి",
      "Invalid Phone Number" : "చెల్లని ఫోన్ నంబర్",
      "Logout Successfully" : "లాగ్ అవుట్ విజయవంతమైంది",
      "Enter OTP sent to" : "పంపిన ఓటీపీని నమోదు చేయండి",
      "Didn’t recieve OTP" : "OTPని అందుకోలేదు",
      "Resend" : "మళ్లీ పంపండి",
      "Program" : "కార్యక్రమం",
      "Update" : "అప్డేట్ చేయండి",
      "Start reporting on : " : "నివేదించడం ప్రారంభించండి",
      "You can only post after the program starts. " : "ప్రోగ్రామ్ ప్రారంభమైన తర్వాత మాత్రమే మీరు పోస్ట్ చేయవచ్చు.",
      "No Programs" : "ప్రోగ్రామ్‌లు లేవు",
      "Please enter a valid Name" : "దయచేసి చెల్లుబాటు అయ్యే పేరును నమోదు చేయండి",
      "Event Address" :  "ఈవెంట్ చిరునామా",
      "Image (Max 2)" : "చిత్రం (గరిష్టంగా 2)",
      "Geolocation not supported" : "జియోలొకేషన్‌కు మద్దతు లేదు",
      "Geolocation not found" : "జియోలొకేషన్ కనుగొనబడలేదు",
      "Permission denied" : "అనుమతి నిరాకరించబడింది",
      "(Go to setting >> Site settings >>" : "(సెట్టింగ్  కి వెళ్లండి >> సైట్ సెట్టింగ్‌లు >>",
      "Locations >> mannkibaatprogram >> Allow access to location)" : "స్థానాలు >> మన్ కీ బాత్ ప్రోగ్రామ్ >> స్థానానికి ప్రాప్యతను అనుమతించు)",
      "(Go to setting >> Privacy and security >> Site settings >>" : "(సెట్టింగ్ కి వెళ్లండి >> గోప్యత మరియు భద్రత >> సైట్ సెట్టింగ్‌లు >>",
      "Shakti Kendra" : "శక్తి కేంద్రం",
      "OR" : "లేదా",
      "Report Again" : "మళ్ళీ నివేదించండి",
      "File size exceeds limit! Please select a file upto 10 MB" : "ఫైల్ పరిమాణం పరిమితిని మించిపోయింది! దయచేసి 10MB వరకు ఫైల్‌ని ఎంచుకోండి",
      "View Less" : "తక్కువ చూడండి",
      "View More" : "మరిన్ని చూడండి",
      "Reports" : "నివేదికలు",
      "Overview" : "అవలోకనం",
      "Select Event" : "సంఘటనని ఎంచుకోండి",
      "Events Reported" : "సంఘటనలు నివేదించబడ్డాయి",
      "Vidhan Sabha Covered" : "కవర్ చేయబడిన విధానసభ",
      "VIDHAN SABHA COVERED" : "కవర్ చేయబడిన విధానసభ",
      "Form Filled Booths" : "ఫారం నింపిన బూత్‌లు",
      "FORM FILLED BOOTHS" : "ఫారం నింపిన బూత్‌లు",
      "Events Reported Booths" : "సంఘటనలు నివేదించబడిన బూత్‌లు",
      "Reported Booths" : "నివేదించబడిన బూత్‌లు",
      "Total Booths" : "మొత్తం బూత్‌లు",
      "Zila" : "జిల్లా ",
      "Zila Wise Reports" : "జిల్లా వారి రిపోర్ట్స్",
      "Vidhan Sabha Wise Reports" : "విధానసభల వారీగా నివేదికలు",
      "Booth Wise Reports" : "బూత్ వారీగా నివేదికలు",
      "Reportee Name & No" : "రిపోర్టీ పేరు & సంఖ్య",
      "Remark" : "వ్యాఖ్య",
      "No Reports Available" : "నివేదికలు ఏవీ అందుబాటులో లేవు",
      "Sort By" : "ఆమరిక",
      "High to Low" : "ఎక్కువ నుండి తక్కువ వరకు",
      "Low to High" : "తక్కువ నుండి ఎక్కువ వరకు",
      "Please select an event" : "దయచేసి సంఘటనని ఎంచుకోండి",
      "Total Event Reports" : "మొత్తం ఈవెంట్ నివేదికలు",
      "OVERALL" : "మొత్తం"
    }
}
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('defaultLang') ? localStorage.getItem('defaultLang') : 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;