import React, {useContext, useEffect, useState} from 'react';
import './report-zila.css';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {AUTH_TOKEN, LEVELNAME} from "../SharingConstants";
import Skeleton from "@mui/material/Skeleton";
import rectangle from "../../../../public/images/rectangle-tab.svg";
import {useTranslation} from "react-i18next";
import {createFilterOptions, TextField} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import {Tooltip} from "antd";
import {toast} from "react-toastify";
import {CameratoggleContext} from "../../context/cameratoggle-context";
import api from "../api";

const ReportZila = ({eventId}) => {
    const [shimmerEffect, setShimmerEffect] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState("");
    const [zilaList,setZilaList] = useState([]);
    const [isEventBlank, setIsEventBlank] = useState(false);
    const {t} = useTranslation();
    const [sortBy, setSortBy] = useState('High to Low');
    const [overAllData, setOverAllData] = useState("");
    const {userLevel, setUserLevel} = useContext(CameratoggleContext);

    useEffect(key => {
        if (userLevel === "Zila") {
            getZilaData(eventId)
        } else {
            getStates();
        }
    }, []);

    const getStates = async () => {
        setShimmerEffect(true);
        try {
            let states = await api.get('api/report/allotted_states', {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await states.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setStateList(res?.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };


    const getZilaData = async (eventId,state_id) => {
        setShimmerEffect(true);
        try {
            let event = ((eventId === undefined) ? "" : eventId);
            let country_state_id = ((state_id === undefined) ? "" : state_id);
            let zilaData = await api.get("api/report/zila_ac_booth_wise_stats?event_id=" + event + "&state_id=" + country_state_id, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await zilaData.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setOverAllData(res.total_data);
            if (sortBy === '') {
                setZilaList(res.data);
            } else {
                setZilaList(res.data);
                sortZilaData(sortBy,res.data);
            }
            if (res.data.length == 0) {
                setIsEventBlank(true);
            } else {
                setIsEventBlank(false);
            }
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };

    const handleSetState = (event,value) => {
        if (value === null) {
            setState("");
            setZilaList([]);
            setIsEventBlank(false);
        } else {
            setState(value);
            getZilaData(eventId,value.id);
        }
    };

    const showShimmer = () => {
        return (<Box spacing={1}>
            <br/>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={300} style={{backgroundColor: '#ECECEC'}}/>
        </Box>);
    };

    const handleSortBy = (event) => {
        if (zilaList === []) {
            setSortBy(event.target.value);
        } else {
            setSortBy(event.target.value);
            sortZilaData(event.target.value,zilaList);
        }
    }

    const sortZilaData = (sortBy, data) => {
        if (sortBy === "High to Low") {
            data.sort((p1, p2) => (p1.ff_booths_count < p2.ff_booths_count) ? 1 : -1);
        } else if (sortBy === "Low to High") {
            data.sort((p1, p2) => (p1.ff_booths_count > p2.ff_booths_count) ? 1 : -1);
        } else if (sortBy === "A-Z") {
            data.sort((p1, p2) => (p1.zila_name > p2.zila_name) ? 1 : -1);
        }
    }

    return (
        <div>
            {shimmerEffect ? showShimmer() : <>
                {userLevel === "Zila" ? <></> : <>
                        <Autocomplete
                            className="select-event-dropdown autocomplete"
                            id="combo-box-demo"
                            options={stateList}
                            value={state}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionValue = {(option) => option.id || ""}
                            filterOptions={createFilterOptions({
                                stringify: (option) => option.id + option.name
                            })}
                            onChange={handleSetState}
                            renderInput={(params) => <TextField {...params} label={t('State')} InputLabelProps={{
                                style: {
                                    color: 'black'
                                }
                            }}/>}
                        />
                </>}
                <br/>

                <div className="zila-reports-container">
                    <div className="zila-report-header">
                        <div className="report-title">
                            <img className="rectangle-tab" src={rectangle} alt=""/>
                            <span className='report-zila-text'>{t('Zila Wise Reports')}</span>
                        </div>

                        <FormControl >
                            <InputLabel id="demo-simple-select-label">{t('Sort By')}</InputLabel>
                            <Select
                                className='sort-by-select'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sortBy}
                                label={t('Sort By')}
                                style={{outline: '1px solid #CCCCCC;', borderRadius: '6px', height: '30px'}}
                                onChange={handleSortBy}>
                                <MenuItem value={'High to Low'}>{t('High to Low')}</MenuItem>
                                <MenuItem value={'Low to High'}>{t('Low to High')}</MenuItem>
                                <MenuItem value={'A-Z'}>A-Z</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <span className='zila-reports-details'>
                        {zilaList.length? <>
                                <div className="overall-count-container">
                                    <div className='overall-count'>
                                        <span className='overall-text'>{t('OVERALL')}</span>
                                        <Tooltip title={overAllData?.total_acs.toLocaleString('en-IN')} placement="top"><b>{overAllData.formatted_total_acs}</b></Tooltip>
                                    </div>
                                    <div className="vertical-line-zila"></div>
                                    <div className='overall-count'>
                                        <span className='overall-text'>{t('OVERALL')}</span>
                                        <Tooltip title={overAllData?.total_booths.toLocaleString('en-IN')} placement="top">
                                            <b>{overAllData.formatted_total_booths}</b>
                                        </Tooltip>
                                    </div>
                                    <div className="vertical-line-zila"></div>
                                    <div className='overall-count'>
                                        <span className='overall-text'>{t('OVERALL')}</span>
                                        <Tooltip title={overAllData?.submission_total_attendees.toLocaleString('en-IN')} placement="top">
                                            <b>{overAllData.formatted_submission_total_attendees}</b>
                                        </Tooltip>
                                    </div>
                                </div>
                                {zilaList?.map((zila,index) => (
                                <>
                                    <div className='report-zila-name-index'><p>{index +1}.&nbsp;</p><b>{zila.zila_name}</b></div>
                                    <div className='report-zila-vidhan-booths-total'>
                                        <div className='report-zila-vidhan'>
                                            <span className='report-zila-vidhan-booths-total-text'>{t('VIDHAN SABHA COVERED')}</span>
                                            <b className='report-zila-vidhan-booths-total-count'>{zila.ac_covered.toLocaleString('en-IN')}</b>
                                        </div>
                                        <div className='report-zila-vidhan'>
                                            <span className='report-zila-vidhan-booths-total-text'>{t('FORM FILLED BOOTHS')}</span>
                                            <b className='report-zila-vidhan-booths-total-count'>{zila.ff_booths_count.toLocaleString('en-IN')}</b>
                                        </div>
                                        <div className='report-zila-vidhan'>
                                            <span className='report-zila-vidhan-booths-total-text'>{t('TOTAL ATTENDEES')}</span>
                                            <b className='report-zila-vidhan-booths-total-count'>{zila.total_attendees === null ? '0' : zila.total_attendees.toLocaleString('en-IN')}</b>
                                        </div>
                                    </div>
                                    <hr style={{color: '#A9A9A9'}}/>
                                </>
                            ))}
                            </> : <div className="blank-report">{isEventBlank? <>{t('No Reports Available')}</> : null}</div>
                        }
                    </span>
                </div>
            </>}
        </div>);
    };
export default ReportZila;