import React, {useContext, useEffect} from 'react';
import './home.css';
import {AUTH_STATUS} from "../SharingConstants";
import Program from "../program/Program";
import Login from "../login/Login";
import 'antd/dist/antd.css';
import {CameratoggleContext} from "../../context/cameratoggle-context";
import {useTranslation} from "react-i18next";
export default function Home() {
    const {showLogin, setShowLogin} = useContext(CameratoggleContext);
    const { i18n } = useTranslation();
    useEffect(() => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const identification_token = url.searchParams.get("token");
        if (identification_token) {
            setShowLogin(false);
            const host_url = localStorage.getItem('host_url');
            const backdoor_otp = localStorage.getItem('backdoor_otp');
            localStorage.clear();
            localStorage.setItem('backdoor_otp', backdoor_otp);
            localStorage.setItem('host_url', host_url);
            i18n.changeLanguage('en');
        } else {
            setShowLogin(true);
        }
    }, [])
    return (
        <div>
            {JSON.parse(localStorage.getItem(AUTH_STATUS)) ? (<> <Program/> </>) : (<>
                <Login/>
            </>)}
        </div>
    )
}