import React from 'react';
import {useTimer} from 'react-timer-hook';
import './timer.css';
import {useTranslation} from "react-i18next";

function MyTimer({expiryTimestamp}) {
    const { t } = useTranslation();
    const {
        seconds,
        minutes,
        isRunning,
        start
    } = useTimer({expiryTimestamp, onExpire: () => console.warn('onExpire called')});

    return (
        <div onLoad={start} className='timerText'>
            <p><span>{minutes}</span>:<span>{seconds}</span>{t('seconds left in Resend OTP')}</p>
        </div>
    );
}

export default function Timer() {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 59); // 1 minute timer
    return (
        <div>
            <MyTimer expiryTimestamp={time}/>
        </div>
    );
}