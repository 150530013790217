import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import './login.css';
import * as Constant from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ACID, AUTH_STATUS, STATEID, USERNAME} from "../SharingConstants";
import Loader from "react-js-loader";
import Timer from "../timer/Timer";
import { useTranslation } from 'react-i18next';
import {phonePattern} from "../../util/util"
import {CameratoggleContext} from "../../context/cameratoggle-context";
toast.configure()

export default function Login() {
    const navigate = useNavigate();
    const {handleSubmit} = useForm();
    const [loaderEnable, setloaderEnable] = useState(false);
    const [showResend, setshowResend] = useState(false);
    const [timerEnable, setTimerEnable] = useState(false);
    const [otpEnable, setOTPEnable] = useState(false);
    const [phoneNumber, setphoneNumber] = useState('');
    const [phoneNumberError, setphoneNumberError] = useState('');
    const [otpNo, setOtpNo] = useState('');
    const { t } = useTranslation();
    const {showLogin, setShowLogin} = useContext(CameratoggleContext);
    const {userLevel, setUserLevel} = useContext(CameratoggleContext);

    const onSubmit = () => {
        if (!phoneNumber) {
            toast.dismiss()
            toast(t('Phone Required'))
            return
        }
        if (!phoneNumberError) {
            !otpEnable ? requestOTP(phoneNumber) : login(otpNo)
        }
    };

    function resendOtp(phone){
        if (otpEnable) {
            setTimerEnable(false);
            setshowResend(false);
            requestOTP(phone)
        }
    }

    async function requestOTP(data, token='') {
        setloaderEnable(true);
        let userNumber = data;
        let body = {phone_number: userNumber, token: token}
        let result = await fetch("api/auth/sessions/send_otp", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json'
            },
            body: JSON.stringify(body)
        });
        result = await result.json();
        { result.status && setOTPEnable(true) };
        setloaderEnable(false);
        setTimerEnable(true);
        toast(t(result.message))
        localStorage.setItem('userNumber', userNumber);
        localStorage.setItem('token', result.data.identification_token);
        setTimerEnable(true);
        setTimeout(() => {
            setshowResend(true);
        }, 60000)
        if (token.length) {
            login(localStorage.getItem('backdoor_otp'), token)
        }
    }
    function byPassNavigation() {
        if(localStorage.getItem(USERNAME) !== 'null' && localStorage.getItem(STATEID) !== '' && localStorage.getItem(ACID) !== '') {
            setTimeout(() => {
                navigate('/program', { replace: true });
            }, 10);
        } else {
            setTimeout(() => {
                navigate('/user-detail', { replace: true });
            }, 10);
        }
    }

    async function login(data, i_token= '') {
        setloaderEnable(true);
        let otp = data;
        const token = document.querySelector('meta[name="csrf-token"]').content;
        let identification_token = localStorage.getItem('token');
        let body = {otp: otp, identification_token: identification_token}
        let result = await fetch("api/auth/sessions/submit_otp", {
            method: 'POST',
            headers: {
                "X-CSRF-Token": token,
                "Content-Type": "application/json",
                "Accept": 'application/json'
            },
            body: JSON.stringify(body)
        });
        result = await result.json();
        toast.dismiss()
        if (i_token) {
            setShowLogin(true);
        }
        toast(t(result.message))
        if (result.status === true) {
            loginSuccessful(result);
        }
        setloaderEnable(false);
    }

    function loginSuccessful(loginData) {
        localStorage.setItem(Constant.AUTH_STATUS, loginData.data.auth_status);
        localStorage.setItem(Constant.AUTH_TOKEN, loginData.data.auth_token);
        localStorage.setItem(Constant.USERID, loginData.data.id);
        localStorage.setItem(Constant.USERNAME, loginData.data.name ? loginData.data.name : '' );
        localStorage.setItem(Constant.USERIMAGE, loginData.data.photo);
        localStorage.setItem(Constant.USERPHONE, loginData.data.phone);
        localStorage.setItem(Constant.STATENAME, loginData.data.country_state.length ? loginData.data.country_state[0].name : '');
        localStorage.setItem(Constant.STATEID, loginData.data.country_state.length ? loginData.data.country_state[0].id : '');
        localStorage.setItem(Constant.ACNAME, loginData.data.ac.length ? loginData.data.ac[0].name : '');
        localStorage.setItem(Constant.ACID, loginData.data.ac.length ? loginData.data.ac[0].id : '');
        localStorage.setItem(Constant.LEVELNAME, loginData.data.user_level.length ? loginData.data.user_level : '');
        setUserLevel(loginData.data.user_level.length ? loginData.data.user_level : "");
        byPassNavigation()
    }

    function allowedNumericOtp(event) {
        if (event.target.value.length >= 6) {event.preventDefault();}
        const charCode = (event.which) ? event.which : event.keyCode;
        if (!(charCode > 31 && (charCode < 48 || charCode > 57))) {
            setOtpNo(event.target.value);
        } else {
            event.preventDefault();
        }
    }
    function allowedNumericPhone(event) {
        if (event.target.value.length >= 10) {event.preventDefault();}
        const charCode = (event.which) ? event.which : event.keyCode;
        if (!(charCode > 31 && (charCode < 48 || charCode > 57))) {
            setphoneNumber(event.target.value);
        } else {
            event.preventDefault();
        }
    }
    function validatePhone(phone) {
        setphoneNumberError('');
        if (!phone) {
            setphoneNumber('');
            setphoneNumberError('Phone required')
        }
        if (phone.match(phonePattern) === null || phone.length < 10) {
            setphoneNumberError('Phone number not valid and should be 10 digit')
        }
    }
    useEffect(() => {
        const url_string = window.location.href;
        const url = new URL(url_string);
        const identification_token = url.searchParams.get("token");
        const phone_number = url.searchParams.get("phone_number");
        if (phone_number && (phone_number.match(phonePattern) === null || phone_number.length < 10)) {
            alert('Phone no is not valid');
            navigate('/', {replace: true});
            return
        }
        if (identification_token) {
            verifyToken(identification_token, phone_number)
        }
    }, []);

    async function verifyToken(token, phone) {
        var host_url = localStorage.getItem('host_url')
        let result = await fetch(host_url + "/zila/api/data/user_identification_verify?token=" + token, {
            method: 'GET'
        });
        result = await result.json();
        if (result['success']) {
            requestOTP(result.phone_number, token);
        } else {
            navigate('/', {replace: true});
            setShowLogin(true);
        }
    }
    return (
        <div className='loginContainer'>
            <div className='loginNameLabel'>
            </div>
            {loaderEnable ? (<> <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={t("Loading..")} color={'#FFFFFF'}
                                        size={100}/> </>) : (<> </>)}
            { showLogin ?
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div>
                        {!otpEnable ? (
                            <>
                                <p className='loginName'>{t('Login')}</p>
                                <p className="mobile-lebel">{t('Mobile Number')}*</p>
                                <div className='mobileNumberContainer'>
                                    <input
                                        className="login-field"
                                        name='phoneNumber'
                                        onChange={(e) => {
                                            setphoneNumber(e.target.value);
                                            validatePhone(e.target.value)
                                        }}
                                        type="tel"
                                        autoComplete="off"
                                        value={phoneNumber}
                                        maxLength="10"
                                        onKeyPress={(event) => allowedNumericPhone(event)}
                                    />
                                    <small className="error-msg">{t(phoneNumberError)}</small>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='verifiedHeader'>
                                    <p className='verifiedLabel'>{t('Verify Details')}</p>
                                    <p className='verifiedSubtext'>{localStorage.getItem('defaultLang') === 'hn' ? phoneNumber + t(' Enter OTP sent to') : t('Enter OTP sent to ') + phoneNumber}</p>
                                </div>
                                <div className='otpContainer'>
                                    <input
                                        className='login-field'
                                        name='otpNo'
                                        onChange={(e) => setOtpNo(e.target.value)}
                                        type="tel"
                                        value={otpNo}
                                        autoComplete="off"
                                        onKeyPress={(event) => allowedNumericOtp(event)}
                                    />
                                </div>
                                {timerEnable && !showResend ? (<> <Timer /> </>) : (<> </>)}
                            </>
                        )}
                    </div>
                    {showResend ? (<>
                        <div className="d-flex justify-content-center">
                            <span>{t('Didn’t recieve OTP')} ? </span>&nbsp;<button className="text-primary" onClick={() => { resendOtp(localStorage.getItem('userNumber'))}}>{t('Resend')}</button>
                        </div>
                    </>) : (<> </>)}
                    <div className="buttonContainer">
                        <button type='submit' className='button'>
                            {!otpEnable ? t('Send OTP') : t('Login')}
                        </button>
                    </div>
                </form>
                :
                <></>
            }
            </div>
    )
}