import React, {useEffect, useState} from 'react';
import './userDetail.css';
import arrowRight from '../../../../public/images/Arrow.svg';
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Loader from "react-js-loader";
import Select from "react-select";
import {USERNAME, ACNAME, ACID, STATEID, STATENAME, AUTH_TOKEN, AUTH_STATUS} from "../SharingConstants";
import {useTranslation} from "react-i18next";
import {namePattern} from "../../util/util";

export default function UserDetail() {
    const navigate = useNavigate();
    const {handleSubmit} = useForm();
    const [loaderEnable, setloaderEnable] = useState(false);
    const [userName, setUserName] = useState(localStorage.getItem(USERNAME) !== 'null' ? localStorage.getItem(USERNAME) : '');
    const [stateId, setStateId] = useState(localStorage.getItem(STATEID) !== 'null' ? localStorage.getItem(STATEID) : '');
    const [stateName, setStateName] = useState();
    const [stateList, setStateList] = useState([]);
    const [vidhanSabhaId, setVidhanSabhaId] = useState(localStorage.getItem(ACID) !== 'null' ? localStorage.getItem(ACID) : '');
    const [vidhanSabhaName, setVidhanSabhaName] = useState();
    const [vidhanSabhaList, setVidhanSabhaList] = useState([]);
    const [nameError, setNameError] = useState('');
    const [stateError, setStateError] = useState('');
    const [acError, setAcError] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
        { !stateList.length && authStatus &&  getStates()};
        // { !vidhanSabhaList.length && getAcs(localStorage.getItem(STATEID)) };
    }, [stateList, vidhanSabhaList])

    useEffect(() => {
        let authStatus= JSON.parse(localStorage.getItem(AUTH_STATUS));
        if(!authStatus) {
            navigate('/', { replace: false })
        }
    });

    async function getStates() {
        let states = await fetch("api/event_tracker/get_country_state", {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
        });
        const res = await states.json();
        setStateList(res.data);
    }

    async function getAcs(state_id) {
        let country_state_id = ((state_id === undefined) ? "" : state_id);
        let acs = await fetch("api/event_tracker/get_acs?country_state_id="+ country_state_id, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
        });
        const res = await acs.json();
        setVidhanSabhaList(res.data);
    }

    const onSubmit = () => {
      if (!userName || !stateId || !vidhanSabhaId) {
        toast.dismiss()
        toast(t('Please fill all * mandatory fields'));
        return
      }
        sendUserDetails();
    };

    async function sendUserDetails() {
        setloaderEnable(true);
        let body = {
            user_name: userName,
            country_state_id: stateId,
            ac_id: vidhanSabhaId
        }
        localStorage.setItem(USERNAME, userName);
        localStorage.setItem('acId', vidhanSabhaId);
        localStorage.setItem('satateId', stateId);
        localStorage.setItem('acName', vidhanSabhaName);
        localStorage.setItem('satateName', stateName);
        let result = await fetch("api/event_tracker/update_user_details", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": localStorage.getItem(AUTH_TOKEN)
            },
            body: JSON.stringify(body)
        });
        result = await result.json();
        setloaderEnable(false);
        toast(t(result.message));
        if (result.status) {
            location.reload;
            navigate('/program', { replace: false });
        }
    }

    function allowedAlphaInput(event) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode>=65 && charCode<=122 || charCode===8 || charCode===32) {
            setUserName(event.target.value)
        } else {
            event.preventDefault();
        }
    }
    function clearStateDependentFields() {
        setVidhanSabhaList([]);
        setVidhanSabhaName('');
        setVidhanSabhaId('');
    }

    function validateName(name) {
         setNameError('');
    if (name && name.match(namePattern) === null) {
      setNameError(t('Please enter a valid Name'));
    }
    if (!name) {
      setNameError('Name required')
    }
    }
  function validateState(state) {
    setStateError('');
    clearStateDependentFields();
    if (!state) {

      setStateError('State required')
    }
  }
  function validateAc(ac) {
    setAcError('');
    if (!ac) {
      setAcError('Vidhan Sabha required')
    }
  }


  return (
    <div className='userDetailContainer'>
      <div className='enterDetailName'>
        <p>{t('Enter Details')}</p>
      </div>
      {loaderEnable ? (<> <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={t("Loading..")} color={'#FFFFFF'} size={100} /> </> ) : ( <> </> )}
      <div className='formContainer'>
            <form className='formFieldsDetail' action="" onSubmit={handleSubmit(onSubmit)}>
              <div className='nameInput'>
                <label className='fullNameLabel' htmlFor="">{t('Enter Your Full Name')}*</label>
                <input
                  name='fullname'
                  className='custom-input'
                  onChange={(e) => {
                    e && setUserName(e.target.value);
                    validateName(e.target.value);
                  }
                  }
                  type="text"
                  value={userName}
                  maxLength="20"
                  minLength="2"
                  autoComplete="off"
                  onKeyPress={(event) => allowedAlphaInput(event)}
                />
                <small className="error-msg">{t(nameError)}</small>
              </div>
              <label className="fullNameLabel mt-3">{t('Select State')}*</label>
              <div className="position-relative">
                <Select getOptionLabel={stateList => stateList.name}
                        getOptionValue={stateList => stateList.id}
                        onChange={(value) => {
                          setStateId(value ? value.id : '');
                          setStateName(value ? value.name : '');
                          value && getAcs(value.id);
                          validateState(value.id);
                        }
                        }
                        value={{value: stateId, name: stateName}}
                        options={stateList}/>
                {stateId ? (<> <button className="clear-btn" onClick={() => {
                    setStateId('');
                    setStateName('');
                    validateState('');
                }}>X
                </button>
                </>) : (<> </>)}
                <small className="error-msg">{t(stateError)}</small>
              </div>
              <label className="fullNameLabel mt-3">{t('Vidhan Sabha')}*</label>
              <div className="position-relative">
                <Select menuPlacement="top" getOptionLabel={vidhanSabhaList => vidhanSabhaList.name}
                        getOptionValue={vidhanSabhaList => vidhanSabhaList.id}
                        onChange={(value) => {
                          setVidhanSabhaId(value ? value.id : '');
                          value && setVidhanSabhaName(value.name);
                          validateAc(value.id);
                        }
                        }
                        value={{value: vidhanSabhaId, name: vidhanSabhaName}}
                        options={vidhanSabhaList}/>
                {vidhanSabhaId ? (<> <button className="clear-btn" onClick={() => {
                    setVidhanSabhaId('');
                    setVidhanSabhaName('');
                    validateAc('');
                }}>X
                </button>
                </>) : (<> </>)}
                <small className="error-msg">{t(acError)}</small>
              </div>
              <input type="submit" className='nextButton' value={t('Next')}/>
            </form>
      </div>
    </div>
  )
}