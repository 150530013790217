import React, {createRef, useEffect, useState} from 'react';
import './editProfile.css';
import {ACID, ACNAME, AUTH_STATUS, AUTH_TOKEN, STATEID, STATENAME} from "../SharingConstants";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Loader from "react-js-loader";
import * as Constant from "../SharingConstants";
import {useNavigate} from "react-router-dom";
import defaultAvatarImg from '../../../../public/images/defaultAvatar.svg'
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import {namePattern, phonePattern, pincodePattern} from "../../util/util"
import api from "../api";

toast.configure()
export default function EditProfile() {
  const {handleSubmit} = useForm();
  const navigate = useNavigate();
  const [loaderEnable, setloaderEnable] = useState(false);
  const [getUserInfoFlag, setGetUserInfoFlag] = useState(true);
  const [getLocationInfoFlag, setGetLocationInfoFlag] = useState(false);
  const [userName, setUserName] = useState();
  const [userFile, setUserFile] = useState('');
  const fileInput = createRef();
  const [userDp, setUserDp] = useState();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userEducation, setUserEducation] = useState('');
  const [userProfession, setuserProfession] = useState('');
  const [userWhatsAppNumber, setUserWhatsAppNumber] = useState('');
  const [userVoterId, setUserVoterId] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userPincode, setUserPincode] = useState('');
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState('');
  const [stateName, setStateName] = useState('');
  const [stateList, setStateList] = useState([]);
  const [vidhanSabha, setVidhanSabha] = useState([]);
  const [vidhanSabhaId, setVidhanSabhaId] = useState('');
  const [vidhanSabhaName, setVidhanSabhaName] = useState('');
  const [vidhanSabhaList, setVidhanSabhaList] = useState([]);
  const [partyZila, setPartyZila] = useState([]);
  const [partyZilaId, setPartyZilaId] = useState('');
  const [partyZilaName, setPartyZilaName] = useState('');
  const [partyZilaList, setPartyZilaList] = useState([]);
  const [mandal, setMandal] = useState([]);
  const [mandalId, setMandalId] = useState('');
  const [mandalName, setMandalName] = useState('');
  const [mandalList, setMandalList] = useState([]);
  const [booth, setBooth] = useState([]);
  const [boothId, setBoothId] = useState('');
  const [boothName, setBoothName] = useState('');
  const [boothList, setBoothList] = useState([]);
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [stateError, setStateError] = useState('');
  const [partyZilaError, setPartyZilaError] = useState('');
  const [partyMandalError, setPartyMandalError] = useState('');
  const [acError, setAcError] = useState('');
  const [boothError, setBoothError] = useState('');
  const [whatsappError, setWhatsappError] = useState('');
  const [pincodeError, setPincodeError] = useState();
  const { t } = useTranslation();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  let lat = JSON.parse(localStorage.getItem('latitude'))
  let long = JSON.parse(localStorage.getItem('longitude'))

  useEffect(() => {
    if (getLocationInfoFlag) {
      {
        !stateList.length && getStates()
      }
      {
        state.length && !vidhanSabhaList.length && getAcs(state.id)
      }
      {
        state.length && !partyZilaList.length && getPartyZila(state.id)
      }
      {
        partyZila.length && !mandalList.length && getMandal(partyZila.id)
      }
      {
        vidhanSabha.length && !boothList.length && getBooths(vidhanSabha.id)
      }
      exitUseEffect();
    }
  }, [getLocationInfoFlag])

  useEffect(() => {
    let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
    {
      getUserInfoFlag && authStatus && getUserInfo()
    }
    ;
  }, [getUserInfoFlag]);

  useEffect(() => {
    let authStatus = JSON.parse(localStorage.getItem(AUTH_STATUS));
    if (!authStatus) {
      navigate('/', {replace: false})
    }
  });

  useEffect(() =>{
    setLatitude(lat)
    setLongitude(long)
  })

  function exitUseEffect() {
    setGetLocationInfoFlag(false);
  }

function clearStateDependentFields() {
  setVidhanSabhaList([]);
  setVidhanSabhaName('');
  setVidhanSabhaId('');
  setPartyZilaName('');
  setPartyZilaId('');
  setPartyZilaList([]);
  setMandalName('');
  setMandalList([]);
  setMandalId('');
  setBoothName('');
  setBoothId('');
  setBoothList([])
}

 function clearAcDependentFields(){
  setPartyZilaName('');
  setPartyZilaId('');
  setMandalName('');
  setMandalList([]);
  setMandalId('');
  setBoothName('');
  setBoothId('');
  }

  function clearPartyZilaDependentFields(){
  setMandalName('');
  setMandalList([]);
  setMandalId('');
  setBoothName('');
  setBoothId('');
  }

  function clearPartyMandalDependentFields(){
  setBoothName('');
  setBoothId('');
  }

 function handleState(state) {
   clearStateDependentFields();
   state && setStateId(state.id);
   state && setStateName(state.name);
   state && getAcs(state.id);
   state && getPartyZila(state.id);
   validateState(state.id);
  }

  function handleZila(zila) {
    clearPartyZilaDependentFields();
    zila && setPartyZilaId(zila.id);
    zila && setPartyZilaName(zila.name);
    zila && getMandal(zila.id);
    validatePartyZila(zila.id);
  }

  function handleVidhanSabha(value) {
    clearAcDependentFields();
    value && setVidhanSabhaId(value.id);
    value && setVidhanSabhaName(value.name);
    value && getBooths(value.id);
    validateAc(value.id);
  }

  const onSubmit = () => {
    if (!userName || !stateId || !vidhanSabhaId || !partyZilaId || !mandalId || !boothId) {
      toast.dismiss()
      toast(t('Please fill all * mandatory fields'))
      return
    }
    if (pincodeError || whatsappError) {
      return
    }
    setUserInfo();
  };

  function setUserInfoState(user) {
    setUserName(user.data.name);
    setPhoneNumber(user.data.phone);
    setUserEducation(user.data.education === 'null' ? '' : user.data.education);
    setuserProfession(user.data.profession === 'null' ? '' : user.data.profession);
    setUserWhatsAppNumber(user.data.whatsapp_no === 'null' ? '' : user.data.whatsapp_no );
    setUserVoterId(user.data.voter_id == 'null' ? '' : user.data.voter_id);
    setUserAddress(user.data.address === 'null' ? '' : user.data.address);
    setUserPincode(user.data.pin_code === 'null' ? '' : user.data.pin_code);
    setState(user.data.country_state);
    setVidhanSabha(user.data.ac);
    setPartyZila(user.data.party_zila);
    setMandal(user.data.party_mandal);
    setBooth(user.data.booth);
    setUserDp(user.data.photo ? user.data.photo : defaultAvatarImg);
    setStateId(user.data.country_state.length ? user.data.country_state[0].id : '');
    setStateName(user.data.country_state.length ? user.data.country_state[0].name: '');
    setVidhanSabhaId(user.data.ac.length ? user.data.ac[0].id : '');
    setVidhanSabhaName(user.data.ac.length ? user.data.ac[0].name : '');
    setMandalId(user.data.party_mandal.length ? user.data.party_mandal[0].id : '');
    setMandalName(user.data.party_mandal.length ? user.data.party_mandal[0].name : '');
    setPartyZilaId(user.data.party_zila.length ? user.data.party_zila[0].id : '');
    setPartyZilaName(user.data.party_zila.length ? user.data.party_zila[0].name : '');
    setBoothId(user.data.booth.length ? user.data.booth[0].id : '');
    setBoothName(user.data.booth.length ? user.data.booth[0].name : '');
    user.data.lat = latitude
    user.data.long = longitude
    localStorage.setItem(STATENAME,user.data.country_state.length ? user.data.country_state[0].name : '');
    localStorage.setItem(ACNAME,user.data.ac.length ? user.data.ac[0].name : '');
    localStorage.setItem(STATEID,user.data.country_state.length ? user.data.country_state[0].id : '');
    localStorage.setItem(ACID,user.data.ac.length ? user.data.ac[0].id : '');
    if (!user.data.country_state.length) {
      clearStateDependentFields();
    }
    if (!user.data.ac.length) {
      clearAcDependentFields();
    }
    if (!user.data.party_zila.length) {
      clearPartyZilaDependentFields();
    }
    if (!user.data.party_mandal.length) {
      clearPartyMandalDependentFields();
    }
  }

  const getUserInfo = async () => {
    setloaderEnable(true);
    try {
      const states = await api.get('api/event_tracker/get_user_info', {
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        },
      }); // Make API call using the api instance
      const response = await states.data;
      setUserInfoState(response);
      setGetUserInfoFlag(false);
      setGetLocationInfoFlag(true);
    } catch (error) {
      console.error('API Call Error:', error);
    }
    setloaderEnable(false);
  };


  async function setUserInfo() {
    setloaderEnable(true);
    let formData = new FormData();
    formData.append('user_name', userName)
    formData.append('phone_number', phoneNumber)
    formData.append('education', userEducation)
    formData.append('profession', userProfession)
    formData.append('whatsapp_no', userWhatsAppNumber)
    formData.append('voter_id', userVoterId)
    formData.append('address', userAddress)
    formData.append('pin_code', userPincode)
    formData.append('file', userFile)
    formData.append('country_state_id', (stateId || state[0].id))
    formData.append('ac_id', (vidhanSabhaId || vidhanSabha[0].id))
    formData.append('party_zila_id', (partyZilaId || partyZila[0].id))
    formData.append('party_mandal_id', (mandalId || mandal[0].id))
    formData.append('booth_id', (boothId || booth[0].id))
    formData.append('lat', latitude)
    formData.append('long', longitude)

    let users = await fetch("api/event_tracker/update_user_details", {
      method: 'POST',
      headers: {
        "Authorization": localStorage.getItem(AUTH_TOKEN)
      },
      body: formData
    });
    const response = await users.json();
    localStorage.setItem(Constant.USERIMAGE, response.data.photo);
    localStorage.setItem(Constant.USERNAME, response.data.name);
    localStorage.setItem(Constant.USERPHONE, response.data.phone);
    setloaderEnable(false);
    { toast.dismiss()
      response.status && toast(t(response.message)) && getUserInfo()
    }
  }

  const getStates = async () => {
    setloaderEnable(true);
    try {
      const states = await api.get('api/event_tracker/get_country_state', {
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        },
      }); // Make API call using the api instance
      const res = await states.data;
      setStateList(res.data);
    } catch (error) {
      console.error('API Call Error:', error);
    }
    setloaderEnable(false);
  };

  const getAcs = async (state_id) => {
    setloaderEnable(true);
    try {
      let country_state_id = ((state_id === undefined) ? "" : state_id);
      const acs = await api.get("api/event_tracker/get_acs?country_state_id="+ country_state_id, {
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        },
      }); // Make API call using the api instance
      const res = await acs.data;
      setVidhanSabhaList(res.data);
    } catch (error) {
      console.error('API Call Error:', error);
    }
    setloaderEnable(false);
  };

  const getPartyZila = async (state_id) => {
    setloaderEnable(true);
    try {
      let body = {country_state_id: state_id}
      const zilas = await api.post('api/event_tracker/get_party_zila', body,{
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        }
      }); // Make API call using the api instance
      const res = await zilas.data;
      setPartyZilaList(res.data);
    } catch (error) {
      console.error('API Call Error:', error);
    }
    setloaderEnable(false);
  };

  const getMandal = async (zila_id) => {
    setloaderEnable(true);
    try {
      let body = {zila_id: zila_id}
      const mandals = await api.post('api/event_tracker/get_party_mandal',
          body, {
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": localStorage.getItem(AUTH_TOKEN)
            }
          }); // Make API call using the api instance
      const res = await mandals.data;
      setMandalList(res.data);
    } catch (error) {
      console.error('API Call Error:', error);
    }
    setloaderEnable(false);
  };

  const getBooths = async (ac_id) => {
    setloaderEnable(true);
    try {
      let body = {ac_id: ac_id}
      const booths = await api.post('api/event_tracker/get_booth', body, {
        headers: {
          "Content-Type": "application/json",
          "Accept": 'application/json',
          "Authorization": localStorage.getItem(AUTH_TOKEN)
        }
      }); // Make API call using the api instance
      const res = await booths.data;
      setBoothList(res.data);
    } catch (error) {
      console.error('API Call Error:', error);
    }
    setloaderEnable(false);
  };

  function uploadFile() {
    setUserFile(fileInput.current.files[0]);
    checkFileSizeType(fileInput);
  }

  function checkFileSizeType(file) {
    const fileName = file.current.files[0].name ? file.current.files[0].name : ''
    if (file.current.value && file.current.files[0].size > 10e6) {
      file.current.value = '';
      toast(t("File size exceeds limit! Please select a file upto 10 MB"));
      setUserFile('');
    } else if (fileName && (!fileName.toLowerCase().includes('.jpg') && !fileName.toLowerCase().includes('.png') && !fileName.toLowerCase().includes('.jpeg'))) {
      file.current.value = '';
      toast(t("File should be JPG or PNG format"));
      setUserFile('');
    }
      }

  function allowedNumericInput(event, field) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (!(charCode > 31 && (charCode < 48 || charCode > 57))) {
      if (field === 'WhatsApp') {
        setUserWhatsAppNumber(event.target.value);
        if (event.target.value.length >= 10 ){
          event.preventDefault();
        }
      } else if (field === 'Pincode') {
        setUserPincode(event.target.value);
      }
    } else {
      event.preventDefault();
    }
  }

  function allowedAlphaInput(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 65 && charCode <= 122 || charCode === 8 || charCode === 32) {
      setUserName(e.target.value)
    } else {
      event.preventDefault();
    }
  }
  function validateName(name) {
    setNameError('');
    if (name && name.match(namePattern) === null) {
      setNameError(t('Please enter a valid Name'));
    }
    if (!name) {
      setNameError('Name required')
    }
  }
  function validateState(state) {
    setStateError('');
    if (!state) {
      clearStateDependentFields();
      setStateError('State required')
    }
  }
  function validateAc(ac) {
    setAcError('');
    if (!ac) {
      clearAcDependentFields();
      setAcError('Vidhan Sabha required')
    }
  }
  function validatePartyZila(zila) {
    setPartyZilaError('');
    if (!zila) {
      clearPartyZilaDependentFields();
      setMandalId('');
      setMandalList([]);
      setMandalName('');
      setPartyZilaError('Party Zila required')
    }
  }
  function validatePartyMandal(mandal) {
    setPartyMandalError('');
    if (!mandal) {
      clearPartyMandalDependentFields();
      setPartyMandalError('Party Mandal required')
    }
  }
  function validatePhone(phone) {
    setPhoneError('');
    if (!phone) {
      setPhoneError('Phone required')
    }
  }
  function validateBooth(booth) {
    setBoothError('');
    if (!booth) {
      setBoothError('Booth required')
    }
  }

  function validateWhatsapp(phone) {
    setWhatsappError('');
    if (phone && phone.match(phonePattern) === null) {
      setWhatsappError(t('Please enter a valid WhatsApp number(10 digits)'));
    }
  }

  function validatePincode(pincode){
    setPincodeError('');
    if(pincode && pincode.match(pincodePattern) == null) {
      setPincodeError(t('Please enter a valid pincode'));
    }
  }

  function validateVoterId(e) {
    if (e.target.value.length >= 10) {
      e.preventDefault();
    }
  }

   function setVoterId(event) {
    const limit = 10;
    setUserVoterId(event.target.value.slice(0,limit))
  }

  return (
    <div className='editProfileContainer'>
      <div className="profile-img-div">
        <img src={userDp} className="profile-img" alt=""/>
      </div>
      {loaderEnable ? (<> <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading.."} color={'#FFFFFF'}
                                  size={100}/> </>) : (<> </>)}
      <div className='profileTitle'>
        <p className="edit-title">{t('Edit Profile')}</p>
      </div>
      <div className='formContainerProfile'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className='phoneNumberLabel' htmlFor="">{t('Phone Number')}*</label>
          <input
            className='custom-input'
            type="tel"
            maxLength="10"
            minLength="10"
            value={phoneNumber}
            onChange={(e) => { setPhoneNumber(e.target.value); validatePhone(e.target.value);}}
            // onKeyPress={(event) => allowedNumericInput(event)}
            disabled={true}
          />
        <small className="error-msg">{t(phoneError)}</small>
          <label className='fullNameLabel mt-3' htmlFor="">{t('Name')}*</label>
          <input
            className='custom-input'
            type="text"
            value={userName}
            onChange={(e) => {setUserName(e.target.value); validateName(e.target.value)}}
            onKeyPress={(event) => allowedAlphaInput(event)}
          />
          <small className="error-msg">{t(nameError)}</small>
          <label className='fullNameLabel mt-3' htmlFor="">{t('Select profile photo')}</label>
          <input
            type="file"
            className="w-100 m-0"
            ref={fileInput}
            onChange={(e) => uploadFile()}
          />

          <label className='fullNameLabel mt-3' htmlFor="">{t('State')}*</label>
          <div className="position-relative">
            <Select getOptionLabel={stateList => stateList.name}
                    getOptionValue={stateList => stateList.id}
                    onChange={(value) => {
                      handleState(value);
                    }
                    }
                    value={{value: stateId, name: stateName}}
                    options={stateList}/>
            {stateId ? (<>
              <button className="clear-btn" onClick={() => {
                setStateId('');
                setStateName('');
                validateState('');
              }}>X
              </button>
            </>) : (<> </>)}
            <small className="error-msg">{t(stateError)}</small>
          </div>

          <label className='fullNameLabel mt-3' htmlFor="">{t('Vidhan Sabha')}*</label>
          <div className="position-relative">
            <Select getOptionLabel={vidhanSabhaList => vidhanSabhaList.name}
                    getOptionValue={vidhanSabhaList => vidhanSabhaList.id}
                    onChange={(value) => {
                      handleVidhanSabha(value)
                    }
                    }
                    value={{value: vidhanSabhaId, name: vidhanSabhaName}}
                    options={vidhanSabhaList}/>
            {vidhanSabhaId ? (<>
              <button className="clear-btn" onClick={() => {
                setVidhanSabhaId('');
                setVidhanSabhaName('');
                validateAc('');
              }}>X
              </button>
            </>) : (<> </>)}
            <small className="error-msg">{t(acError)}</small>
          </div>

          <label className='fullNameLabel mt-3' htmlFor="">{t('Party Zila')}*</label>
          <div className="position-relative">
            <Select getOptionLabel={partyZilaList => partyZilaList.name}
                    getOptionValue={partyZilaList => partyZilaList.id}
                    onChange={(value) => {
                      handleZila(value);
                    }
                    }
                    value={{value: partyZilaId, name: partyZilaName}}
                    options={partyZilaList}/>
            {partyZilaId ? (<>
              <button className="clear-btn" onClick={() => {
                setPartyZilaId('');
                setPartyZilaName('');
                validatePartyZila('');
              }}>X
              </button>
            </>) : (<> </>)}
            <small className="error-msg">{t(partyZilaError)}</small>
          </div>

          <label className='fullNameLabel mt-3' htmlFor="">{t('Mandal')}*</label>
          <div className="position-relative">
            <Select getOptionLabel={mandalList => mandalList.name}
                    getOptionValue={mandalList => mandalList.id}
                    onChange={(value) => {
                      value && setMandalId(value.id);
                      value && setMandalName(value.name);
                      validatePartyMandal(value.id);
                    }
                    }
                    value={{value: mandalId, name: mandalName}}
                    options={mandalList}/>
            {mandalId ? (<>
              <button className="clear-btn" onClick={() => {
                setMandalId('');
                setMandalName('');
                validatePartyMandal('');
              }}>X
              </button>
            </>) : (<> </>)}
            <small className="error-msg">{t(partyMandalError)}</small>
          </div>

          <label className='fullNameLabel mt-3' htmlFor="">{t('Booth')}*</label>
          <div className="position-relative">
            <Select getOptionLabel={boothList => boothList.name}
                    getOptionValue={boothList => boothList.id}
                    onChange={(value) => {
                      value && setBoothId(value.id);
                      value && setBoothName(value.name);
                      validateBooth(value.id);
                    }
                    }
                    value={{value: boothId, name: boothName}}
                    options={boothList}/>
            {boothId ? (<>
              <button className="clear-btn" onClick={() => {
                setBoothId('');
                setBoothName('');
                validateBooth('');
              }}>X
              </button>
            </>) : (<> </>)}
            <small className="error-msg">{t(boothError)}</small>
          </div>

          <label className='wappNumberLabel mt-3'>{t('WhatsApp Number')}</label>
          <input
            className='custom-input'
            type="tel"
            value={userWhatsAppNumber}
            onChange={(e) => {
              setUserWhatsAppNumber(e.target.value);
              validateWhatsapp(e.target.value);
            }}
            onKeyPress={(event) => allowedNumericInput(event, 'WhatsApp')}
          />
          <small className="error-msg">{whatsappError}</small>
          <label className='fullNameLabel mt-3'>{t('Education')}</label>
          <input
            className='custom-input'
            type="text"
            value={userEducation}
            onChange={(e) => setUserEducation(e.target.value)}
          />

          <label className='fullNameLabel mt-3'>{t('Profession')}</label>
          <input
            className='custom-input'
            type="text"
            value={userProfession}
            onChange={(e) => setuserProfession(e.target.value)}
          />

          <label className='voterIdLabel mt-3'>{t('Voter ID')}</label>
          <input
            className='custom-input'
            type="text"
            maxLength="10"
            minLength="10"
            value={userVoterId}
            onChange={(e) => {
              setVoterId(e)
          }}
            onKeyPress={(event) => validateVoterId(event)}
          />

          <label className='addressLabel mt-3'>{t('Address')}</label>
          <textarea
            className='addressInput'
            value={userAddress}
            onChange={(e) => setUserAddress(e.target.value)}
          />

          <label className='pinCodeLabel mt-3'>{t('Pincode')}</label>
          <input
            className='custom-input'
            type="tel"
            value={userPincode}
            maxLength="6"
            minLength="6"
            onChange={(e) => {
              setUserPincode(e.target.value);
              validatePincode(e.target.value);
            }}
            onKeyPress={(event) => allowedNumericInput(event, 'Pincode')}
          />
          <small className="error-msg">{pincodeError}</small>
          <div className="buttonContainer">
            <button type='submit' className='button'>
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}