import React, {useEffect, useState} from 'react';
import './reports-overview.css';
import AreaTimeChart from "./areaTimeChart";
import StackedCard from "./stackedCard";
import Box from '@mui/material/Box';
import {AUTH_TOKEN} from "../SharingConstants";
import SemiCircleChart from "./semi-circle-chart";
import Skeleton from '@mui/material/Skeleton';
import { Divider } from '@mui/material';
import {DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dateFormat, {masks} from "dateformat";
import dayjs from "dayjs";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import api from "../api";
import rectangle from "../../../../public/images/rectangle-tab.svg";


const ReportsOverview = ({eventId, eventStartDate, eventEndDate}) => {
    const [areaData, setAreaData] = useState([]);
    const [boothWiseData, setBoothWiseData] = useState({});
    const [semiCircleData, setSemiCircleData] = useState({});
    const [shimmerEffect, setShimmerEffect] = useState(true);
    const [areaChartShimmer, setAreaChartShimmer] = useState(false);
    const [stackedCardLoad,setStackedCardLoad] = useState(false);
    const {t} = useTranslation();
    const [stateWiseData, setStateWiseData] = useState([]);

    const handleDateChange = (date) => {
        setAreaChartShimmer(true);
        getTimeChart(eventId, dateFormat(date?.$d, 'dd/mm/yyyy'));
    };

    const getTimeChart = async (eventId, date) => {
        try {
            let events = await api.get(`api/report/date_wise_event_stats?event_id=${eventId}&date=${date}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await events.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setAreaData(res.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setAreaChartShimmer(false)
    };

    const boothWiseStats = async (eventId) => {
        setStackedCardLoad(true);
        try {
            let stats = await api.get(`/api/report/acs_booths_wise_stats?event_id=${eventId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const response = await stats.data;
            if (response.status === false) {
                setShimmerEffect(false);
                toast(response.message);
                return;
            }
            response.status === true ? setStackedCardLoad(false) : null;
            setBoothWiseData(response.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setStackedCardLoad(false)
    };


    const getDonutChart = async (eventId) => {
        try {
            let events = await api.get(`api/report/booth_wise_stats?event_id=${eventId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": localStorage.getItem(AUTH_TOKEN)
                },
            }); // Make API call using the api instance
            const res = await events.data;
            if (res.status === false) {
                setShimmerEffect(false);
                toast(res.message);
                return;
            }
            setSemiCircleData(res.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
        setShimmerEffect(false);
    };

    useEffect(() => {
        if (eventId && eventStartDate) {
            getTimeChart(eventId, eventStartDate);
        }
    }, [eventId, eventStartDate]);

    useEffect(() => {
        if (eventId) {
            setShimmerEffect(true);
            boothWiseStats(eventId);
            getDonutChart(eventId);
            getStateWiseData();

        }
    }, [eventId]);

    const showShimmer = () => {
        return (<Box spacing={1}>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={300} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rectangular" height={80} style={{backgroundColor: '#ECECEC'}}/>
            <br/>
            <Skeleton animation="wave" variant="rounded" height={200} style={{backgroundColor: '#ECECEC'}}/>
        </Box>);
    };

    const shimmerOnDateChange = () => {
        return (
            <Box spacing={1}>
                <Skeleton animation="wave" variant="rounded" height={300} style={{backgroundColor: '#ECECEC'}}/>
                <br/>
            </Box>
        );
    };

    const shimmerBoothData = () => {
        return (
            <Box spacing={1}>
                <Skeleton animation="wave" variant="rectangular" height={80}
                          style={{backgroundColor: '#ECECEC'}}/>
                <br/>
            </Box>
        )
    }

    const getStateWiseData = async () => {
        try {
            const response = await api.get(`/api/report/state_wise_stats?event_id=${eventId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: localStorage.getItem(AUTH_TOKEN),
                },
            });

            const responseData = response.data;
            console.log('response.data',response.data)
            if (responseData.status === false) {
                setShimmerEffect(false);
                toast(responseData.message);
                return;
            }

            setStateWiseData(responseData.data);
        } catch (error) {
            console.error('API Call Error:', error);
        }
    };

    return (
        <div>
            {shimmerEffect ? showShimmer() : <>
                {eventId === "" ? <>
                    <div className="blank-report">
                        {t('No Reports Available')}
                    </div>
                </> : <>
                    <div className='report-overview-chart-calender'>
                <span className='reports-overview-events-calender'>
                    <span className='reports-overview-events-reported-text'>{t('Events Reported')}</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoItem components={['Select Date']}>
                                  <DesktopDatePicker
                                      className="small-date-picker"
                                      // label="Select Date"
                                      onChange={handleDateChange}
                                      format='DD MMM YYYY'
                                      defaultValue={eventStartDate.toString().length > 0 ? dayjs(eventStartDate.replace("th", "")) : undefined}
                                      minDate={eventStartDate.toString().length > 0 ? dayjs(eventStartDate.replace("th", "")) : undefined}
                                      maxDate={eventEndDate.toString().length > 0 ? dayjs(eventEndDate.replace("th", "")) : undefined}
                                      slotProps={{
                                          textField: {
                                              readOnly: true,
                                          },
                                      }}
                                  />
                              </DemoItem>
                    </LocalizationProvider>
                    </LocalizationProvider>
                </span>
                        <hr style={{color: '#A9A9A9'}}/>
                        {areaChartShimmer === true ? shimmerOnDateChange() : <AreaTimeChart areaData={areaData}/>}
                    </div>

                    {Object.keys(boothWiseData).length === 0 || stackedCardLoad === true ? shimmerBoothData() :
                        <StackedCard boothWiseData={boothWiseData}/>}
                    <SemiCircleChart semiCircleData={semiCircleData}/>

                    {

                        stateWiseData.user_level === 'Zila' || stateWiseData?.data?.length === 0  || stateWiseData?.data === null ?  null :
                            <div className="state-wise-Text">
                                <img className="rectangle-tab" src={rectangle} alt=""/>
                                <span className='state-wise-text'>{t('State Wise Data')}</span>
                            </div>
                    }
                            {stateWiseData?.data?.map((stateData) => (
                                <div className='state-data-container' key={stateData.cs_name}>
                                    <div className='data-container'>
                                        <div className="data-item"> {stateData.total_acs}</div>
                                        <div className="data-item"> {stateData.formatted_ff_booth_count}</div>
                                        <div className="data-item"> {stateData.formatted_ta}</div>
                                    </div>

                                    <div className="side-by-side">
                                        <span>
                                            <div className='txt-container'>
                                                <span className='txt-font'>Vidhan Sabha Covered</span>
                                            </div>
                                        </span>
                                        <span>
                                            <div className='txt-container'>
                                                <span className='txt-font'>Form Filled Booths</span>
                                            </div>
                                        </span>
                                        <span>
                                            <div className='txt-container'>
                                                <span className='txt-font'>Total Attendees</span>
                                            </div>
                                        </span>
                                    </div>
                                    <Divider />
                                    <div className='txt-state'>
                                        <p>{stateData.cs_name}</p>
                                    </div>
                                </div>
                    ))}

                </>}
            </>}


        </div>
    );
};

export default ReportsOverview;